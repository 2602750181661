import axios from 'axios';
import { buildUrl } from '../helpers/str';

export const BASE_URL =
    (process.env.REACT_APP_API_ROOT_LOS ||
        process.env.REACT_APP_API_ROOT_DEFAULT) + '/los/';

export const urls = {
    documents: BASE_URL + 'documents/',
    agreement_generate: BASE_URL + 'agreements/generate/',
    agreement_upload: BASE_URL + 'agreements/upload/',

    stamp_generate: BASE_URL + 'stamps/generate/',

    signs_initiate: BASE_URL + 'signs/initiate/',
    signs_refresh: BASE_URL + 'signs/:id/refresh_status/',

    application_details: BASE_URL + 'accounts/:id/',
    application_activate: BASE_URL + 'accounts/:id/activate/',
};

export const getApplication = async (id) => {
    const { data } = await axios.get(
        buildUrl(urls.application_details, { id }),
    );
    return data;
};

export const listAgreements = async (account) => {
    const { data } = await axios.get(
        buildUrl(urls.documents, {
            account,
            types: 'agreement,signed_agreement,stamp',
            'no-pagination': true,
        }),
    );
    return data;
};

export const generateAgreement = async (account) => {
    const { data } = await axios.post(urls.agreement_generate, {
        account,
    });
    return data;
};

export const generateSign = async (account, name) => {
    const { data } = await axios.post(urls.signs_initiate, {
        account,
        name,
    });
    return data;
};

export const refreshSignStatus = async (id) => {
    const { data } = await axios.get(
        buildUrl(urls.signs_refresh, {
            id,
        }),
    );
    return data;
};

export const generateStamp = async (account, name) => {
    const { data } = await axios.post(urls.agreement_generate, {
        account,
        name,
    });
    return data;
};

export const activateApplication = async (id) => {
    const { data } = await axios.put(
        buildUrl(urls.application_activate, { id }),
    );
    return data;
};
