import { useEffect, useState } from 'react';
import FormView from '.';
import { confirm } from '../../../helpers/alert';
import { buildPayload } from '../../../helpers/form';
import FormWrapper from './FormWrapper';

const FormDrawer = (props) => {
    const {
        form = true,
        plus,
        btn,
        className,
        show,
        label,
        icon,
        name,
        config,
        data,
        submitted,
        tooltip,
        width,
        constants,
    } = props;

    const [showDrawer, setShowDrawer] = useState(show || false);
    const [request, setRequest] = useState({});
    const [hasFormChanged, setHasFormChanged] = useState(false);

    useEffect(() => {
        setHasFormChanged(false);
    }, [showDrawer, show, data, config]);

    const handleSubmit = (data) => {
        setShowDrawer(false);
        if (submitted) {
            submitted(data);
        }
    };

    const submit = () => {
        setShowDrawer(false);
        if (submitted) {
            submitted(buildPayload(config.items, request));
        }
    };

    const handleUpdated = (data) => {
        setRequest(data);
        formChanged();
    };

    const formChanged = () => {
        setHasFormChanged(true);
    };

    const handleDrawerClose = () => {
        if (hasFormChanged) {
            confirm(
                'Your changes are not saved.',
                'Do you still want to close ?',
                async () => {
                    setShowDrawer(false);
                },
            );
        } else {
            setShowDrawer(false);
        }
    };

    return (
        <>
            {icon && tooltip && (
                <div className='symbol symbol-50px me-5'>
                    <button
                        onClick={() => setShowDrawer(true)}
                        className={`btn btn-icon btn-circle btn-${
                            className || 'light'
                        } btn-active-color-white btn-sm mx-2`}>
                        <i className={`bi bi-${icon || 'pencil-fill'}`}></i>
                    </button>
                </div>
            )}
            {icon && !tooltip && (
                <button
                    type='button'
                    onClick={() => setShowDrawer(true)}
                    className={`btn btn-icon btn-circle btn-${
                        className || 'light'
                    } btn-active-color-white btn-sm mx-2`}>
                    <i className={`bi bi-${icon || 'pencil-fill'}`}></i>
                </button>
            )}
            {plus && (
                <div className='text-center'>
                    <span
                        className='svg-icon cursor-pointer'
                        onClick={() => setShowDrawer(true)}>
                        <i className='ri ri-add-line'></i>
                    </span>
                </div>
            )}
            {btn && (
                <button
                    type='button'
                    className='btn btn-info w-100'
                    onClick={() => setShowDrawer(true)}>
                    {btn}
                </button>
            )}
            {label && <div onClick={() => setShowDrawer(true)}>{label}</div>}
            {showDrawer && (
                <div>
                    <div
                        style={{ zIndex: 109 }}
                        className='drawer-overlay'
                        onClick={handleDrawerClose}></div>
                    <div
                        id={`config_drawer_${name}`}
                        className='bg-body fs-6 drawer drawer-end drawer-on text-start'
                        style={{ width: width || '450px' }}>
                        <div className='card shadow-none rounded-0 w-100'>
                            <div
                                className='card-header'
                                id='kt_help_header'>
                                <h5 className='card-title fw-bold text-gray-600'>
                                    {config.title}
                                </h5>
                                <div className='card-toolbar'>
                                    <button
                                        type='button'
                                        onClick={() => setShowDrawer(false)}
                                        className='btn btn-sm btn-icon explore-btn-dismiss me-n5'>
                                        <i className='bi bi-x-lg'></i>
                                    </button>
                                </div>
                            </div>
                            <div
                                className='card-body'
                                id='kt_help_body'>
                                {form && (
                                    <FormWrapper
                                        updated={handleUpdated}
                                        constants={constants}
                                        config={config}
                                        data={data}
                                        submitted={handleSubmit}
                                    />
                                )}
                                {!form && (
                                    <>
                                        <FormView
                                            constants={constants}
                                            config={config}
                                            data={data}
                                            updated={handleUpdated}
                                        />
                                        <button
                                            onClick={submit}
                                            className='btn btn-sm btn-primary'>
                                            {config.btn || 'Save'}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FormDrawer;
