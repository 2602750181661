import React, { useEffect, useState } from 'react';
import Image from '../../components/atoms/Image';
import { docDetails, docUrl } from '../../services/_storage.service';

const ImagePreview = ({ storage_id, className }) => {
    const [file, setFile] = useState(null);
    useEffect(() => {}, [storage_id]);

    const initData = async () => {
        const docs = await docDetails(storage_id);
        const doc = docs[0] || {};
        if (doc.file_name && doc.mime_type.indexOf('image') !== -1) {
            const data = await docUrl(doc.file_name);
            setFile(data);
        }
    };

    useEffect(() => {
        initData();
    }, []);

    return (
        <>
            {file && (
                <Image
                    src={file}
                    alt={file}
                    className={className}
                />
            )}
        </>
    );
};

export default ImagePreview;
