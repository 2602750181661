import axios from 'axios';
import { buildUrl } from '../helpers/str';

const BASE_URL =
    (process.env.REACT_APP_API_ROOT_ESIGN ||
        process.env.REACT_APP_API_ROOT_DEFAULT) + '/plugins/esign/';

export const urls = {
    esign_details: BASE_URL + ':id/',
};

export const getEsign = async (id, is_refresh = false) => {
    const { data } = await axios.get(buildUrl(urls.esign_details, { id }), {
        params: { is_refresh },
    });
    return data;
};
