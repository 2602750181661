import React, { useEffect, useState } from 'react';
import PopSheet from '../../components/molecules/PopSheet';
import { useTheme } from '../../context/ThemeContext';

const KycTnc = ({ onClick, onClose }) => {
    const [checked, setChecked] = useState(false);
    const { theme } = useTheme();

    const handleScroll = (e) => {
        setChecked(
            e.target.scrollTop + e.target.clientHeight + 20 >
                e.target.scrollHeight,
        );
    };

    useEffect(() => {}, []);

    return (
        <PopSheet
            closeOnClick={false}
            title='Applicant Declaration'
            onClose={onClose}>
            <div
                className='popsheet__body'
                onScroll={handleScroll}>
                <p>
                    By clicking the following button you agree to the above
                    terms and conditions and would like to share my information
                    for Credit Line application.
                </p>
                <hr />
                <p>
                    [I/We], ("the Applicant(s)"), hereby unconditionally and
                    irrevocably declare and confirm that:
                </p>
                <p>
                    The application for an onboarding facility ("Facility") is
                    made to a corporate , or any person(s) who provides a
                    facility pursuant to this declaration with the assistance of
                    Kiranovations Technology Pvt Ltd (herein refereed to as
                    “Crego”), which is engaged in designing, developing,
                    hosting, and providing technology platform(s) which collect
                    information, data and documents relating to persons from
                    various sources and in analysing, processing, and
                    disseminating such information, data, analysis and/or
                    documents to Corporated to enable Onboarding services for
                    their client / partners.
                </p>
                <p>
                    Crego (Kiranovations Technology Pvt. Ltd.) has been
                    entrusted with developing the onboarding facility by
                    Corporate -{' '}
                    <strong className='color-green'>
                        {theme?.company?.registered_name}
                    </strong>{' '}
                    for their client / partners.
                </p>
                <p>
                    Pursuant to [my/our] application for the Facility, [I/we]
                    make this declaration for the benefit and reliance of the
                    Corporate and Crego
                </p>
                <p>
                    [I/We] shall submit, sign and execute all such facility
                    agreements and other documents as may be prescribed and/or
                    required by the Corporate, in its discretion, in relation to
                    availing such a Facility at any point of time and the
                    applicable stamp duty in which regard shall be payable by
                    [me/us].
                </p>
                <p>
                    [I/We] fully acknowledge that the grant of the Facility is
                    at the sole discretion of Corporate and is subject to
                    [my/our] execution of documents deemed necessary by the
                    Corporate and [my/our] fulfilment of other closing
                    formalities as required by, and to the satisfaction of, the
                    Corporate in this regard. We also acknowledge that the
                    Corporate reserves the right to reject [my/our] application
                    for such a Facility without assigning any reasons
                    whatsoever, and that [I/we] reserve no right to appeal
                    against such a decision of the Corporate
                </p>
                <p>
                    [I/We] further acknowledge that the Onboarding fee, if any
                    given by [me/us] in relation to the application for such a
                    Facility is non-refundable and in case of the rejection of
                    [my/our] application, [I/we] shall not seek a refund of the
                    same.
                </p>
                <p>
                    [I/We] declare that [I am/we are] [citizen(s) of/resident(s)
                    in] India and [I/we] declare, warrant and represent that all
                    the particulars and information and details provided in the
                    application form to avail the Facility and documents given
                    by [me/us] to the Corporate and/or Crego in connection
                    therewith, are true, correct, accurate and up-to-date in all
                    respects and that [I/we] have not withheld or suppressed any
                    material information from the Crego or Crego in this regard.
                    [I/ We] further acknowledge that all the information given
                    by [me/us] in the application for a Facility upon the
                    Corporate request, shall form the basis of any facility that
                    the Corporate may decide to grant to [me/us]. We understand
                    and acknowledge that the Corporate and/or Crego reserve the
                    right to retain all applicable information, photographs and
                    other documents submitted by [me/us] (forming part of such
                    application and this declaration) and may not return the
                    same to [me/us].
                </p>
                <p>
                    [I/We] confirm that there are no insolvency proceedings
                    against [me/us] nor have [I/we] ever been adjudicated
                    insolvent by any judicial or other authority under
                    applicable law.
                </p>
                <p>
                    [I/We] also undertake to inform the Corporate of changes in
                    [my/our] occupation or employment and to provide any further
                    information that the Corporate may in its discretion
                    require.
                </p>
                <p>
                    [I/We] shall further inform the Corporate on the change in
                    [my/our] resident status, control, capital structure,
                    constitution and management (wherever applicable).
                </p>
                <p>
                    [I/We] hereby agree and give [my/our] consent for the
                    disclosure by the Corporate and/or Crego, waiving
                    specifically [my/our] right of notice, privacy, privity,
                    defamation for such disclosure by the Corporate and/or
                    Crego, of all or any of the following:
                </p>
                <p>
                    information and data relating to [me/us] as well our
                    directors and group companies (as applicable) or obligations
                    to be assumed by [me/us], our directors and/or our group
                    companies (as applicable) in connection with the Facility to
                    be availed by [me/us] as well as any other information
                    required to provided pursuant to such a Facility and as
                    required by the Corporate and/or Crego; and (b) Any default
                    committed by [me/us] and/or non-performance of our
                    obligations prescribed in any facility document or other
                    agreement executed pursuant to the Corporate’s instruction
                    (as determined by the Corporate in its sole discretion) in
                    relation to the Facility that will be granted to [me/us]
                    upon the successful processing of [my/our] application by
                    the Corporate, and as the Corporate may deem appropriate and
                    necessary to disclose and furnish to
                </p>
                <p>
                    The Reserve Bank of India ("RBI") or the Credit Information
                    Bureau (India) Limited ("CIBIL") and/ or any other agency
                    authorized in this behalf by RBI or any regulatory,
                    supervisory or other authority, court of law, tribunal or
                    person, in India or any other jurisdiction, where such
                    disclosure is required by law, regulation, judgment or order
                    of court or order of any tribunal;
                </p>
                <p>
                    any actual or potential assignee(s) or novatee(s) or
                    transferee(s) of any rights and obligations of the Corporate
                    or other participants in any of its rights and/or
                    obligations under or relating to the Facility for any
                    purposes connected with the proposed assignment or transfer;
                    or Crego. Provided that Kiranovations Technology Private
                    limited may use, disclose and/or process such information
                    and data disclosed by the Corporate in the manner as it
                    deems fit.
                </p>
                <p>
                    <strong>
                        [I/We] also acknowledge that the Corporate and/or Crego
                        shall have the right to seek Personal/Business
                        information pertaining to [me/us] from regulatory
                        authorities and other third parties including but not
                        limited to, alternate sources (Trusting Social score) to
                        establish credit worthiness, inter-alia, the RBI, the
                        CIBIL, Experian, CRIF Highmark, Equifax etc. in
                        accordance with applicable law
                    </strong>
                </p>
                <p>
                    [I/We] have no objection to furnish documents deemed
                    necessary by the Corporate and/or required as per 'Know Your
                    Customer' guidelines issued by RBI
                </p>
                <p>
                    [I/We] acknowledge and appreciate that the Corporate and
                    Crego will have right to seek information related to the
                    business transactions from [my/our] business partners for
                    the purpose of the sanction of Facility and ongoing
                    monitoring in relation thereto.
                </p>
                <p>
                    [I/We] further acknowledge and declare that the funds
                    availed by [me/us] pursuant to the Facility shall be used
                    for the purpose for which such a Facility has been granted
                    and will not be used for any speculative, prohibited,
                    forbidden, illegal, unlawful or anti-social purposes
                    whatsoever.
                </p>
                <p>
                    By accepting the terms and conditions, [I/We] [am/are]
                    deemed to have accepted, read, understood and agreed to be
                    bound by the terms (including amendments thereof) governing
                    the Facility as determined by the Corporate in its sole
                    discretion.
                </p>
                <p>
                    Further, [I/we] have no objection to the Corporate and/or
                    Crego from sending [me/us] promotional offers of their
                    products or services (including those of the subsidiaries,
                    affiliates or associate companies of the Corporate and/or
                    the Company). [I/We] further acknowledge that the use of the
                    above information shall not amount to violation of any of
                    [my/our] rights or privileges pertaining to privacy or
                    confidentiality. [I/We] further confirm that the privacy
                    rights available to us under, inter-alia, the Telecom
                    Regulatory Authority of India Act, 1997 and the Telecom
                    Commercial Communication Customer Preference Regulations,
                    2010, and any statutory amendments thereto, will not be
                    exercised by [me/us] in relation to any promotional offers
                    or information received from the Corporate and/or Crego and
                    their subsidiaries, affiliates or associate companies.
                    [I/We] accord the above consent, notwithstanding the grant
                    of Facility, its rejection by the Corporate, or the maturity
                    of such Facilities and authorise Corporate and/or Crego to
                    use the said information.
                </p>
                <h3>UIDAI RELATED DECLARATIONS</h3>
                <p>
                    [I/we] holder of the Aadhaar number stated herein, hereby
                    voluntary give my/our consent to Crego/Corporate to obtain
                    and use my/our Aadhaar number /Name and Fingerprints/Iris
                    and my/our Aadhar details to authenticate me/us with unique
                    identification Authority of India (UIDAI) as per Aadhaar
                    Act,2016 and all other applicable laws ('Aadhar Details').
                    [I/we] understand that furnishing of Aadhaar Details is
                    mandatory for the processing of my/our loan and related
                    services.
                </p>
                <p>
                    Crego/Corporate has informed me/us that my/our Aadhaar
                    details and identity information would only be used for
                    demographic authentication, validation, e-KYC purpose and
                    OTP authentication for availing services from
                    Crego/Corporate. A record of the search may be retained as
                    per applicable laws for usage by Crego/Corporate for KYC
                    verification or Aadhar based authentication or for
                    enforcement /compliance of Crego/Corporate rights and
                    obligations under applicable laws. [I/we] shall not hold
                    Crego [or any of its group/ related entities/ or its their
                    agents/ representatives/ service provider] liable for the
                    use/ sharing of the information and details for any purpose
                    mentioned herein in accordance with applicable laws.
                </p>
                <p>
                    [I/we] hereby give my expressed and unequivocal consent to
                    Crego/Corporate to retrieve/ procure such data/ details from
                    UIDAI for the purposes of verifying my/our
                    data/details/identity. I hereby further authorize Crego
                    /Corporate and its service providers that to do so.
                    Crego/Corporate and/or the service provider may check the
                    details that [I/we] have supplied against any particulars to
                    UIDAI
                </p>
                <p>
                    [I/we] hereby state that [I/we] have no objection in
                    authenticating my/our details with Aadhar based
                    authentication system and hereby consent to and authorize
                    Crego/Corporate to retrieve my/our personal details from
                    UIDAI for Aadhar based authentication for the purpose of
                    generation of E-sign, for registration of e-mandate and for
                    validating my consent to the terms and conditions of the
                    definitive agreements for the services of Crego/Corporate.
                </p>
                <p>
                    Crego/Corporate has also informed that my/our biometric
                    details will not be stored /shared and will be submitted to
                    Central Identities Data Repository (CIDR) only for the
                    purpose of authentication.
                </p>
                <p>
                    [I/we] also authorize Crego/Corporate to link and
                    authenticate my/our Aadhaar no to all its services of
                    Crego/Corporate as may be opened in the future in addition
                    to the those mentioned herein
                </p>
                <p>
                    [I/we] declare that the details submitted by me/us in the
                    Aadhaar letter are accurate, current and complete. [I/we]
                    will not hold Crego/ Corporate and any of its officials
                    responsible in case of any incorrect information provided by
                    me/us.
                </p>
                <p>
                    [I/we] understand that Crego/Corporate and the service
                    providers shall ensure security, protection, confidentiality
                    and access of my/our personal data provided for the purpose
                    of Aadhaar based authentication / KYC verification and other
                    services as mentioned above in accordance with applicable
                    laws.
                </p>
                <p>
                    [I/we] agree that the information shared under this
                    declaration shall be retained/ stored/ transmitted/
                    furnished by Crego as per applicable laws.
                </p>
                <p>
                    By signing/ accepting the terms and conditions, [I/We]
                    [am/are] deemed to have accepted, read, understood and
                    agreed to be bound by the terms (including amendments
                    thereof) governing the Facility as determined by the
                    Corporate in its sole discretion.
                </p>
                <p>
                    In this regard, [I/we] represent and warrant that the
                    declarations made above by [me/us] have been made in
                    [my/our] true and lawful capacity and further recognize that
                    the Corporate and/or Crego will rely on the correctness and
                    accuracy of the declarations made by [me/us] in this
                    declaration. [I/We] acknowledge that any inaccuracies or
                    falsehoods in the declarations made by [me/us] and/or
                    information provided by [me/us] shall give an unconditional
                    right to the Corporate, at any point in time, to cancel the
                    Facility and exercise all rights available to such Corporate
                    against [me/us] under applicable law.
                </p>
            </div>
            {/* <div className='popsheet__footer'>
                <div className='color-yellow'>
                    Read Terms and Scroll Down to bottom before Clicking "Agree
                    & Start Application" to start your KYC
                </div>
                <button
                    onClick={onClick}
                    disabled={!checked}
                    className='btn mt-20'>
                    Agree & Start Application
                </button>
            </div> */}
        </PopSheet>
    );
};

export default KycTnc;
