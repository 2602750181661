import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormView from '../../components/organisms/FormView';
import { useUser } from '../../context/UserContext';
import { validatePayload } from '../../helpers/form';
import {
    createAddress,
    getLead,
    updateApplicant,
} from '../../services/_crm.service';
import ImagePreview from '../Storage/ImagePreview';
import KycFormAddress from './KycForm/KycFormAddress';
import KycFormDocuments from './KycForm/KycFormDocuments';
import { routes } from './config';
import { alertError } from '../../helpers/alert';

const KycPerson = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [loading, setLoading] = useState(false);
    const [lead, setLead] = useState({});
    const [address, setAddress] = useState({});
    const [photo, setPhoto] = useState(null);
    const [signature, setSignature] = useState(null);
    const [isDocsUploaded, setIsDocsUploaded] = useState(false);
    const personFormItems = [
        { type: 'text', name: 'name', is_required: true },
        { type: 'text', name: 'pan_no', is_required: true },
        {
            type: 'datepicker',
            name: 'dob',
            is_required: true,
        },
    ];
    const initLead = async () => {
        const response = await getLead(user.lead_id);
        setLead(response);
    };

    const handleAddressUpdated = (response) => {
        setAddress(response);
    };

    const handleSubmit = async () => {
        const businessBank =
            user?.program_config?.borrower?.business?.banks?.length > 0;
        const valid = validatePayload(personFormItems, lead.person_entity);

        setLoading(true);
        if (address && address.address_line) {
            await createAddress(address);
        }
        if (valid && isDocsUploaded) {
            await updateApplicant(lead.person_entity.id, lead.person_entity);
            if (businessBank) {
                navigate(routes.bank);
            } else {
                navigate(routes.validate);
            }
        } else if (!isDocsUploaded) {
            alertError('Please upload PAN photo', 'PAN file required');
        }
        setLoading(false);
    };

    const handleUpdated = (response) => {
        const tmp = lead;
        tmp.person_entity = response;
        setLead(tmp);
    };

    useEffect(() => {
        if (user.lead_id) {
            initLead();
        }
    }, []);
    return (
        <div className='container'>
            <h1>Authorized Signatory</h1>
            <p className='mb-10'>
                Please fill the following details to complete your KYC.
            </p>
            {photo && (
                <div className='profile mb-10'>
                    <ImagePreview
                        className='profile__image'
                        storage_id={photo}
                    />
                </div>
            )}
            {lead?.person_entity?.id && (
                <FormView
                    updated={handleUpdated}
                    data={lead.person_entity}
                    config={{
                        items: personFormItems,
                    }}
                    submitted={handleSubmit}
                />
            )}
            <h4 className='mt-30 mb-5'>Documents</h4>

            <KycFormDocuments
                applicant_id={lead?.person_entity?.id}
                setPhoto={(response) => setPhoto(response)}
                setSignature={(response) => setSignature(response)}
                onValidate={setIsDocsUploaded}
            />
            <h4 className='mt-30 mb-5'>Current Residence Address</h4>
            <KycFormAddress
                onUpdate={handleAddressUpdated}
                applicant={lead?.person_entity}
            />
            <button
                onClick={handleSubmit}
                className='btn btn-fixed'
                disabled={loading}>
                {user?.program_config?.borrower?.business?.banks?.length > 0
                    ? 'Proceed'
                    : 'Finish KYC'}
            </button>
        </div>
    );
};

export default KycPerson;
