import React, { useEffect } from 'react';
import FormWrapper from '../../../components/organisms/FormView/FormWrapper';
import { urls } from '../../../services/_crm.service';

const KycFormBasic = ({ lead, onSubmit }) => {
    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit();
        }
    };

    useEffect(() => {}, [lead]);

    return (
        <>
            {lead?.person_entity?.id && (
                <FormWrapper
                    data={lead.person_entity}
                    config={{
                        url: urls.applicant_details,
                        method: 'PATCH',
                        items: [
                            { type: 'text', name: 'name' },
                            { type: 'text', name: 'pan_no' },
                            { type: 'datepicker', name: 'dob' },
                        ],
                    }}
                    submitted={handleSubmit}
                />
            )}
        </>
    );
};

export default KycFormBasic;
