import React from 'react';
import FormView from '../../../../components/organisms/FormView';
import { transform } from '../../../../helpers/transform';
import OnboardingDocs from '../../../Onboarding/OnboardingDocs';

const LeadProperty = ({ lead }) => {
    return (
        <div className='card p-15'>
            <FormView
                config={{
                    items: [
                        {
                            type: 'select',
                            name: 'type_of_asset',
                            options: transform('options', [
                                'property',
                                'vehicle',
                                'other',
                            ]),
                        },
                        {
                            type: 'select',
                            name: 'kind',
                            options: transform('options', [
                                'commercial',
                                'residential',
                            ]),
                            when: 'type_of_asset:property',
                        },
                        {
                            type: 'select',
                            name: 'kind',
                            options: transform('options', [
                                'commercial',
                                'private',
                            ]),
                            when: 'type_of_asset:vehicle',
                        },
                        {
                            type: 'textarea',
                            name: 'address',
                            when: 'type_of_asset:property',
                        },
                        {
                            type: 'textarea',
                            name: 'comments',
                            when: 'type_of_asset:other',
                        },
                        {
                            name: 'vehicle_number',
                            when: 'type_of_asset:vehicle',
                        },
                        {
                            name: 'area',
                            label: 'Area (in sqft)',
                            when: 'type_of_asset:property',
                        },
                    ],
                }}
            />
            <div className='mt-15'>
                <OnboardingDocs
                    title='Documents'
                    className='mt-20'
                    applicant={lead.primary_applicant}
                />
            </div>
        </div>
    );
};

export default LeadProperty;
