import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InputFieldAmount from '../../../components/molecules/InputField/InputFieldAmount';
import { useTheme } from '../../../context/ThemeContext';
import { useUser } from '../../../context/UserContext';
import { initiatePayment } from '../../../services/_accounting.service';

const RecordsForm = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [amount, setAmount] = useState('');
    const { theme } = useTheme();
    const { user } = useUser();

    console.log(theme);
    console.log(user);

    const serviceAccounts = user?.service_accounts;

    const jquery = document.createElement('script');
    jquery.src =
        'https://www.paynimo.com/paynimocheckout/client/lib/jquery.min.js';
    jquery.type = 'text/javascript';
    document.head.appendChild(jquery);

    // Load Checkout script
    const paynimo = document.createElement('script');
    paynimo.src =
        'https://www.paynimo.com/paynimocheckout/server/lib/checkout.js';
    paynimo.type = 'text/javascript';
    document.body.appendChild(paynimo);

    useEffect(() => {
        console.log('amount', searchParams.get('amount'));
        setAmount(searchParams.get('amount') || '');
    }, [searchParams]);

    const handleCheckoutResponse = (a, b, c) => {
        console.log('handleCheckoutResponse', a, b, c);
    };

    useEffect(() => {
        if (amount) {
            paynimo.onload = () => {
                function handleResponse(res) {
                    console.log(res);
                }

                const handleButtonClick = async (e) => {
                    e.preventDefault();

                    const response = await initiatePayment({
                        account_id: serviceAccounts?.lms?.id,
                        amount,
                        redirect_url:
                            process.env.REACT_APP_PWA_URL +
                            '/borrower/records/finished',
                    });
                    console.log(response);

                    const reqJson = {
                        features: {
                            enableAbortResponse: true,
                            enableExpressPay: true,
                            enableInstrumentDeRegistration: true,
                            enableMerTxnDetails: true,
                        },
                        consumerData: {
                            deviceId: 'WEBSH2',
                            token: response?.token,
                            returnUrl: response?.returnUrl,
                            responseHandler: handleResponse,
                            paymentMode: response?.paymentMode,
                            merchantLogoUrl:
                                'https://www.paynimo.com/CompanyDocs/company-logo-vertical.png',
                            merchantId: response?.merchantId,
                            currency: response?.currency,
                            consumerId: response?.consumerId,
                            txnId: response?.txnId,
                            items: response?.items,
                            customStyle: {
                                PRIMARY_COLOR_CODE: '#0047bb',
                                SECONDARY_COLOR_CODE: '#FFFFFF',
                                BUTTON_COLOR_CODE_1: '#0047bb',
                                BUTTON_COLOR_CODE_2: '#FFFFFF',
                            },
                        },
                    };

                    console.log(reqJson);

                    $.pnCheckout(reqJson);
                    if (reqJson.features.enableNewWindowFlow) {
                        pnCheckoutShared.openNewWindow();
                    }
                };

                document
                    .getElementById('pay-btn')
                    .addEventListener('click', handleButtonClick);
            };
        }
    }, [amount, serviceAccounts]);

    return (
        <div className='container'>
            <div className='profile'>
                <div className='profile__initial bg-green'>
                    <i className='ri ri-cash-line color-white'></i>
                </div>
                <div className='profile__info'>
                    <h4 className='profile__info__name'>
                        Paying {theme?.company?.registered_name}
                    </h4>
                </div>
            </div>
            <div className='flex flex-column align-center mt-50'>
                <InputFieldAmount
                    defaultValue={amount}
                    readOnly={searchParams.get('full')}
                    className='control-hero'
                    config={{
                        name: 'amount',
                    }}
                    onChange={(value) => setAmount(value)}
                />
                <button
                    id='pay-btn'
                    className='btn btn-inline mt-20'>
                    Proceed to Pay
                </button>
            </div>
        </div>
    );
};

export default RecordsForm;
