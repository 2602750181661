import moment from 'moment';

export const dateFromEpoch = (value, format = 'Do MMM YYYY') => {
    if (!value) {
        return value;
    }
    const date = moment.unix(value);
    return date.format(format);
};

export const dateTimeFromEpoch = (value, format = 'Do MMM YYYY, HH:mm') => {
    if (!value) {
        return '';
    }
    return moment.unix(value).format(format);
};

export const date = (value, format, sourceFormat) => {
    if (!value) {
        return '';
    }
    const date = moment(value, sourceFormat || '');
    return date.format(format || 'Do MMM YYYY');
};

export const dateTime = (value, format, sourceFormat) => {
    if (!value) {
        return '';
    }
    return moment(value, sourceFormat || '').format(
        format || 'Do MMM YYYY, HH:mm',
    );
};

export const relativeDateTimeFromEpoch = (value) => {
    if (!value) {
        return '';
    }
    return moment.unix(value).fromNow();
};

export const days = (
    from,
    to,
    format = { from: 'YYYY-MM-DD', to: 'YYYY-MM-DD' },
) => {
    to = to ? moment(to, format.to) : moment();
    if (!from || !to) {
        return '';
    }
    from = moment(from, format.from);
    return moment(to).diff(moment(from), 'days');
};

export const daysClass = (from, to) => {
    const num = days(from, to);
    if (num > -1) {
        return 'red';
    } else if (num > -15) {
        return 'yellow';
    }
};

export const humanizedDate = (date, epoch = true) => {
    if (epoch) {
        date = dateFromEpoch(date, 'YYYY-MM-DD');
    }
    return moment(date).fromNow();
};

export const humanizedDue = (date, epoch = true) => {
    if (!date) {
        return '';
    }
    date = humanizedDate(date);
    if (date.indexOf('ago') !== -1) {
        return 'Overdue since ' + date.replace('ago', '');
    } else if (date.indexOf('in') !== -1) {
        return 'Due ' + date;
    }
};
