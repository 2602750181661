import React from 'react';
import ListView from '../../../components/organisms/ListView';
import { urls as crmUrls } from '../../../services/_crm.service';
import { routes } from '../config';

const LeadsList = ({ tab }) => {
    const detailItems = [
        {
            type: 'heading',
            name: 'primary_applicant.name',
            className: 'list__item__content__heading-sm',
        },
        {
            type: 'items',
            items: [
                {
                    name: 'primary_applicant.business_type',
                    transform: 'beautify',
                },
                {
                    name: 'primary_applicant.primary_mobile',
                },
            ],
        },
    ];
    const data = [
        {
            id: 'f96d1c49-28b4-41e7-a597-3e7154483e59',
            primary_applicant: {
                name: 'NSM VENTURES PRIVATE LIMITED',
                primary_mobile: '9920828104',
                business_type: 'private_limited',
            },
            ref_id: 'CL3LWZ2',
            program: {
                ref_id: 'CL3LWZ2A',
                program: {
                    id: 'fd332f90-5d1b-428b-b8f5-37014b5be8d7',
                    ref_id: 'CP00XRK',
                    name: 'MSFT <> AMEX',
                    product_type: 'scf',
                },
            },
        },
        {
            id: '08d16dcc-df03-4c95-98e3-952b7190c985',
            primary_applicant: {
                name: 'Kiranovations Technology Private Limited',
                primary_mobile: '9920828104',
                business_type: 'private_limited',
            },
            ref_id: 'CL3LXZ1',
            program: {
                ref_id: 'CL3LWZ2A',
                program: {
                    id: 'fd332f90-5d1b-428b-b8f5-37014b5be8d7',
                    ref_id: 'CP00XRK',
                    name: 'MSFT <> AMEX',
                    product_type: 'scf',
                },
            },
        },
    ];
    return (
        <ListView
            config={{
                route: routes.leads,
                tab: tab || 'kyc_pending',
                tabs: [
                    {
                        value: 'all',
                        url: crmUrls.leads,
                        path: routes.lead_details,
                        name: 'All',
                        items: detailItems,
                    },
                    {
                        value: 'kyc_pending',
                        url: crmUrls.leads,
                        path: routes.lead_details,
                        name: 'KYC',
                        items: detailItems,
                    },
                    {
                        value: 'valuation',
                        url: crmUrls.leads,
                        path: routes.lead_details,
                        name: 'Valuation',
                        items: detailItems,
                    },
                    {
                        value: 'fi',
                        url: crmUrls.leads,
                        path: routes.lead_details,
                        name: 'FI',
                        items: detailItems,
                    },
                    {
                        value: 'activation',
                        url: crmUrls.leads,
                        path: routes.lead_details,
                        name: 'Activation',
                        items: detailItems,
                    },
                    {
                        value: 'disbursed',
                        url: crmUrls.leads,
                        path: routes.lead_details,
                        name: 'Disbursed',
                        items: detailItems,
                    },
                ],
                data,
            }}
        />
    );
};

export default LeadsList;
