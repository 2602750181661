import React, { createContext, useContext, useState } from 'react';

const ConstantsContext = createContext();

export function useConstants() {
    return useContext(ConstantsContext);
}

export function ConstantsProvider({ children }) {
    const [constants, setConstants] = useState(null);

    const cacheConstants = (userData) => {
        setConstants(userData);
    };

    const contextValue = {
        constants,
        cacheConstants,
    };

    return (
        <ConstantsContext.Provider value={contextValue}>
            {children}
        </ConstantsContext.Provider>
    );
}
