import React from 'react';

const LoanDpd = ({ loan }) => {
    return (
        <div className='card mt-20 bg-red-dark p-20'>
            <h4>
                <span className='ml-5 color-red'>{loan?.summary?.dpd}</span> DPD
            </h4>
            <small>
                Please Pay the overdue amount to prevent any impact on your
                credit score.
            </small>
        </div>
    );
};

export default LoanDpd;
