import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/atoms/Loader';
import DetailView from '../../components/organisms/DetailView';
import FormView from '../../components/organisms/FormView';
import { useUser } from '../../context/UserContext';
import { alertError } from '../../helpers/alert';
import { searchArray } from '../../helpers/json';
import { getPersonTag } from '../../helpers/str';
import { validatePincode } from '../../helpers/validate';
import {
    createAddress,
    createApplicant,
    updateApplicant,
} from '../../services/_crm.service';
import KycFormAddress from '../Kyc/KycForm/KycFormAddress';
import OnboardingDocs from './OnboardingDocs';
import OnboardingSignatory from './OnboardingSignatory';

const OnboardingBusiness = ({ lead, onNext }) => {
    const { user } = useUser();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [business, setBusiness] = useState({});
    const [person, setPerson] = useState('');
    const [address, setAddress] = useState({});
    const business_config = user?.program_config?.borrower?.business;

    const handlePersonChange = (value) => {
        setPerson(value);
    };

    const handleSubmit = async (e) => {
        let status = false;
        e.preventDefault();
        setLoading(true);
        status = await saveBusiness();
        const applicantNames = lead.applicants
            .map((row) => {
                return row.applicant_type === 'person' ? row.name : null;
            })
            .filter((n) => n);

        console.log(applicantNames);

        if (!person?.name && person?.other) {
            alertError('Please enter authorized signatory name');
            return setLoading(false);
        } else if (
            !person?.name &&
            lead?.business_entity?.business_type !== 'proprietorship'
        ) {
            alertError('Please select authorized signatory');
            return setLoading(false);
        }

        if (
            person?.name &&
            (!lead?.person_entity?.name ||
                applicantNames.indexOf(person?.name) === -1)
        ) {
            status = await savePerson();
        } else if (person?.name) {
            status = await updatePerson();
        }

        if (address?.pincode && validatePincode(address?.pincode)) {
            status = await saveAddress();
        } else {
            status = validatePincode(address?.pincode);
        }
        if (status !== false) {
            onNext();
        }
        setLoading(false);
    };

    const saveBusiness = async () => {
        return await updateApplicant(lead?.business_entity?.id, business);
    };

    const savePerson = async () => {
        return await createApplicant({
            applicant_type: 'person',
            lead_id: lead?.id,
            name: person?.name,
            source: person?.source,
            is_authorized_signatory: true,
            primary_mobile: lead?.business_entity?.primary_mobile,
            tags: [getPersonTag(lead?.business_entity?.business_type)],
        });
    };

    const updatePerson = async () => {
        const existing = searchArray(lead.applicants, 'name', person?.name);
        console.log(person, {
            name: person?.name,
            source: person?.source,
            is_authorized_signatory: true,
        });
        return await updateApplicant(existing.id || lead?.person_entity?.id, {
            ...person,
            ...{
                name: person?.name,
                source: person?.source,
                is_authorized_signatory: true,
            },
        });
    };

    const saveAddress = async () => {
        return await createAddress(address);
    };

    useEffect(() => {
        if (lead?.business_entity) {
            setBusiness(lead.business_entity);
        }
        if (lead?.person_entity?.name) {
            setPerson(lead?.person_entity);
        }
    }, [lead]);

    return (
        <div className='container'>
            {lead?.business_entity?.name && (
                <>
                    <h3>{lead?.business_entity?.name}</h3>
                    <p className='mb-10'>
                        Verify your business information to continue KYC
                    </p>
                    {lead?.data_source === 'GST_DETAILS' && (
                        <DetailView
                            details={lead}
                            config={{
                                items: [
                                    {
                                        name: 'business_entity.gstin',
                                        label: 'GST Number',
                                    },
                                    {
                                        name: 'business_entity.date_of_incorporation',
                                        label: 'Date of Incorporation',
                                        transform: 'date',
                                    },
                                    {
                                        name: 'business_entity.business_type',
                                        transform: 'beautify',
                                    },
                                ],
                            }}
                        />
                    )}
                </>
            )}
            {loading && <Loader loading={loading} />}
            <FormView
                data={business}
                updated={setBusiness}
                config={{
                    items: [
                        ...(lead.data_source === 'GST_DETAILS'
                            ? []
                            : [
                                  ...(lead?.business_entity?.name
                                      ? []
                                      : [
                                            {
                                                type: 'text',
                                                name: 'name',
                                                is_required: true,
                                            },
                                        ]),
                                  ...(lead?.business_entity?.business_type
                                      ? []
                                      : [
                                            {
                                                type: 'select',
                                                name: 'business_type',
                                                options_src: 'constants',
                                                options: 'business_types',
                                                is_required: true,
                                            },
                                        ]),
                                  ...(lead?.business_entity?.gstin
                                      ? []
                                      : [
                                            {
                                                type: 'text',
                                                name: 'gstin',
                                            },
                                        ]),
                                  ...(lead?.business_entity?.gstin
                                      ? []
                                      : [
                                            {
                                                type: 'datepicker',
                                                name: 'date_of_incorporation',
                                            },
                                        ]),
                              ]),
                        ...(business_config?.extra_data
                            ? business_config?.extra_data?.map((extra) => {
                                  extra.name = extra.key;
                                  return extra;
                              })
                            : []),
                    ],
                }}
            />
            {business?.id && (
                <>
                    <div className='mt-20'>
                        <OnboardingDocs
                            title='Business Documents'
                            doc_type='business_document_names'
                            className='mt-20'
                            applicant={lead.business_entity}
                        />
                    </div>
                    <div className='mt-20'>
                        <h4 className='mt-20'>Business Address</h4>
                        <KycFormAddress
                            applicant={business}
                            onUpdate={(response) => setAddress(response)}
                        />
                    </div>
                </>
            )}
            {user.lead_id &&
                lead?.business_entity?.business_type !== 'proprietorship' && (
                    <div className='mt-20'>
                        <OnboardingSignatory
                            lead_id={user.lead_id}
                            onChange={handlePersonChange}
                        />
                    </div>
                )}
            <button
                onClick={handleSubmit}
                className='btn btn-fixed'>
                Save & Proceed
            </button>
        </div>
    );
};

export default OnboardingBusiness;
