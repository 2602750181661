import React, { useEffect, useState } from 'react';
import InputSelect from './InputSelect';
import InputText from './InputText';
import InputTextArea from './InputTextArea';
import { flattenForm } from '../../helpers/form';

const FormFields = ({ config, data, onChange }) => {
    const [request, setRequest] = useState(data || {});

    const handleChange = (val, name) => {
        const tmp = { ...request };
        tmp[name] = val;
        setRequest(tmp);
        onChange(tmp);
    };

    useEffect(() => {
        const initRequest = () => {
            const tmp = flattenForm(config.items, data);
            setRequest(tmp);
        };
        initRequest();
    }, [data]);

    return (
        <div className='form'>
            {config.items.map((item) => {
                const value = request[item.name] || item.value || '';
                return (
                    <div
                        className='form__item'
                        key={item.name}>
                        {/* <InputLabel>{item.label || beautify(item.name)}</InputLabel> */}
                        {(!item.type ||
                            ['password', 'number', 'date'].indexOf(
                                item.type,
                            ) !== -1) && (
                            <InputText
                                defaultValue={value}
                                config={item}
                                onChange={handleChange}
                            />
                        )}
                        {item.type === 'textarea' && (
                            <InputTextArea
                                defaultValue={value}
                                config={item}
                                onChange={handleChange}
                            />
                        )}
                        {item.type === 'select' && (
                            <InputSelect
                                defaultValue={value}
                                config={item}
                                onChange={handleChange}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default FormFields;
