import React from 'react';
import Icon from '../atoms/Icon';
import AddForm from './AddForm';
import Filter from './Filter';

const PageHeader = ({
    className,
    form,
    children,
    subtitle,
    back,
    toolbar,
    filter,
    onSubmit,
}) => {
    return (
        <div className={`header ${className || ''}`}>
            {!back && (
                <div className='header__title'>
                    <h1>{children}</h1>
                    {subtitle && <p className='header__subtitle'>{subtitle}</p>}
                </div>
            )}
            {back && (
                <>
                    <div className='header__back'>
                        <a href={back}>
                            <Icon name='arrow-left-line' />
                        </a>
                    </div>
                    <div className='header__title-sm'>
                        <h5>{children}</h5>
                    </div>
                </>
            )}
            <div className='header__toolbar'>
                &nbsp;
                {filter && <Filter />}
                {form && (
                    <div>
                        <AddForm
                            onSubmit={onSubmit}
                            config={form}
                        />
                    </div>
                )}
                {toolbar && <>{toolbar}</>}
            </div>
        </div>
    );
};

export default PageHeader;
