import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AccountProfile from './AccountProfile';
import AccountSummary from './AccountSummary';

const Account = () => {
    return (
        <Routes>
            <Route
                index
                element={<AccountProfile />}></Route>
            ̵
            <Route
                path='/summary'
                element={<AccountSummary />}></Route>
            ̵
        </Routes>
    );
};

export default Account;
