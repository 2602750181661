import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppHeader from '../../components/organisms/AppHeader';
import { useConstants } from '../../context/ConstantsContext';
import MetaContext from '../../context/MetaContext';
import { listConstants } from '../../services/_constants.service';
import KycBank from './KycBank';
import KycBusiness from './KycBusiness';
import KycForm from './KycForm';
import KycFormFace from './KycForm/KycFormFace';
import KycInit from './KycInit';
import KycPerson from './KycPerson';
import KycSummary from './KycSummary';
import KycPersonalDetails from './KycSummary/KycPersonalDetails';
import KycValidate from './KycValidate';

const Kyc = () => {
    const { setMeta } = useContext(MetaContext);
    const { cacheConstants } = useConstants();
    const [initialized, setInitialized] = useState(false);

    const getConstants = async () => {
        try {
            const response = await listConstants();
            cacheConstants(response);
        } catch (error) {
        } finally {
            setInitialized(true);
        }
    };

    useEffect(() => {
        getConstants();
        setMeta({ title: 'KYC | Crego for Borrowers' });
    }, []);

    return (
        <>
            {initialized && (
                <>
                    <AppHeader logout={true} />
                    <div className='content'>
                        <Routes>
                            <Route
                                index
                                element={<KycInit />}></Route>
                            <Route
                                path='/summary'
                                element={<KycSummary />}></Route>
                            <Route
                                path='/personal-details'
                                element={<KycPersonalDetails />}></Route>
                            <Route
                                path='/business'
                                element={<KycBusiness />}></Route>
                            <Route
                                path='/person'
                                element={<KycPerson />}></Route>
                            <Route
                                path='/bank'
                                element={<KycBank />}></Route>
                            <Route
                                path='/validate'
                                element={<KycValidate />}></Route>
                            <Route
                                path='/form'
                                element={<KycForm />}></Route>
                            <Route
                                path='/liveliness'
                                element={<KycFormFace />}></Route>
                        </Routes>
                    </div>
                </>
            )}
        </>
    );
};

export default Kyc;
