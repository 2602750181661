import React, { useEffect, useMemo, useState } from 'react';
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useSearchParams,
} from 'react-router-dom';
import BorrowerApp from './apps/BorrowerApp';
import { root as borrowerRoot } from './apps/BorrowerApp/config';
import { root as companyRoot } from './apps/CompanyApp/config';
import Kyc from './apps/Kyc';
import Auth from './auth';
import { routes as authRoutes } from './auth/_auth.config';
import { ConstantsProvider } from './context/ConstantsContext';
import MetaContext from './context/MetaContext';
import NavContext from './context/NavContext';
import { useUser } from './context/UserContext';
import { buildUrl } from './helpers/str';
import { getPwaConfig } from './services/_unr.service';
import { useTheme } from './context/ThemeContext';
import Onboarding from './apps/Onboarding';
import CompanyApp from './apps/CompanyApp';

const AppRoutes = () => {
    const { type, user } = useUser();
    const { cacheTheme } = useTheme();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const [meta, setMeta] = useState({});
    const [nav, setNav] = useState({});
    const hideNav = [];

    const loginRequired = ['borrower'];

    const initPwa = async (id) => {
        const response = await getPwaConfig(id, 'ref_id');
        cacheTheme(response);
    };

    useEffect(() => {
        const extra_data = {};
        const program_ref_id =
            searchParams.get('program_ref_id') ||
            localStorage.getItem('program_ref_id');
        const mobile = searchParams.get('mobile');
        if (mobile) {
            localStorage.setItem('mobile', mobile);
        }

        if (program_ref_id) {
            initPwa(program_ref_id);
            localStorage.setItem('program_ref_id', program_ref_id);
        }

        searchParams.forEach((value, key) => {
            if (
                ['program_ref_id', 'redirectTo', 'mobile', 'program'].indexOf(
                    key,
                ) === -1
            ) {
                extra_data[key] = value;
            }
        });

        if (Object.values(extra_data)?.length > 0) {
            localStorage.setItem('extra_data', JSON.stringify(extra_data));
        }

        const app = location.pathname.split('/')[1];
        if (loginRequired.indexOf(app) !== -1 && !user) {
            window.location.href = buildUrl(
                authRoutes.login,
                { redirectTo: window.location.href },
                { useExtras: true },
            );
        }
    }, [location]);

    const updateMeta = (val) => {
        document.title = val.title;
        setMeta(val);
    };

    const updateNav = (val) => {
        setNav(val);
    };

    const memoizedMeta = useMemo(() => ({ meta, setMeta: updateMeta }), [meta]);
    const memoizedNav = useMemo(() => ({ nav, setNav: updateNav }), [nav]);

    return (
        <ConstantsProvider>
            <MetaContext.Provider value={memoizedMeta}>
                <NavContext.Provider value={memoizedNav}>
                    <div className='app'>
                        <Routes>
                            <Route
                                index
                                element={
                                    <Navigate
                                        to={`${
                                            type === 'company'
                                                ? companyRoot
                                                : borrowerRoot
                                        }`}
                                    />
                                }
                            />
                            <Route
                                path='auth/*'
                                element={<Auth />}
                            />
                            <Route
                                path={`${borrowerRoot}/*`}
                                element={<BorrowerApp />}
                            />
                            <Route
                                path={`${companyRoot}/*`}
                                element={<CompanyApp />}
                            />
                            <Route
                                path='kyc/*'
                                element={<Kyc />}></Route>
                            <Route
                                path='onboarding/*'
                                element={<Onboarding />}></Route>
                        </Routes>
                    </div>
                </NavContext.Provider>
            </MetaContext.Provider>
        </ConstantsProvider>
    );
};

export default AppRoutes;
