import React, { useEffect, useState } from 'react';
import { confirm } from '../../../helpers/alert';
import { beautify, buildUrl } from '../../../helpers/str';
import FormDrawer from '../../organisms/FormView/FormDrawer';
import JsonList from '../JsonList';
import { getValue } from '../../../helpers/json';
import SmartLabel from '../../organisms/SmartLabel';

const FormControlTable = (props) => {
    const { item, onChange, value, constants } = props;
    const [request, setRequest] = useState([]);

    const handleAdd = (data) => {
        const tmp = [...request];
        tmp.push(data);
        setRequest(tmp);
        onChange(item.name, tmp);
    };

    const deleteRow = (index) => {
        confirm(
            'Delete Item?',
            'Do you really want to delete this?',
            async () => {
                const tmp = [...request];
                tmp.splice(index, 1);
                setRequest(tmp);
                onChange(item.name, tmp);
            },
        );
    };

    useEffect(() => {
        if (value) {
            setRequest(value);
        }
    }, [item, value]);

    return (
        <div className='table-responsive w-100'>
            <table className='table table-sm gx-4'>
                <thead>
                    <tr className='bg-light-dark'>
                        {item?.form?.items &&
                            item.form.items.map((row, index) => {
                                return (
                                    !row.remove && (
                                        <React.Fragment
                                            key={`head_${row.name}`}>
                                            {row.type !== 'separator' && (
                                                <th className='text-gray-400 fw-bold fs-7 text-uppercase align-middle'>
                                                    {row.label ||
                                                        beautify(row.name)}
                                                </th>
                                            )}
                                        </React.Fragment>
                                    )
                                );
                            })}
                        {!item.disabled && (
                            <th className='text-end'>
                                <FormDrawer
                                    constants={constants}
                                    form={false}
                                    icon='plus-lg'
                                    className='info'
                                    name='charges_form'
                                    config={item.form}
                                    submitted={handleAdd}
                                />
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {request?.map((details, index) => {
                        return (
                            <tr key={`body_${index}`}>
                                {item?.form?.items?.map((row) => {
                                    const value = getValue(
                                        details,
                                        row.as || row.name,
                                    );
                                    return (
                                        !row.remove && (
                                            <React.Fragment
                                                key={`head_${row.name}`}>
                                                {row.type !== 'separator' && (
                                                    <td>
                                                        {row.translate &&
                                                            value &&
                                                            typeof value !==
                                                                'object' && (
                                                                <SmartLabel
                                                                    url={buildUrl(
                                                                        row
                                                                            .translate
                                                                            .url,
                                                                        {
                                                                            [row
                                                                                .translate
                                                                                .id]:
                                                                                value,
                                                                        },
                                                                    )}
                                                                    config={
                                                                        row
                                                                            .translate
                                                                            .config
                                                                    }
                                                                />
                                                            )}
                                                        {!row.translate &&
                                                            typeof value !==
                                                                'object' && (
                                                                <>{value}</>
                                                            )}
                                                        {typeof value ===
                                                            'object' && (
                                                            <JsonList
                                                                data={value}
                                                            />
                                                        )}
                                                    </td>
                                                )}
                                            </React.Fragment>
                                        )
                                    );
                                })}
                                {!item.disabled && (
                                    <td className='text-end'>
                                        <button
                                            type='button'
                                            onClick={() => deleteRow(index)}
                                            className='btn btn-icon btn-circle btn-danger btn-sm'>
                                            <i className={`bi bi-trash`}></i>
                                        </button>
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default FormControlTable;
