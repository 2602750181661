import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import KycTnc from '../apps/Kyc/KycTnc';
import PageHeader from '../components/molecules/PageHeader';
import FormView from '../components/organisms/FormView';
import { useTheme } from '../context/ThemeContext';
import { alertError } from '../helpers/alert';
import { verifyOtp } from '../services/_identity.service';
import { getPwaConfig } from '../services/_unr.service';
import { routes } from './_auth.config';

const VerifyLogin = () => {
    const [searchParams] = useSearchParams();
    const [showTnc, setShowTnc] = useState(false);
    const navigate = useNavigate();
    const mobile = searchParams.get('mobile');
    const program = searchParams.get('program');
    const { cacheTheme } = useTheme();
    const [request, setRequest] = useState({ mobile, program_id: program });

    const handleUpdated = (response) => {
        setRequest(response);
    };

    const handleSubmitClick = (e) => {
        e.preventDefault();
        if (!request.otp) {
            return alertError(
                'Please enter the OTP sent to your mobile number',
            );
        }
        handleSubmit();
    };

    const handleSubmit = async () => {
        const response = await verifyOtp(request);
        setShowTnc(false);
        if (response?.access_token) {
            localStorage.setItem(
                process.env.REACT_APP_LOCAL_STORAGE_AUTH,
                response.access_token,
            );
            navigate(routes.programs);
        }
    };

    const initPwa = async () => {
        if (program) {
            const response = await getPwaConfig(program);
            cacheTheme(response);
        }
    };

    useEffect(() => {
        initPwa();
    }, []);

    return (
        <>
            <PageHeader
                className='p-0 pb-20'
                subtitle={`Enter OTP sent to your mobile number ${mobile} to login`}>
                OTP Sent
            </PageHeader>
            {showTnc && (
                <KycTnc
                    program={program}
                    onClick={handleSubmit}
                    onClose={() => setShowTnc(false)}
                />
            )}
            <form onSubmit={handleSubmitClick}>
                <FormView
                    config={{
                        title: 'OTP Sent',
                        btn: 'Login',
                        items: [
                            {
                                name: 'mobile',
                                type: 'hidden',
                            },
                            {
                                name: 'program_id',
                                type: 'hidden',
                            },
                            {
                                name: 'bypass',
                                type: 'hidden',
                                value: true,
                            },
                            { name: 'otp' },
                        ],
                    }}
                    updated={handleUpdated}
                    data={request}
                />
                <button className='btn'>Login</button>
            </form>
        </>
    );
};

export default VerifyLogin;
