import axios from 'axios';
import { useEffect, useState } from 'react';
import Tags from '../atoms/Tags';
import { getValue } from '../../helpers/json';

const SmartLabel = (props) => {
    const { url, className, type, config } = props;
    const [details, setDetails] = useState({});
    const { heading, desc, tag, raw } = config;

    const getDetails = async () => {
        const { data } = await axios.get(url);
        setDetails(data);
    };

    useEffect(() => {
        if (url) {
            getDetails();
        }
    }, [url, type]);

    return (
        <span className={className}>
            {details && (
                <div>
                    {raw && <>{getValue(details, raw)}</>}
                    {heading && (
                        <div className='text-gray-800 fs-6 fw-bold'>
                            {getValue(details, heading)}
                        </div>
                    )}
                    {desc && (
                        <span className='text-muted fw-semibold d-block fs-7 mb-2'>
                            {getValue(details, desc)}
                        </span>
                    )}
                    {tag && <Tags list={[getValue(details, tag)]} />}
                </div>
            )}
        </span>
    );
};

export default SmartLabel;
