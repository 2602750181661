import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/atoms/Loader';
import DetailView from '../../../components/organisms/DetailView';
import { date } from '../../../helpers/datetime';
import { amount } from '../../../helpers/str';
import {
    approveInvoice,
    deleteTopup,
    getTopup,
    listApprovals,
    rejectInvoice,
} from '../../../services/_disburse.service';
import { routes } from '../config';
import { alertSuccess, confirm, confirmText } from '../../../helpers/alert';
import DocumentPreview from '../../Storage/DocumentPreview';

const TopupDetails = () => {
    const { id } = useParams();
    const [topup, setTopup] = useState({});
    const [loading, setLoading] = useState(false);
    const [approvals, setApprovals] = useState([]);
    const navigate = useNavigate();

    const getData = async () => {
        setLoading(true);
        const response = await getTopup(id);
        setTopup(response);
        setLoading(false);
    };

    const getApprovals = async () => {
        const response = await listApprovals({
            tab: 'mine',
            topup_id: id,
            is_for_borrower: true,
            type: 'topup',
            status: 'pending',
            expand: 'topup',
            'no-pagination': true,
        });
        setApprovals(response);
    };

    const handleDelete = async () => {
        confirm(
            'Delete Topups',
            'Do you really want to delete this topup',
            async () => {
                setLoading(true);
                await deleteTopup(id);
                navigate(routes.topups + '/all');
                setLoading(false);
            },
        );
    };

    const handleApproval = async (id) => {
        confirm(
            'Approve Invoice?',
            'Do you really want to approve this invoice?',
            async () => {
                await approveInvoice(id);
                alertSuccess('The invoice has been approved successfully');
                getApprovals();
            },
        );
    };

    const handleRejection = (id) => {
        confirmText(
            'Reject Invoice?',
            'Do you really want to reject this invoice?',
            async (res) => {
                await rejectInvoice(id, res.value);
                alertSuccess('The invoice has been rejected');
                getApprovals();
            },
            {
                inputPlaceholder: 'Reason for rejection',
            },
        );
    };

    useEffect(() => {
        getData();
        getApprovals();
    }, [id]);

    return (
        <>
            {loading && <Loader loading={loading} />}
            {!loading && (
                <>
                    <div className='container flex justify-between align-center'>
                        <div>
                            <h1>{amount(topup?.disbursal_amount)}</h1>
                            Invoice No: {topup?.invoice_no}
                            <span className='mx-5'>&#11825;</span>
                            <span>{date(topup?.invoice_date)}</span>
                        </div>
                        {topup?.invoice_storage_id && (
                            <DocumentPreview
                                documents={[
                                    { storage_id: topup?.invoice_storage_id },
                                ]}
                                config={{
                                    icon: 'ri-bill-line',
                                    title: 'Uploaded Invoice',
                                }}
                            />
                        )}
                    </div>
                    <div className='container pt-0'>
                        <DetailView
                            className='mt-30'
                            details={topup}
                            config={{
                                items: [
                                    { name: 'ref_id', label: 'Topup ID' },
                                    { name: 'status', transform: 'beautify' },
                                    {
                                        name: 'invoice_amount',
                                        transform: 'amount',
                                    },
                                    {
                                        name: 'disbursal_amount',
                                        transform: 'amount',
                                    },
                                ],
                            }}
                        />
                        {approvals.map((approval) => {
                            return (
                                <>
                                    <div className='btn-group mb-10'>
                                        <button
                                            onClick={() =>
                                                handleRejection(approval.id)
                                            }
                                            className='btn btn-sm btn-line'>
                                            Reject Invoice
                                        </button>
                                        <button
                                            onClick={() =>
                                                handleApproval(approval.id)
                                            }
                                            className='btn btn-sm bg-green'>
                                            Approve Invoice
                                        </button>
                                    </div>
                                </>
                            );
                        })}
                        {['pending', 'pending_approval'].indexOf(
                            topup.status,
                        ) !== -1 && (
                            <div
                                role='button'
                                onClick={handleDelete}
                                className='color-red text-center'>
                                Delete Invoice
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default TopupDetails;
