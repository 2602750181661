import React, { useEffect, useState } from 'react';
import { alertSuccess, confirm, confirmText } from '../../../helpers/alert';
import { date } from '../../../helpers/datetime';
import { amount } from '../../../helpers/str';
import {
    approveInvoice,
    listApprovals,
    rejectInvoice,
} from '../../../services/_disburse.service';

const TopupApprovals = () => {
    // const { user } = useUser();
    const [list, setList] = useState([]);

    const getList = async () => {
        const response = await listApprovals({
            tab: 'mine',
            type: 'topup',
            status: 'pending',
            expand: 'topup',
            'no-pagination': true,
        });
        setList(response);
    };

    const handleApproval = async (id) => {
        confirm(
            'Approve Invoice?',
            'Do you really want to approve this invoice?',
            async () => {
                await approveInvoice(id);
                alertSuccess('The invoice has been approved successfully');
                getList();
            },
        );
    };

    const handleRejection = (id) => {
        confirmText(
            'Reject Invoice?',
            'Do you really want to reject this invoice?',
            async (res) => {
                await rejectInvoice(id, res.value);
                alertSuccess('The invoice has been rejected');
                getList();
            },
            {
                inputPlaceholder: 'Reason for rejection',
            },
        );
    };

    useEffect(() => {
        getList();
    }, []);

    return (
        <>
            {list?.length > 0 && (
                <h4 className='mt-30 mb-10'>Invoices Pending for Approval</h4>
            )}
            {list?.map((row) => {
                return (
                    <div
                        className='card mt-10'
                        key={row.id}>
                        <div className='card__body p-20'>
                            <h2>{amount(row?.topup?.disbursal_amount)}</h2>
                            Invoice#: {row?.topup?.invoice_no}
                            <span className='mx-5'>&#11825;</span>
                            <span
                                className={[
                                    row.class ? `color-${row.class}` : '',
                                ].join(' ')}>
                                {date(row?.topup?.invoice_date)}
                            </span>
                            {row.is_for_borrower && (
                                <div className='btn-group'>
                                    <button
                                        onClick={() => handleRejection(row.id)}
                                        className='btn btn-sm btn-line'>
                                        Reject Invoice
                                    </button>
                                    <button
                                        onClick={() => handleApproval(row.id)}
                                        className='btn btn-sm bg-green'>
                                        Approve Invoice
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default TopupApprovals;
