import axios from 'axios';
import { buildUrl } from '../helpers/str';

export const BASE_URL =
    (process.env.REACT_APP_API_ROOT_ACCOUNTING ||
        process.env.REACT_APP_API_ROOT_DEFAULT) + '/lms/';

export const urls = {
    account_soa: BASE_URL + 'accounts/:id/soa/',

    loans: BASE_URL + 'loans/',
    loan_details: BASE_URL + 'loans/:id/',
    loan_summary: BASE_URL + 'loans/:id/summary/',

    records: BASE_URL + 'records/',
    records_initiate_pg: BASE_URL + 'records/initiate_pg/',
};

export const listLoans = async (params = null) => {
    const { data } = await axios.get(urls.loans, {
        params: { ...params },
    });
    return data;
};

export const getLoan = async (id) => {
    const { data } = await axios.get(
        buildUrl(urls.loan_details, { id, expand: 'topup' }),
    );
    return data;
};

export const getLoanSummary = async (id) => {
    const { data } = await axios.get(buildUrl(urls.loan_summary, { id }));
    return data;
};

export const initiatePayment = async (payload) => {
    const { data } = await axios.post(
        buildUrl(urls.records_initiate_pg),
        payload,
    );
    return data;
};

export const downloadSoa = async (id) => {
    const { data } = await axios.request({
        url: buildUrl(urls.account_soa, { id }),
        method: 'GET',
        responseType: 'arraybuffer',
    });
    return data;
};
