import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PopSheet from '../../components/molecules/PopSheet';
import { buildUrl } from '../../helpers/str';
import { api } from './config';

const DocumentPreview = (props) => {
    const {
        config,
        show,
        documents,
        onReupload,
        onDelete,
        onCtaClick,
        className,
        onClose,
    } = props;
    const [showDrawer, setShowDrawer] = useState(show || false);
    const [files, setFiles] = useState([]);
    useEffect(() => {}, [documents]);

    const handleShowDrawer = () => {
        getData();
        setShowDrawer(true);
    };

    const handleCloseDrawer = () => {
        setShowDrawer(false);
        if (onClose) {
            onClose();
        }
    };

    const handleCtaClick = () => {
        if (onCtaClick) {
            onCtaClick();
        }
        setShowDrawer(false);
    };

    const getData = async () => {
        const tmp = [];
        for (const document of documents) {
            let response = await axios.get(
                buildUrl(api.storage_documents_details, {
                    id: document.storage_id,
                }),
            );
            response = response.data.response_data;
            for (const element of response) {
                try {
                    const { data } = await axios.get(
                        buildUrl(api.storage_file, {
                            file_path: element.file_name,
                        }),
                    );
                    element.url = data.url;
                    element.doc_name = document.doc_name;
                    element.storage_id = document.id;
                    tmp.push(element);
                } catch (error) {}
            }
        }
        setFiles(tmp);
    };

    return (
        <>
            {config.icon ? (
                <span
                    className='btn btn-icon-lg btn-circle cursor-pointer'
                    onClick={handleShowDrawer}>
                    <i className={`ri ${config.icon}`}></i>
                </span>
            ) : (
                <>
                    <span
                        className={`cursor-pointer ${
                            className || 'color-link'
                        }`}
                        onClick={handleShowDrawer}>
                        <i className='fs-lg ri ri-eye-line mr-5'></i>
                    </span>
                    {config.reUpload && (
                        <>
                            <span
                                className={`ml-10 cursor-pointer ${
                                    className || 'color-link'
                                }`}
                                onClick={onReupload}>
                                <i className='fs-xl ri ri-upload-2-line'></i>
                            </span>
                        </>
                    )}
                    {config.delete && (
                        <>
                            <span
                                className={`ml-10 cursor-pointer ${
                                    className || 'color-red'
                                }`}
                                onClick={onDelete}>
                                <i className='fs-xl ri ri-delete-bin-line'></i>
                            </span>
                        </>
                    )}
                </>
            )}
            {showDrawer && (
                <PopSheet
                    closeOnClick={false}
                    title={config.title}
                    onClose={handleCloseDrawer}>
                    <div className='popsheet__body'>
                        {files?.map((item, index) => {
                            return (
                                <React.Fragment
                                    key={`document_preview_${index}`}>
                                    {item.mime_type.indexOf('image') !== -1 &&
                                        item.url && (
                                            <img
                                                src={item.url}
                                                alt={item.url}
                                                className='mw-100 mh-100'
                                            />
                                        )}
                                    {item.mime_type.indexOf('image') === -1 &&
                                        item.url && (
                                            <iframe
                                                style={{
                                                    width: '100%',
                                                    height:
                                                        window.innerHeight *
                                                        0.6,
                                                }}
                                                title={item.url}
                                                src={item.url}
                                            />
                                        )}
                                    {config.cta && (
                                        <button
                                            type='button'
                                            className='btn mt-5 btn-fixed'
                                            onClick={handleCtaClick}>
                                            {config.cta}
                                        </button>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </PopSheet>
            )}
        </>
    );
};

export default DocumentPreview;
