export const DICT = {
    en: {
        utr: 'UTR',
        ic: 'IC',
        subvention: 'Upfront Interest',
        otp: 'OTP',
        dob: 'Date of Birth',

        pan_no: 'PAN',
        pan: 'PAN',
        gstin: 'GST Number',
        ifsc: 'IFSC',
        co_applicant: 'Co-Applicant',

        gst: 'GST',
    },

    hi: {},
};
