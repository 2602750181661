import '@sweetalert2/theme-dark/dark.min.css';
import ReactGA from 'react-ga4';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import './assets/styles/index.scss';
import { useTheme } from './context/ThemeContext';
import { useUser } from './context/UserContext';
import { alertError } from './helpers/alert';
import { fixId, isJson } from './helpers/json';
import { getQueryParams } from './helpers/str';
import { transform } from './helpers/transform';
import { getServiceAccounts } from './services/_crm.service';
import { getProgramConfig } from './services/_datum.service';
import { ping } from './services/_identity.service';
import { getPwaConfig } from './services/_unr.service';

const authKey = process.env.REACT_APP_LOCAL_STORAGE_AUTH;
const authType = localStorage.getItem(
    process.env.REACT_APP_LOCAL_STORAGE_AUTH + '-type',
);

const useConstructor = (callBack = () => {}) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
};

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(authKey);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers['ngrok-skip-browser-warning'] = true;
        }
        return config;
    },
    (error) => {
        const { response } = error;
        console.log(error, response);
        if (response.status === 408) {
            alertError('Request time out, please try again');
        } else if (response.status === 413) {
            alertError(
                'The uploaded file was too large, please uploade a smaller file and try again',
            );
        }
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    (response) => {
        if (response.data) {
            response.data = fixId(response.data);
        }

        if (Array.isArray(response.data)) {
            response.data.forEach((row) => {
                if (row?._id) {
                    row.id = row._id;
                }
            });
        } else if (response?.data?._id) {
            response.data.id = response.data._id;
        } else if (response?.data?.results) {
            response.data.results.forEach((row) => {
                if (row?._id) {
                    row.id = row._id;
                }
            });
        }
        return response;
    },
    (error) => {
        const safeKeys = ['missing_fields'];
        let message = 'An error occurred, please try again later.';
        let title = 'Error';
        const { response } = error;
        let { queryParams } = getQueryParams(error.config.url);
        queryParams = { ...queryParams, ...error.config.params };

        if (error && response?.data && isJson(response.data)) {
            let jsonData = response.data;

            if (response.data instanceof ArrayBuffer) {
                const responseData = new TextDecoder().decode(response.data);
                jsonData = JSON.parse(responseData);
            }

            if (jsonData?.description) {
                title = jsonData.message || null;
                if (Array.isArray(jsonData.description)) {
                    message = {};
                    jsonData.description.forEach((desc) => {
                        safeKeys.forEach((key) => {
                            if (response.data.description[key]) {
                                message = {
                                    ...message,
                                    [key]: response.data.description[key].join(
                                        ', ',
                                    ),
                                };
                            }
                        });
                    });
                } else {
                    safeKeys.forEach((key) => {
                        if (jsonData.description[key]) {
                            message = {
                                ...message,
                                [key]: jsonData.description[key].join(', '),
                            };
                        }
                    });
                }
            } else if (jsonData.errors) {
                title = jsonData.message;
                message = jsonData.errors;
            } else if (jsonData?.message) {
                message = jsonData.message;
            } else if (jsonData) {
                const keys = Object.keys(jsonData);
                if (Array.isArray(jsonData[keys[0]])) {
                    message =
                        transform('beautify', keys[0]) +
                        ': ' +
                        jsonData[keys[0]][0];
                } else {
                    message =
                        transform('beautify', keys[0]) +
                        ': ' +
                        jsonData[keys[0]];
                }
            }
            if (!queryParams.silent) {
                if (response.config.method.toLowerCase() === 'get') {
                    alertError(title + ': ' + message);
                } else {
                    alertError(message, title);
                }
            }
            if (response.status === 408) {
                alertError('Request time out, please try again');
            } else if (response.status === 413) {
                alertError(
                    'The uploaded file was too large, please uploade a smaller file and try again',
                );
            } else if (response.status === 401 || response.status === 403) {
                localStorage.removeItem(authKey);
                localStorage.removeItem(
                    process.env.REACT_APP_LOCAL_STORAGE_AUTH + '-type',
                );
                window.location.href = '/auth';
            }
        }
        return Promise.reject(error);
    },
);

function App() {
    const { initType, loginUser } = useUser();
    const { mode, cacheTheme } = useTheme();
    const [pinged, setPinged] = useState(false);
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

    useEffect(() => {
        const ga_response = ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname,
        });
    }, [window.location.pathname]);

    const pingUser = async (token) => {
        const response = await ping(authType);

        const tmp = {};

        if (response.lead_id && response.program_id) {
            const serviceAccounts = await getServiceAccounts(
                response.lead_id,
                response.program_id,
            );
            Object.entries(serviceAccounts).forEach(([key, value]) => {
                if (value?.length) {
                    tmp[key] = value[0];
                }
            });
            response.service_accounts = tmp;
        }

        if (response.program_id) {
            response.program_config = await getProgramConfig(
                response.program_id,
            );
            const pwa_settings = await getPwaConfig(response.program_id);
            cacheTheme(pwa_settings);
        }

        loginUser(response);
        initType(authType);
        setPinged(true);
    };

    useConstructor(async () => {
        const token = localStorage.getItem(authKey);
        if (token) {
            await pingUser();
        } else {
            setPinged(true);
        }
    });

    return (
        <div className={`App ${mode}`}>
            <BrowserRouter>{pinged && <AppRoutes />}</BrowserRouter>
        </div>
    );
}

export default App;
