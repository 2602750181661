import React, { useState } from 'react';
import Icon from '../atoms/Icon';
import Form from '../organisms/Form';
import PopSheet from './PopSheet';
import { animated, useSpring } from 'react-spring';

const AddForm = ({ config, onSubmit }) => {
    const [showForm, setShowForm] = useState(false);

    const sheetAnimation = useSpring({
        bottom: showForm ? '0%' : '-100%',
        config: { tension: 300, friction: 30 },
    });

    const handleSubmit = (response) => {
        setShowForm(false);
        if (onSubmit) {
            onSubmit(response);
        }
    };

    return (
        <>
            <span onClick={() => setShowForm(true)}>
                <Icon name='add-circle-line' />
            </span>
            {showForm && (
                <animated.div style={sheetAnimation}>
                    <PopSheet
                        closeOnClick={false}
                        title={config.title}
                        onClose={() => setShowForm(false)}>
                        <Form
                            onSubmit={handleSubmit}
                            config={config}
                        />
                    </PopSheet>
                </animated.div>
            )}
        </>
    );
};

export default AddForm;
