import React from 'react';

const LeadActivity = () => {
    const activities = [
        {
            id: 1,
            icon: 'ri-add-large-line',
            iconClass: 'green',
            task: 'created',
            desc: 'Lead created',
            payload: { primary_mobile: '9920828104' },
            created_by: 'Rahul',
        },
        {
            id: 2,
            icon: 'ri-building-line',
            iconClass: 'yellow-dark',
            task: 'updated',
            desc: 'GST Number updated',
            payload: { gst_no: '06AAICK4577H1Z8' },
            created_by: 'Rahul',
        },
        {
            id: 3,
            icon: 'ri-exchange-2-line',
            iconClass: 'blue',
            task: 'updated',
            desc: 'Business Details updated automatically from GST Data',
            payload: {
                name: 'Kiranovations Technology Private Limited',
                business_type: 'private_limited',
                date_of_incorporation: '2020-11-25',
            },
        },
        {
            id: 4,
            icon: 'ri-user-line',
            iconClass: 'green',
            task: 'created',
            desc: 'Authorized Singnatory added from GST',
            payload: { name: ['Daman Singh Kohli'] },
            created_by: 'Rahul',
        },
        {
            id: 5,
            icon: 'ri-upload-cloud-2-line',
            iconClass: 'green',
            task: 'uploaded',
            desc: 'PAN Card photo uploaded',
            payload: { file: 'pan.png' },
            created_by: 'Daman',
        },
        {
            id: 6,
            icon: 'ri-exchange-2-line',
            iconClass: 'blue',
            task: 'updated',
            desc: 'Authorized Signatory Details updated automatically from PAN Data',
            payload: {
                father_name: 'PRABHJOT SINGH KOHLI',
            },
        },
        {
            id: 7,
            icon: 'ri-exchange-2-line',
            iconClass: 'blue',
            task: 'uploaded',
            desc: 'Aadhaar photo uploaded',
            payload: { file: 'aadhaar.png' },
            created_by: 'Daman',
        },
        {
            id: 8,
            icon: 'ri-exchange-2-line',
            iconClass: 'blue',
            task: 'updated',
            desc: 'Authorized Signatory Details updated automatically from Aadhaar Data',
            payload: {
                gender: 'male',
            },
        },
    ];
    return (
        <div className='card'>
            <div className='card-body p-15'>
                <div className='timeline timeline-border-dashed'>
                    {activities.map((activity) => {
                        return (
                            <div
                                className='timeline-item'
                                key={activity.id}>
                                <div className='timeline-line'></div>
                                <div
                                    className={`timeline-icon bg-${activity.iconClass}`}>
                                    <i className={activity.icon}></i>
                                </div>
                                <div className='timeline-content'>
                                    <div className='pr-2 mb-3'>
                                        <div className='fs-sm'>
                                            {activity.desc}
                                        </div>
                                        <small className='flex align-center'>
                                            <div className='color-secondary mr-2'>
                                                at 9th Jun 2024, 22:00
                                                {activity.created_by && (
                                                    <>
                                                        {' '}
                                                        by {activity.created_by}
                                                    </>
                                                )}
                                            </div>
                                        </small>
                                    </div>
                                </div>
                                <i className='ri-article-line'></i>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default LeadActivity;
