export const root = '/onboarding';
export const BASE_URL = process.env.REACT_APP_API_ROOT_DEFAULT + root + '/';

export const routes = {
    home: root,
    gst: root + '/gst',
    search_gst: root + '/search-gst',
    pan: root + '/pan',
    business: root + '/business',
    documents: root + '/documents',
    person: root + '/person',
    partners: root + '/partners',
    assets: root + '/assets',
    bank: root + '/bank',
    validate: root + '/validate',
};
