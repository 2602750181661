import axios from 'axios';
import { useEffect, useState } from 'react';
import FormView from '.';
import KycTnc from '../../../apps/Kyc/KycTnc';
import { useTheme } from '../../../context/ThemeContext';
import { alertError } from '../../../helpers/alert';
import { buildPayload, flattenForm } from '../../../helpers/form';
import { getValue } from '../../../helpers/json';
import { beautify, buildUrl, plural } from '../../../helpers/str';
import Loader from '../../atoms/Loader';
import LoaderAnimated from '../../atoms/LoaderAnimated';
import { validatePincode } from '../../../helpers/validate';

const FormWrapper = ({
    type = 'div',
    is_editable = true,
    config,
    data,
    updated,
    submitted,
    onError,
    verified,
    className,
    constants,
    onCancel,
}) => {
    const [request, setRequest] = useState({});
    const [extraFields, setExtraFields] = useState([]);
    const [consent, setConsent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showTnc, setShowTnc] = useState(false);
    const { theme } = useTheme();

    const handleUpdated = (response) => {
        setRequest(response);
        if (updated) {
            updated(response);
        }
    };

    const validatePresets = (payload) => {
        let response = true;
        if (payload?.pincode) {
            response = validatePincode(payload?.pincode);
        }
        return response;
    };

    const validateRequired = (items, payload) => {
        let response = true;
        const required = [];
        items.forEach((item) => {
            if (
                response &&
                item.is_required &&
                !getValue(payload, item.as || item.name)
            ) {
                required.push(item.label || beautify(item.name));
                response = false;
            }
        });
        if (required.length) {
            alertError(
                `${required.join(', ')} ${plural(
                    'is',
                    required.length,
                )} required`,
            );
        }
        return response;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (config?.consent && !consent) {
            return alertError(
                'Please read & accept the terms & conditions before continuing .',
            );
        }

        const payload = buildPayload(config.items, request);

        const isValid = validatePresets(payload);
        const isComplete = validateRequired(config.items, payload);

        if (!isValid || !isComplete) {
            return false;
        }

        if (config.silent) {
            payload.silent = true;
        }
        const queryParams =
            config.method && config.method.toLowerCase() === 'get'
                ? payload
                : config.silent
                ? { silent: true }
                : {};

        if (config.url) {
            const url = buildUrl(config.url, data, {
                ignoreExtra: true,
            });
            setLoading(true);
            await axios
                .request({
                    url,
                    data: payload,
                    method: config.method || 'post',
                    params: queryParams,
                    responseType: config.responseType || 'json',
                })
                .then((response) => {
                    setRequest({});
                    if (submitted) {
                        submitted(response?.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                })
                .catch((error) => {
                    if (onError) {
                        return onError(error?.response?.data);
                    }
                    return Promise.reject(error);
                });
        } else if (submitted) {
            submitted(payload);
        }
    };

    const initRequest = () => {
        if (data) {
            delete data.status;
        }
        const tmp = flattenForm(config.items, data);
        setRequest(tmp);

        if (config.dataFill) {
            const tmpExtraFields = [];
            Object.entries(config.dataFill).forEach(([k, v], index) => {
                tmpExtraFields.push({
                    type: 'hidden',
                    name: k,
                    value: getValue(data, v),
                });
            });
            setExtraFields(tmpExtraFields);
        }
    };

    useEffect(() => {
        initRequest();
    }, [config, data, constants]);

    const formTemplate = () => {
        return (
            <div className={className}>
                {config?.loader?.animated && (
                    <LoaderAnimated
                        name={config?.loader?.name}
                        loop={config?.loader?.loop}
                        loading={loading}
                        title='Verifying your information from government database'
                    />
                )}
                {!config?.loader?.animated && <Loader loading={loading} />}
                <FormView
                    is_editable={is_editable}
                    constants={constants}
                    config={config}
                    data={data}
                    updated={handleUpdated}
                    submitted={submitted}
                    verified={verified}
                />
                {config?.consent && (
                    <div className='flex'>
                        <input
                            type='checkbox'
                            name='consent'
                            value={consent}
                            onChange={(e) => {
                                setConsent(e.target.checked);
                            }}
                        />
                        <div>
                            I've read & accept the{' '}
                            <span
                                onClick={() => {
                                    setShowTnc(true);
                                }}
                                className='cursor-pointer color-red'>
                                terms & condition
                            </span>{' '}
                            set by{' '}
                            <span className='color-yellow'>
                                {theme?.company?.registered_name ||
                                    theme?.company?.name}
                            </span>
                        </div>
                    </div>
                )}
                {showTnc && (
                    <KycTnc
                        onClick={handleSubmit}
                        onClose={() => setShowTnc(false)}
                    />
                )}
                {is_editable && !config.disableButton && (
                    <div className='btn-group'>
                        {config.cancel && (
                            <button
                                type='button'
                                onClick={onCancel}
                                className='btn btn-line'
                                disabled={loading}>
                                {config.cancel || 'Cancel'}
                            </button>
                        )}
                        <button
                            onClick={handleSubmit}
                            className='btn'
                            disabled={loading}>
                            {config.btn || 'Save'}
                        </button>
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            {type === 'form' ? (
                <form onSubmit={handleSubmit}>{formTemplate()}</form>
            ) : (
                <>{formTemplate()}</>
            )}
        </>
    );
};

export default FormWrapper;
