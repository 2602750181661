import axios from 'axios';
import { filterArray } from '../helpers/json';
import { transform } from '../helpers/transform';

export const BASE_URL =
    (process.env.REACT_APP_API_ROOT_DATUM ||
        process.env.REACT_APP_API_ROOT_DEFAULT) + '/datum/constants/';

export const urls = {
    constants: BASE_URL,
    constants_types: BASE_URL + 'types/',
    constant_details: BASE_URL + ':id/',
};

export const listConstants = async (params = null) => {
    const { data } = await axios.get(urls.constants, {
        params: { silent: true, ...params },
    });
    return data;
};

export const getConstants = (consts, type) => {
    if (!type) {
        return [];
    }
    const chunks = type.split('.');
    if (chunks.length > 1) {
        consts = filterArray(consts, 'type', chunks.shift());
        consts = filterArray(consts, 'name', chunks.shift());
        return transform('options', JSON.parse(consts[0].value));
    }
    return transform('options', filterArray(consts, 'type', chunks[0]));
};
