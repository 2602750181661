import React, { useEffect, useState } from 'react';
import { beautify, buildUrl } from '../../../helpers/str';
import {
    listDocuments,
    uploadDocument,
    urls,
} from '../../../services/_crm.service';
import DocumentPreview from '../../Storage/DocumentPreview';
import { searchArray } from '../../../helpers/json';
import PopSheet from '../../../components/molecules/PopSheet';
import FormWrapper from '../../../components/organisms/FormView/FormWrapper';

const KycFormDocuments = ({
    applicant_id,
    setPhoto,
    setSignature,
    onValidate,
}) => {
    const ignoreDocs = [
        'e-kyc authentication',
        'e_kyc_authentication',
        'photograph',
        'signature',
    ];
    const [edit, setEdit] = useState({});
    const [docsSchema, setDocsSchema] = useState([]);

    const onFileChange = async (e, index) => {
        const tmp = [...docsSchema];
        const response = await uploadDocument(e.target.files[0], {
            related_model_name: 'applicant',
            related_model_id: applicant_id,
            doc_name: tmp[index].doc_name,
        });
        tmp[index] = response;
        const panDoc = searchArray(tmp, 'doc_name', 'pan');
        if (panDoc?.storage_id) {
            onValidate(true);
        } else {
            onValidate(false);
        }
        setDocsSchema(tmp);
    };

    const initDocs = async () => {
        const tmp = [];
        let pan = 0;
        let aadhaar_front = 0;
        let aadhaar_back = 0;
        const response = await listDocuments(applicant_id);
        response.forEach((doc) => {
            if (doc.storage_id && doc.doc_name === 'signature') {
                setSignature(doc.storage_id);
            }
            if (doc.storage_id && doc.doc_name === 'photograph') {
                setPhoto(doc.storage_id);
            }
            if (ignoreDocs.indexOf(doc.doc_name) !== -1) {
                return;
            }
            const doc_name = doc.doc_name;
            if (doc_name === 'pan') {
                pan += 1;
            }
            if (doc_name === 'aadhaar_front') {
                aadhaar_front += 1;
            }
            if (doc_name === 'aadhaar_back') {
                aadhaar_back += 1;
            }
            tmp.push({ ...doc, doc_name });
        });
        if (!pan) {
            tmp.push({ doc_name: 'pan' });
            onValidate(false);
        } else {
            onValidate(true);
        }
        if (!aadhaar_front) {
            tmp.push({ doc_name: 'aadhaar_front' });
        }
        if (!aadhaar_back) {
            tmp.push({ doc_name: 'aadhaar_back' });
        }
        setDocsSchema(tmp);
    };

    useEffect(() => {
        if (applicant_id) {
            initDocs();
        }
    }, [applicant_id]);
    return (
        <>
            {docsSchema.map((doc, index) => {
                return (
                    <div
                        className='uploader'
                        key={`doc.doc_name_${index}`}>
                        {doc.back && (
                            <div className='uploader__label'>
                                {beautify(doc.doc_name)}
                            </div>
                        )}
                        <label className={`uploader__field ${doc.doc_name}`}>
                            {doc.storage_id && (
                                <DocumentPreview
                                    onReupload={() => setEdit(doc)}
                                    documents={[doc]}
                                    config={{
                                        reUpload: true,
                                        delete: true,
                                        label:
                                            'View ' +
                                            (doc.label ||
                                                beautify(doc.doc_name)),
                                        title:
                                            (doc.label ||
                                                beautify(doc.doc_name)) +
                                            ' Documents',
                                        position: 'start',
                                    }}
                                />
                            )}
                            {!doc.storage_id && (
                                <>
                                    <input
                                        className='uploader__field__file'
                                        onChange={(e) => onFileChange(e, index)}
                                        name={`${doc.doc_name}_file`}
                                        type='file'
                                    />
                                    Upload {doc.label || beautify(doc.doc_name)}
                                </>
                            )}
                            {doc.storage_id && (
                                <span className='uploader__field-uploaded'></span>
                            )}
                        </label>
                    </div>
                );
            })}
            {edit?.id && (
                <PopSheet
                    closeOnClick={false}
                    title='Edit Document'
                    onClose={() => setEdit({})}>
                    <FormWrapper
                        data={edit}
                        config={{
                            method: 'PATCH',
                            url: buildUrl(urls.document_details, {
                                id: edit.id,
                            }),
                            items: [
                                {
                                    type: 'file',
                                    name: 'files',
                                    class: 'col-md-12',
                                },
                            ],
                        }}
                        submitted={() => {
                            setEdit({});
                            initDocs();
                        }}
                    />
                </PopSheet>
            )}
        </>
    );
};

export default KycFormDocuments;
