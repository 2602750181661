import React, { useState } from 'react';
import FormWrapper from '../../../components/organisms/FormView/FormWrapper';
import { urls } from '../../../services/_crm.service';

const KycSummaryAddress = ({ address, applicant_id }) => {
    const [addAddress, setAddAddress] = useState(false);
    const handleSubmit = () => {};
    return (
        <>
            {(address?.address || addAddress) && (
                <div className='card'>
                    <div className='card__header'>
                        Business Address
                        {address?.address && <>(as per GST)</>}
                    </div>
                    <div className='card__body p-15'>
                        {!addAddress && address?.address && (
                            <>
                                <div>{address?.address}</div>
                                <button
                                    className='btn-link mt-10'
                                    onClick={() => setAddAddress(true)}>
                                    Use a Different Address
                                </button>
                            </>
                        )}
                        {addAddress && (
                            <FormWrapper
                                data={urls.addresses}
                                config={{
                                    url: urls.addresses,
                                    items: [
                                        {
                                            type: 'hidden',
                                            name: 'applicant_id',
                                            value: applicant_id,
                                        },
                                        { type: 'text', name: 'address_line' },
                                        {
                                            type: 'text',
                                            name: 'pincode',
                                            class: 'col-md-12',
                                            lookup: {
                                                type: 'pincode',
                                                url: urls.lookup_pincode,
                                                fields: {
                                                    city: 'city',
                                                    state: 'state',
                                                },
                                            },
                                        },
                                        {
                                            type: 'hidden',
                                            name: 'tags',
                                            value: ['current'],
                                        },
                                        { type: 'text', name: 'city' },
                                        { type: 'text', name: 'state' },
                                    ],
                                }}
                                submitted={handleSubmit}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default KycSummaryAddress;
