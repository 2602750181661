import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { transform } from '../../../helpers/transform';
import { beautify } from '../../../helpers/str';

const FormControlDatepicker = (props) => {
    const { item, onChange, value } = props;
    const [startDate, setStartDate] = useState('');

    const handleChange = (date) => {
        setStartDate(date);
        onChange(
            item.name,
            item.epoch
                ? isNaN(moment(date).unix())
                    ? ''
                    : moment(date).unix()
                : transform('date', date, 'YYYY-MM-DD'),
        );
    };

    useEffect(() => {
        if (value) {
            const date = new Date(
                item.epoch ? moment.unix(value) : moment(value),
            );
            setStartDate(date);
        }
    }, [item, value]);

    return (
        <div className='form__item'>
            <DatePicker
                placeholderText={
                    item.placeholder || item.label || beautify(item.name)
                }
                dateFormat='yyyy-MM-dd'
                className='control'
                selected={startDate}
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                onChange={handleChange}
            />
        </div>
    );
};

export default FormControlDatepicker;
