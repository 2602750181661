import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Loader from '../../../components/atoms/Loader';
import LoaderAnimated from '../../../components/atoms/LoaderAnimated';
import PageHeader from '../../../components/molecules/PageHeader';
import { useUser } from '../../../context/UserContext';
import { alertSuccess, confirm } from '../../../helpers/alert';
import { dateTimeFromEpoch } from '../../../helpers/datetime';
import { searchArray, sortArray } from '../../../helpers/json';
import { beautify } from '../../../helpers/str';
import { getEsign } from '../../../services/_esign.service';
import {
    generateAgreement,
    generateSign,
    getApplication,
    listAgreements,
    refreshSignStatus,
    urls,
} from '../../../services/_los.service';
import DocumentPreview from '../../Storage/DocumentPreview';
import SignedAgreement from './SignedAgreement';

const Agreements = () => {
    const [intervalStarted, setIntervalStarted] = useState(false);
    const { user } = useUser();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [application, setApplication] = useState({});
    const [agreements, setAgreements] = useState([]);
    const [esign, setEsign] = useState({});
    const account_id = user?.service_accounts?.los?.id;

    const order = [
        {
            type: 'agreement',
            name: 'Loan Agreement',
            url: urls.agreement_generate,
        },
        { type: 'stamp', name: 'Stamp Paper', url: urls.stamp_generate },
        {
            type: 'signed_agreement',
            name: 'Signed Agreement',
            url: urls.signs_initiate,
        },
    ];

    const handleGenerate = async () => {
        confirm(
            'Are you sure?',
            'Do you really want to generate agreement for signing?',
            async () => {
                setLoading(true);
                await generateAgreement(account_id);
                initAgreements();
                setLoading(false);
            },
        );
    };

    const handleEsign = async () => {
        setLoading(true);
        await generateSign(account_id, 'Signed Agreement');
        initAgreements();
        setLoading(false);
    };

    const handleSignRefresh = async (request, showAlert = true) => {
        const id = request?.id || esign?.id;
        const status = request?.status || esign?.status;
        if (id && status !== 'success') {
            const response = await refreshSignStatus(id);
            if (response) {
                initAgreements();
                if (showAlert) {
                    alertSuccess('eSign status has been refreshed succesfully');
                }
            }
        }
    };

    const initApplication = async () => {
        const response = await getApplication(user?.service_accounts?.los?.id);
        setApplication(response);
    };

    const initAgreements = async () => {
        if (user?.service_accounts?.los?.id) {
            let response = await listAgreements(user.service_accounts.los.id);
            for (let i = 0; i < response.length; i++) {
                const agreement = response[i];
                try {
                    if (agreement.signing_service_id) {
                        agreement.esign = await getEsign(
                            agreement.signing_service_id,
                            // true,
                        );
                    }
                } catch (error) {}
                if (agreement.type === 'signed_agreement') {
                    setEsign(agreement);
                    if (searchParams.get('documentId')) {
                        handleSignRefresh();
                        searchParams.delete('documentId');
                        setSearchParams(searchParams);
                    }
                }
                response[i] = agreement;
            }
            if (response) {
                response = sortArray(response, 'attachement_order');
            }
            setAgreements(response);
            order.forEach((item, index) => {
                const doc = searchArray(response, 'type', item.type);
                if (doc) {
                    setStep(index + 1);
                }
            });
        }
    };

    useEffect(() => {
        initApplication();
        initAgreements();
    }, []);

    return (
        <div className='container'>
            <PageHeader className='p-0'>Agreement Signing</PageHeader>
            <div className='mt-20'>
                <Loader loading={loading} />
                {step > 4 && (
                    <LoaderAnimated
                        className='loader-static'
                        loading={true}
                        name={'success'}
                        title='Your agreement has been signed successfully'
                    />
                )}
                {agreements.map((agreement) => {
                    return (
                        <div
                            className='uploader'
                            key={agreement.id}>
                            <label
                                className={`uploader__field uploader__field-fluid`}>
                                {agreement.storage_id && (
                                    <div className='w-100'>
                                        {beautify(agreement.name)}{' '}
                                        <DocumentPreview
                                            documents={[agreement]}
                                            config={{
                                                label: beautify(agreement.name),
                                                title: beautify(agreement.name),
                                                items: [
                                                    {
                                                        type: 'created_at',
                                                        label: 'Generated At',
                                                    },
                                                    {
                                                        type: 'sign_status',
                                                        label: 'Status',
                                                    },
                                                    {
                                                        type: 'stamp_status',
                                                        label: 'Status',
                                                    },
                                                ],
                                                position: 'start',
                                            }}
                                        />
                                        {agreement.type === 'agreement' && (
                                            <>
                                                <div>
                                                    <small>
                                                        Generated At:{' '}
                                                        {dateTimeFromEpoch(
                                                            agreement.completed_at,
                                                        )}
                                                    </small>
                                                </div>
                                                <div>
                                                    <small className='color-green'>
                                                        Status: Generated
                                                    </small>
                                                </div>
                                            </>
                                        )}
                                        {agreement.type ===
                                            'signed_agreement' && (
                                            <SignedAgreement
                                                agreement={agreement}
                                                onRefresh={handleSignRefresh}
                                            />
                                        )}
                                        {agreement.type === 'stamp' && (
                                            <>
                                                <div>
                                                    <small>
                                                        Initiated At:{' '}
                                                        {dateTimeFromEpoch(
                                                            agreement.initiated_at,
                                                        )}
                                                    </small>
                                                </div>
                                                <div>
                                                    <small>
                                                        Completed At:{' '}
                                                        {dateTimeFromEpoch(
                                                            agreement.completed_at,
                                                        )}
                                                    </small>
                                                </div>
                                                <div>
                                                    <small
                                                        className={
                                                            (agreement.stamp_status ===
                                                                'success') ===
                                                            'success'
                                                                ? 'color-green'
                                                                : 'color-red'
                                                        }>
                                                        Status:{' '}
                                                        {beautify(
                                                            agreement.stamp_status ||
                                                                'pending',
                                                        )}
                                                    </small>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                            </label>
                        </div>
                    );
                })}
                {agreements.length <= 0 && (
                    <button
                        className='btn'
                        onClick={handleGenerate}>
                        Generate Agreement
                    </button>
                )}
                {agreements.length > 0 && !esign?.id && (
                    <button
                        className='btn'
                        onClick={handleEsign}>
                        eSign Agreement
                    </button>
                )}
            </div>
        </div>
    );
};

export default Agreements;
