import React, { useEffect, useState } from 'react';
import Loader from '../../components/atoms/Loader';
import FormControlFile from '../../components/molecules/FormControl/FormControlFile';
import PopSheet from '../../components/molecules/PopSheet';
import { useConstants } from '../../context/ConstantsContext';
import { alertError, confirm } from '../../helpers/alert';
import { arrayPluck, searchArray } from '../../helpers/json';
import { beautify } from '../../helpers/str';
import { getConstants } from '../../services/_constants.service';
import {
    deleteDocument,
    listDocuments,
    updateDocument,
    uploadDocument,
} from '../../services/_crm.service';
import DocumentPreview from '../Storage/DocumentPreview';

const OnboardingDocs = ({
    title,
    required,
    doc_type,
    applicant,
    onUpdated,
    onRefresh,
}) => {
    const consts = useConstants();
    const initialSchema = {
        related_model_name: 'applicant',
        related_model_id: applicant.id,
    };
    const [request, setRequest] = useState([]);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState({});
    const [docsSchema, setDocsSchema] = useState([]);

    const onFileChange = async (e, index) => {
        if (!request[index]?.name) {
            e.target.value = null;
            return alertError('Please select document type');
        }
        const tmp = [...docsSchema];
        setLoading(true);
        try {
            const response = await uploadDocument(e.target.files[0], {
                related_model_name: 'applicant',
                related_model_id: applicant.id,
                name: request[index]?.name,
            });
            tmp[index] = response;
            setDocsSchema(tmp);

            initUploadedDocs(tmp);
            if (onRefresh) {
                onRefresh();
            }
        } catch (error) {
            e.target.value = null;
        } finally {
            setLoading(false);
        }
    };

    const initUploadedDocs = (request) => {
        const uploadedDocNames = [];
        request.forEach((doc) => {
            if (doc.storage_id) {
                uploadedDocNames.push(doc.name);
            }
        });
        if (onUpdated) {
            onUpdated(uploadedDocNames);
        }
    };

    const handleFileSelect = async (response, file) => {
        console.log(response);
        setLoading(true);
        try {
            await updateDocument(edit.id, file[0]);
            setEdit({});
            initDocs();
            if (onRefresh) {
                onRefresh();
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const removeFile = (index) => {
        const tmp = [...docsSchema];
        const tmpRequest = [...request];

        tmp.splice(index, 1);
        tmpRequest.splice(index, 1);
        setDocsSchema(tmp);
        initUploadedDocs(tmp);
        setRequest(tmpRequest);
    };

    const initDocs = async () => {
        const tmpRequest = [...request];
        const tmp = [];
        const response = await listDocuments(applicant.id);
        const uploadedDocNames = arrayPluck(response, 'name');
        response.forEach((doc) => {
            tmp.push(doc);
        });
        if (onUpdated) {
            onUpdated(uploadedDocNames);
        }
        if (required) {
            required.forEach((field) => {
                if (uploadedDocNames.indexOf(field) === -1) {
                    const index =
                        tmp.push({
                            related_model_name: 'applicant',
                            related_model_id: applicant.id,
                            name: field,
                            disabled: true,
                        }) - 1;
                    if (!tmpRequest[index]) {
                        tmpRequest[index] = {};
                    }
                    tmpRequest[index].name = field;
                }
            });
        }
        if (tmp.length === 0) {
            tmp.push(initialSchema);
        }
        setDocsSchema(tmp);
        setRequest(tmpRequest);
    };

    const handleTypeChange = (e, index) => {
        e.preventDefault();
        const tmp = [...request];
        if (!tmp[index]) {
            tmp[index] = {};
        }
        tmp[index].name = e.target.value;
        setRequest(tmp);
    };

    const handleDelete = async (schema, index) => {
        confirm(
            'Delete Document?',
            'Do you really want to delete this document',
            async () => {
                const response = await deleteDocument(schema.id);
                if (response) {
                    const tmp = [...docsSchema];
                    tmp[index].storage_id = null;
                    setDocsSchema(tmp);
                    initUploadedDocs(tmp);
                }
            },
        );
    };

    useEffect(() => {
        if (applicant.id) {
            initDocs();
        }
    }, [applicant]);

    return (
        <div>
            <div className='flex justify-between align-center'>
                <h4>{title || 'Documents'}</h4>
                <h3
                    className='cursor-pointer'
                    onClick={() => {
                        setDocsSchema([...docsSchema, initialSchema]);
                        setRequest([...request, {}]);
                    }}>
                    <i className='ri-add-circle-line'></i>
                </h3>
            </div>
            <Loader loading={loading} />
            {docsSchema.map((schema, index) => {
                return (
                    <div
                        className='uploader'
                        key={index}>
                        <div
                            className={`uploader__field none`}
                            htmlFor={`uploader__field__file__${index}`}>
                            {schema.storage_id && (
                                <>
                                    <div className='uploader__field__key'>
                                        {beautify(schema.name)}
                                    </div>
                                    <div>
                                        <label
                                            htmlFor={`uploader__field__file__${index}`}>
                                            <DocumentPreview
                                                onReupload={() =>
                                                    setEdit(schema)
                                                }
                                                onDelete={() =>
                                                    handleDelete(schema, index)
                                                }
                                                documents={[schema]}
                                                config={{
                                                    reUpload: true,
                                                    delete: true,
                                                    label: 'View ',
                                                    title: beautify(
                                                        schema.name,
                                                    ),
                                                    position: 'start',
                                                }}
                                            />
                                        </label>
                                        <span className='uploader__field-uploaded'></span>
                                    </div>
                                </>
                            )}
                            {!schema.storage_id && (
                                <>
                                    <div>
                                        {required?.length > 0 &&
                                        required.indexOf(schema.name) !== -1 ? (
                                            <span className='ml-20'>
                                                {beautify(schema.name)}
                                            </span>
                                        ) : (
                                            <select
                                                name={`type_${index}`}
                                                className='select uploader__field__type'
                                                style={{ height: '50px' }}
                                                disabled={schema.disabled}
                                                value={request?.[index]?.name}
                                                onChange={(e) =>
                                                    handleTypeChange(e, index)
                                                }>
                                                <option value=''>
                                                    Select Type
                                                </option>
                                                {getConstants(
                                                    consts.constants,
                                                    doc_type,
                                                ).map((constant) => {
                                                    return (
                                                        <React.Fragment
                                                            key={
                                                                constant.value
                                                            }>
                                                            {!searchArray(
                                                                docsSchema,
                                                                'name',
                                                                constant.value,
                                                            ) && (
                                                                <option
                                                                    value={
                                                                        constant.value
                                                                    }>
                                                                    {
                                                                        constant.label
                                                                    }
                                                                </option>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </select>
                                        )}
                                    </div>
                                    <label
                                        htmlFor={`uploader__field__file__${index}`}>
                                        <input
                                            id={`uploader__field__file__${index}`}
                                            className='uploader__field__file'
                                            onChange={(e) =>
                                                onFileChange(e, index)
                                            }
                                            name={`file_${index}`}
                                            type='file'
                                        />
                                        <i className='ri-upload-cloud-line mr-5'></i>
                                        Upload
                                    </label>
                                    <i
                                        onClick={() => removeFile(index)}
                                        className='uploader__field__remove ri-close-circle-line mr-5'></i>
                                </>
                            )}
                        </div>
                    </div>
                );
            })}
            {edit?.id && (
                <PopSheet
                    closeOnClick={false}
                    title='Edit Document'
                    onClose={() => setEdit({})}>
                    <FormControlFile
                        item={{
                            name: 'file',
                        }}
                        onFileSelect={handleFileSelect}
                    />
                </PopSheet>
            )}
        </div>
    );
};

export default OnboardingDocs;
