import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/atoms/Loader';
import AccordionItem from '../../components/organisms/AccordionItem';
import FormWrapper from '../../components/organisms/FormView/FormWrapper';
import { useUser } from '../../context/UserContext';
import { getLead, urls } from '../../services/_crm.service';
import { routes } from './config';
import { MAPPINGS } from '../../consts/mapping';
import { beautify } from '../../helpers/str';

const OnboardingPartners = ({ lead, onNext, onRefresh }) => {
    const { user } = useUser();
    const [label, setLabel] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [add, setAdd] = useState(false);
    const [applicants, setApplicants] = useState([]);
    const [personConfig, setPersonConfig] = useState({});

    const handleSubmit = () => {
        if (activeIndex === applicants.length - 1) {
            onNext();
        } else {
            setActiveIndex(activeIndex + 1);
        }
    };

    const handleProceed = () => {
        onNext();
    };

    const handlePartnerAdded = () => {
        setActiveIndex(-1);
        setAdd(false);
        onRefresh();
    };

    const initLabel = () => {
        const business_type = lead?.business_entity?.business_type;
        const partnersLabels = MAPPINGS.crego.partners_label;
        if (Object.keys(partnersLabels).indexOf(business_type) !== -1) {
            setLabel(partnersLabels[business_type]);
        } else if (
            user?.program_config?.borrower?.co_applicants?.requirement === 'yes'
        ) {
            setLabel('co_applicant');
        }
    };

    useEffect(() => {
        initLead();
        initLabel();
    }, [lead]);

    const initLead = async () => {
        const tmp = [];
        lead?.applicants?.forEach((applicant) => {
            if (
                applicant.applicant_type === 'person' &&
                !applicant.is_authorized_signatory
            ) {
                tmp.push(applicant);
            }
        });
        setApplicants(tmp);
    };

    useEffect(() => {
        initLead();
    }, []);

    return (
        <div className='container'>
            <Loader loading={loading} />
            <div className='accordion my-10'>
                {applicants?.map((applicant, index) => {
                    return (
                        <AccordionItem
                            title={applicant.name}
                            index={index}
                            key={index}
                            activeIndex={activeIndex}>
                            <FormWrapper
                                data={applicant}
                                config={{
                                    url: urls.applicant_details,
                                    btn: 'Save Applicant',
                                    method: 'PATCH',
                                    items: [
                                        { name: 'pan_no' },
                                        {
                                            type: 'number',
                                            name: 'primary_mobile',
                                            value: applicant.is_authorized_signatory
                                                ? applicant?.primary_mobile
                                                : '',
                                        },
                                    ],
                                }}
                                submitted={handleSubmit}
                            />
                        </AccordionItem>
                    );
                })}
            </div>
            {!add && (
                <div
                    className='cursor-pointer card card-bordered text-center p-30'
                    onClick={() => {
                        setAdd(true);
                        setActiveIndex(-1);
                    }}>
                    <h5>
                        <i className='ri-add-line'></i>
                        Add {beautify(label)}
                    </h5>
                </div>
            )}
            {add && (
                <div className='card'>
                    <div className='card__header'>Add {beautify(label)}</div>
                    <div className='card__content'>
                        <FormWrapper
                            onCancel={() => {
                                setAdd(false);
                                setActiveIndex(applicants.length - 1);
                            }}
                            config={{
                                url: urls.applicants,
                                cancel: 'Cancel',
                                items: [
                                    {
                                        name: 'lead_id',
                                        type: 'hidden',
                                        value: user.lead_id,
                                    },
                                    {
                                        name: 'applicant_type',
                                        type: 'hidden',
                                        value: 'person',
                                    },
                                    { name: 'name' },
                                    {
                                        name: 'tags',
                                        type: 'hidden',
                                        value: [label],
                                    },
                                    { name: 'pan_no' },
                                    { type: 'number', name: 'primary_mobile' },
                                ],
                            }}
                            submitted={handlePartnerAdded}
                        />
                    </div>
                </div>
            )}
            <button
                type='button'
                className='btn btn-fixed'
                onClick={handleProceed}>
                Proceed
            </button>
        </div>
    );
};

export default OnboardingPartners;
