import React from 'react';
import Webcam from 'react-webcam';

const KycFormFace = () => {
    return (
        <div>
            <Webcam />
        </div>
    );
};

export default KycFormFace;
