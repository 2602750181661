export const searchArray = (array, key, value) => {
    let response = {};
    if (array) {
        array.forEach((item) => {
            if (getValue(item, key) === value) {
                response = item;
            }
        });
    }
    if (!Object.keys(response).length) {
        return false;
    }
    return response;
};

export const sortArray = (array, key) => {
    const response = [];
    if (array) {
        array.forEach((item) => {
            response.splice(item[key], 0, item);
        });
    }
    return response;
};

export const filterArray = (arr, key, value) => {
    if (!arr) {
        return arr;
    }
    return arr.filter(
        (item) =>
            item[key] &&
            value &&
            item[key].toLowerCase() === value.toLowerCase(),
    );
};

export const getValue = (data, key = '') => {
    try {
        if (!key) {
            return data;
        }
        if (Array.isArray(key)) {
            const values = [];
            key.forEach((key) => {
                values.push(key.split('.').reduce((o, i) => o[i], data));
            });
            return values.filter((n) => n).join(', ');
        } else {
            return key.split('.').reduce((o, i) => o[i], data);
        }
    } catch (error) {
        return '';
    }
};

export const flattenExpanded = (obj) => {
    Object.entries(obj).forEach(([k, v]) => {
        if (v && typeof v === 'object' && v.id) {
            obj[k] = v.id;
        }
    });
    return obj;
};

export const getArrayIndex = (array, key, value) => {
    let response = -1;
    array.forEach((item, index) => {
        if (item[key] === value) {
            response = index;
        }
    });
    return response;
};

export const updateObject = (obj, path, val) => {
    const keys = path.split('.');
    const lastKey = keys.pop();
    const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
    lastObj[lastKey] = val;
    return obj;
};

export const isJson = (str) => {
    if (typeof str === 'object') {
        return true;
    }
    try {
        return JSON.parse(str) && !!str;
    } catch (e) {
        return false;
    }
};

export const arrayPluck = (arr, keys) => {
    if (typeof keys === 'string') {
        keys = [keys];
    }
    const values = [];
    arr.forEach((row) => {
        keys.forEach((key) => {
            if (row[key]) {
                values.push(row[key]);
            }
        });
    });
    return values;
};

export const fixId = (data) => {
    if (Array.isArray(data)) {
        data.forEach((row, index) => {
            data[index] = fixId(row);
        });
    } else {
        if (data) {
            Object.entries(data).forEach(([key, value]) => {
                if (key === '_id') {
                    data.id = data._id;
                }
                if (value && typeof value === 'object') {
                    data[key] = fixId(value);
                }
            });
        }
    }
    return data;
};

export const objectify = (data, key = 'key') => {
    if (!data) {
        return {};
    }
    const payload = {};
    data.forEach((row) => {
        const tmp = {};
        Object.entries(row).forEach(([k, v]) => {
            if (k !== key) {
                tmp[k] = v;
            }
        });
        payload[row[key]] = tmp;
    });
    return payload;
};
