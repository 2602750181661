import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../components/molecules/PageHeader';
import FormWrapper from '../../../components/organisms/FormView/FormWrapper';
import { getLead, urls } from '../../../services/_crm.service';
import { useUser } from '../../../context/UserContext';

const ItrAnalyticsInit = () => {
    const { user } = useUser();
    const navigate = useNavigate();
    const [request, setRequest] = useState({});
    const [lead, setLead] = useState({});

    useEffect(() => {
        initLead();
    }, []);

    const initLead = async () => {
        if (user.lead_id) {
            const response = await getLead(user.lead_id);
            setLead(response);
        }
    };

    const handleSubmitted = (response) => {
        if (response?.data?.result?.request_id) {
            navigate(
                '/borrower/itr-analytics?request_id=' +
                    response?.data?.result?.request_id,
            );
        }
    };

    const handleUpdated = (data) => {
        setRequest(data);
    };

    return (
        <div
            className='container init'
            style={{ marginTop: -100 }}>
            <PageHeader
                className='p-0 pb-20'
                subtitle='Enter your ITR Portal Credentials to fetch your ITR'></PageHeader>
            <FormWrapper
                submitted={handleSubmitted}
                config={{
                    url: urls.itr_analytics_intiate,
                    btn: 'Pull ITR Data',
                    items: [
                        {
                            name: 'username',
                            label: 'Username (Business PAN)',
                            value: lead?.business_entity?.pan_no,
                            disabled: lead?.business_entity?.pan_no,
                        },
                        { name: 'password' },
                    ],
                }}
                updated={handleUpdated}
                data={request}
            />
        </div>
    );
};
export default ItrAnalyticsInit;
