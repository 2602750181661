export const root = '/identity/auth';
export const venusRoot = '/auth';
export const BASE_URL = process.env.REACT_APP_API_ROOT_DEFAULT + root + '/';

export const api = {
    ping: BASE_URL + 'ping/',
    login: BASE_URL + 'token/',
};

export const routes = {
    login: venusRoot + '/',
    otp: venusRoot + '/otp',
    programs: venusRoot + '/programs',
};
