import React, { useEffect, useState } from 'react';
import PopSheet from '../../../components/molecules/PopSheet';
import FormView from '../../../components/organisms/FormView';
import FormWrapper from '../../../components/organisms/FormView/FormWrapper';
import {
    listAddresses,
    listDocuments,
    urls,
} from '../../../services/_crm.service';
import DocumentPreview from '../../Storage/DocumentPreview';

const KycFormAddress = ({ applicant, onUpdate }) => {
    const [edit, setEdit] = useState({});
    const [editAddressProof, setEditAddressProof] = useState(null);
    const [request, setRequest] = useState({});
    const [addresses, setAddresses] = useState([]);
    const [addressProofs, setAddressProofs] = useState({});

    const handleUpdate = (response) => {
        if (onUpdate) {
            onUpdate(response);
        }
        setRequest(response);
    };

    const handleSubmit = () => {
        initAddresses();
        setEdit({});
    };

    const handleAddressProofSubmit = (response) => {
        initAddressProofs(response?.related_model_id);
        setEditAddressProof(null);
    };

    const initAddresses = async () => {
        const response = await listAddresses(applicant.id);
        for (const element of response) {
            await initAddressProofs(element.id);
        }
        setAddresses(response);
    };

    const initAddressProofs = async (related_model_id) => {
        const tmp = { ...addressProofs };
        const response = await listDocuments(related_model_id, 'address');
        if (response) {
            tmp[related_model_id] = response[response.length - 1];
        }
        setAddressProofs(tmp);
    };

    useEffect(() => {
        if (applicant.id) {
            initAddresses();
        }
    }, [applicant]);

    const formItems = [
        {
            type: 'hidden',
            name: 'applicant_id',
            value: applicant.id,
        },
        {
            type: 'hidden',
            name: 'tags',
            value: 'current',
        },
        { type: 'text', name: 'address_line' },
        {
            type: 'text',
            name: 'pincode',
            class: 'col-md-12',
            is_required: true,
            /* lookup: {
                type: 'pincode',
                url: urls.lookup_pi ncode,
                target: {
                    pincode_details: ['city', 'state'],
                },
            }, */
        },
        /* {
            type: 'rawText',
            name: 'pincode_details',
            showLabel: false,
            className: 'info',
            prefill: {
                type: 'pincode',
                url: urls.lookup_pincode,
                key: 'pincode',
                value: ['city', 'state'],
            },
        }, */
    ];

    return (
        <>
            {addresses?.length > 0 &&
                addresses?.map((address) => {
                    return address.tags.includes('current') ? (
                        <div key={address.id}>
                            <div>
                                {address.address_line}
                                <br />
                                {address.city}, {address.state}{' '}
                                {address.pincode}
                            </div>
                            {addressProofs?.[address.id] ? (
                                <DocumentPreview
                                    onCtaClick={() =>
                                        setEditAddressProof(address.id)
                                    }
                                    documents={[addressProofs[address.id]]}
                                    config={{
                                        label: 'View Address Proof',
                                        title: 'Uploaded Address Proof',
                                        cta: 'Re-upload Address Proof',
                                    }}
                                    className='color-green'
                                />
                            ) : (
                                <button
                                    type='button'
                                    className='btn-link my-10'
                                    onClick={() =>
                                        setEditAddressProof(address.id)
                                    }>
                                    Upload Address Proof
                                </button>
                            )}
                            <> &bull; </>
                            <button
                                type='button'
                                className='btn-link my-10'
                                onClick={() => setEdit(address)}>
                                Edit Address
                            </button>
                        </div>
                    ) : (
                        <React.Fragment key={address.id}></React.Fragment>
                    );
                })}
            {addresses.length === 0 && (
                <FormView
                    data={request}
                    updated={handleUpdate}
                    config={{
                        items: formItems,
                    }}
                />
            )}
            {edit?.id && (
                <PopSheet
                    closeOnClick={false}
                    title='Edit Address'
                    onClose={() => setEdit({})}>
                    <FormWrapper
                        data={edit}
                        config={{
                            method: 'PATCH',
                            url: urls.address_details,
                            items: formItems,
                        }}
                        submitted={handleSubmit}
                    />
                </PopSheet>
            )}
            {editAddressProof && (
                <PopSheet
                    closeOnClick={false}
                    title='Address Proof'
                    onClose={() => setEditAddressProof(null)}>
                    <FormWrapper
                        data={edit}
                        config={{
                            method: 'POST',
                            url: urls.documents,
                            items: [
                                {
                                    type: 'hidden',
                                    name: 'related_model_id',
                                    value: editAddressProof,
                                },
                                {
                                    type: 'hidden',
                                    name: 'related_model_name',
                                    value: 'address',
                                },
                                {
                                    type: 'select',
                                    name: 'name',
                                    options_src: 'constants',
                                    options: `${applicant.applicant_type}_address_proofs`,
                                },
                                {
                                    type: 'file',
                                    name: 'files',
                                    class: 'col-md-12',
                                },
                            ],
                        }}
                        submitted={handleAddressProofSubmit}
                    />
                </PopSheet>
            )}
        </>
    );
};

export default KycFormAddress;
