import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { root } from '../apps/CompanyApp/config';
import PageHeader from '../components/molecules/PageHeader';
import FormWrapper from '../components/organisms/FormView/FormWrapper';
import { buildUrl } from '../helpers/str';
import { ping, urls } from '../services/_identity.service';
import { useUser } from '../context/UserContext';

const CompanyLogin = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { loginUser } = useUser();
    const program_ref_id =
        searchParams.get('program_ref_id') ||
        localStorage.getItem('program_ref_id') ||
        '';
    const [request, setRequest] = useState({ username: '', password: '' });

    const handleSubmit = async (response) => {
        if (response?.access) {
            localStorage.setItem(
                process.env.REACT_APP_LOCAL_STORAGE_AUTH,
                response.access,
            );
            localStorage.setItem(
                process.env.REACT_APP_LOCAL_STORAGE_AUTH + '-type',
                'company',
            );
            const pingedData = await ping('compamy');
            loginUser(pingedData);
            navigate(root);
        }
    };

    const handleUpdated = (response) => {
        setRequest(response);
    };

    useEffect(() => {}, []);

    return (
        <>
            <PageHeader
                className='p-0 pb-20'
                subtitle='Login using your Crego username & password'>
                Sign In
            </PageHeader>
            <FormWrapper
                type='form'
                config={{
                    url: buildUrl(urls.auth_token),
                    btn: 'Login',
                    items: [
                        { name: 'username' },
                        { name: 'password', type: 'password' },
                    ],
                }}
                updated={handleUpdated}
                submitted={handleSubmit}
                data={request}
            />
        </>
    );
};

export default CompanyLogin;
