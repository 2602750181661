import React from 'react';
import PopSheet from '../../../components/molecules/PopSheet';
import { amount } from '../../../helpers/str';
import { Link } from 'react-router-dom';

const PaymentForm = ({ show = false, onClose, config }) => {
    return (
        <>
            {show && (
                <PopSheet
                    onClose={onClose}
                    title='Make Payment'>
                    <Link
                        to={`/borrower/records/new?amount=${config.amount.toFixed(
                            2,
                        )}&full=1`}
                        className='btn btn-line mb-10 text-left pl-15'>
                        Pay Full Amount ({amount(config.amount)})
                    </Link>
                    <Link
                        to='/borrower/records/new'
                        className='btn btn-line text-left pl-15'>
                        Pay Custom Amount
                    </Link>
                </PopSheet>
            )}
        </>
    );
};

export default PaymentForm;
