import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Image from '../../../components/atoms/Image';
import { MAPPINGS } from '../../../consts/mapping';
import { useUser } from '../../../context/UserContext';
import { alertError } from '../../../helpers/alert';
import { date } from '../../../helpers/datetime';
import {
    businessTypeFromDocument,
    getPersonTag,
    parseAddress,
} from '../../../helpers/str';
import { createLead, urls } from '../../../services/_crm.service';
import { changeProfile } from '../../../services/_identity.service';
import { routes } from '../config';
import GstKycData from './GstKycData';
import Directors from './Directors';
import KycSummaryDocuments from './KycSummaryDocuments';
import FormView from '../../../components/organisms/FormView';
import { getProgramConfig } from '../../../services/_datum.service';

const KycSummary = () => {
    const { user, loginUser } = useUser();
    const navigate = useNavigate();
    const [request, setRequest] = useState({});
    const [manualData, setManualData] = useState({});
    const kyc = JSON.parse(localStorage.getItem('kyc'));
    const pan = kyc?.pan;
    const gstin = kyc?.gstin;
    const businessType = businessTypeFromDocument(gstin || pan);

    const pan_data = kyc?.pan_data?.result?.response?.result;
    const gst_detailed = kyc?.gst_data?.result?.gstnDetailed;
    const directors = gst_detailed?.directorNames;
    const ckyc_person =
        kyc?.ckyc_prefill?.CKYC_SEARCH_AND_DOWNLOAD.personal_details;
    const ckyc_images =
        kyc?.ckyc_prefill?.CKYC_SEARCH_AND_DOWNLOAD.image_details;

    const makeAddresses = (ckyc) => {
        const addresses = [];
        const permanent_address = makeAddress(ckyc, 'perm');
        if (permanent_address.address_line) {
            addresses.push(permanent_address);
        }
        const comm_address = makeAddress(ckyc, 'corres');
        if (
            comm_address.address_line &&
            comm_address.address_line !== permanent_address.address_line
        ) {
            addresses.push(comm_address);
        }
        return addresses;
    };

    const makeAddress = (data, type) => {
        if (!data?.[`${type}_line1`]) {
            return {};
        }
        return {
            source: 'GST_DETAILS',
            tags: ['current'],
            address_line:
                data?.[`${type}_line1`] + ' ' + data?.[`${type}_dist`],
            city: data?.[`${type}_city`],
            state: data?.[`${type}_state`],
            pincode: data?.[`${type}_pin`],
        };
    };

    const makeBusinessFromGst = () => {
        const business = {
            source: 'GST_DETAILS',
            is_primary: true,
            applicant_type: 'business',
            gstin,
        };

        if (gstin) {
            business.pan = gstin.slice(2, 12);
        }

        if (
            gst_detailed?.tradeNameOfBusiness ||
            gst_detailed?.legalNameOfBusiness
        ) {
            const gst_address = parseAddress(
                gst_detailed?.principalPlaceAddress?.address,
            );
            business.name =
                businessType === 'P' &&
                gst_detailed.tradeNameOfBusiness &&
                gst_detailed.tradeNameOfBusiness !== 'NA'
                    ? gst_detailed.tradeNameOfBusiness
                    : gst_detailed.legalNameOfBusiness;
            business.date_of_incorporation = gst_detailed?.registrationDate;
            business.business_type =
                MAPPINGS?.signzy?.business_types?.[
                    gst_detailed?.constitutionOfBusiness
                ] || '';
            business.addresses = [
                {
                    source: 'GST_DETAILS',
                    address_line: gst_address?.address_line,
                    city: gst_address?.city,
                    state: gst_address?.state,
                    pincode: gst_address?.pincode,
                    tags: ['current'],
                },
            ];
        }
        return business;
    };

    const makePerson = (data, src = '') => {
        let applicant = {
            name: data?.name,
            source: src,
            applicant_type: 'person',
            primary_mobile: user.mobile,
            is_authorized_signatory: true,
            pan_no: data?.pan,
        };

        applicant.tags.push(getPersonTag(businessType));

        applicant = appendCkycData(applicant);
        return applicant;
    };

    const appendCkycData = (applicant) => {
        if (ckyc_person?.father_fname) {
            applicant.father_name = ckyc_person?.father_fname;
        }
        if (ckyc_person?.father_lname) {
            applicant.father_name += ' ' + ckyc_person?.father_lname;
        }
        if (ckyc_person?.father_name) {
            applicant.father_name = ckyc_person?.father_name;
        }

        if (ckyc_person?.dob) {
            applicant.dob = date(ckyc_person?.dob, 'YYYY-MM-DD', 'DD-MM-YYYY');
            applicant.source = 'DECENTRO_CKYC_PREFILL';
        }

        Object.entries({
            gender: 'gender',
            pan: 'pan_no',
        }).forEach(([key, value]) => {
            if (ckyc_person?.[key]) {
                applicant[value] = ckyc_person?.[key];
            }
        });

        applicant.addresses = makeAddresses(ckyc_person);
        return applicant;
    };

    const makeManualPayload = (data) => {
        if (
            data?.name?.toLowerCase().indexOf('private limited') !== -1 ||
            data?.name?.toLowerCase().indexOf('pvt') !== -1
        ) {
            data.business_type = 'private_limited';
        }
        setManualData(data);
    };

    const makePayload = () => {
        const applicants = [];
        if (
            gstin &&
            (!gst_detailed?.gstinStatus ||
                gst_detailed?.gstinStatus?.toLowerCase().indexOf('active') ===
                    -1)
        ) {
            alertError(
                'The GST is not eligible for a credit line application',
                `GST Status: ${gst_detailed.gstinStatus || 'Not Available'}`,
            );
            setTimeout(() => {
                navigate(-1);
            }, 3000);
        } else if (gstin && gst_detailed?.gstinStatus === 'ACTIVE') {
            applicants.push(makeBusinessFromGst());
            if (businessType === 'P') {
                applicants.push(
                    makePerson({
                        name:
                            directors?.[0] || gst_detailed?.legalNameOfBusiness,
                        pan: gstin.slice(2, 12),
                    }),
                );
            } else if (directors?.length === 1) {
                applicants.push(
                    makePerson({ name: directors[0] }, 'GST_DETAILS'),
                );
            }
        } else if (pan && pan_data?.name) {
            const tmp = {
                name: pan_data?.name,
                father_name: pan_data?.fatherName,
            };
            applicants.push(
                businessType === 'P'
                    ? makePerson(tmp, 'PAN_DETAILS')
                    : {
                          name: pan_data?.name,
                          is_primary: true,
                          source: 'PAN_DETAILS',
                          applicant_type: 'business',
                          pan_no: pan_data?.number,
                      },
            );
        }
        const tmp = {
            program_id: user.program_id,
            primary_mobile: user.mobile,
            applicants,
        };
        setRequest(tmp);
        if (!directors && !pan_data?.name && !ckyc_person) {
            setManualData({
                pan: businessType === 'P' ? '' : pan,
                person_entity_pan: businessType === 'P' ? pan : '',
            });
        } else if (
            pan_data?.name &&
            businessTypeFromDocument(pan_data?.number) !== 'P'
        ) {
            setManualData({
                pan: pan_data?.number,
                name: pan_data.name,
            });
        }
        return tmp;
    };

    const handleProceed = async () => {
        const extra_data = localStorage.getItem('extra_data');
        if (extra_data) {
            request.extra_data = JSON.parse(extra_data);
        }
        if (directors?.length > 1 && request.applicants.length < 2) {
            return alertError(
                'Please select an authorized signatory to continue',
                'Authorized Signatory Not Selected',
            );
        }
        if (businessType === 'F' && directors?.length > 1) {
            directors.forEach((director) => {
                if (director !== request?.applicants?.[1]?.name) {
                    const tmp = { ...request };
                    tmp.applicants.push({
                        source: 'GST_DETAILS',
                        applicant_type: 'person',
                        name: director,
                    });
                    setRequest(tmp);
                }
            });
        }

        let response = {};
        if (
            !directors &&
            (!pan_data?.name ||
                businessTypeFromDocument(pan_data?.number) !== 'P') &&
            !ckyc_person
        ) {
            response = await createLead({
                primary_mobile: user.mobile,
                program_id: user.program_id,
                applicants: [
                    {
                        applicant_type: 'business',
                        is_primary: true,
                        business_type: manualData?.business_type,
                        name: manualData?.name,
                        pan_no: manualData?.pan,
                        address: {
                            tags: manualData.tags,
                            address_line: manualData.address_line,
                            pincode: manualData.pincode,
                            city: manualData.city,
                            state: manualData.state,
                        },
                    },
                    {
                        applicant_type: 'person',
                        name: manualData?.person_entity_name,
                        pan_no: manualData.person_entity_pan,
                        is_authorized_signatory: true,
                        tags: [getPersonTag(businessType)],
                    },
                ],
            });
        } else {
            response = await createLead(request);
        }
        if (response.id) {
            switchProfile({
                lead_id: response.id,
                program_id: user.program_id,
            });
        }
    };

    const switchProfile = async (profile) => {
        const payload = {
            lead_id: profile.lead_id,
            program_id: profile.program_id,
        };
        const response = await changeProfile(payload);
        if (response?.access_token) {
            localStorage.setItem(
                process.env.REACT_APP_LOCAL_STORAGE_AUTH,
                response.access_token,
            );
            payload.program_config = await getProgramConfig(profile.program_id);
            loginUser(payload);
            if (
                directors?.length > 1 ||
                (directors?.length === 0 && businessType !== 'P')
            ) {
                navigate(routes.personal_details);
            } else {
                navigate(routes.person);
            }
        }
    };

    const handleApplicantChange = (value) => {
        const tmp = { ...request };
        tmp.applicants[1] = {
            applicant_type: 'person',
            source: 'GST_DETAILS',
            name: value,
            is_authorized_signatory: true,
            tags: [getPersonTag(businessType)],
        };
        setRequest(tmp);
    };

    useEffect(() => {
        makePayload();
    }, []);

    return (
        <div className='container'>
            {kyc?.gst_data?.result?.gstin && (
                <>
                    <h1>{kyc?.gst_data?.result?.gstin}</h1>
                    <p>Verify your business information to continue KYC</p>
                </>
            )}
            <div className='mt-20'>
                {ckyc_images?.Photograph?.image_data && (
                    <div className='profile mb-10'>
                        <Image
                            className='profile__image'
                            src={`data:image/png;base64, ${ckyc_images?.Photograph?.image_data}`}
                        />
                    </div>
                )}
                <GstKycData gst_detailed={gst_detailed} />
                <Directors
                    businessType={businessType}
                    directors={directors}
                    ckyc_person={ckyc_person}
                    pan_data={pan_data}
                    handleApplicantChange={handleApplicantChange}
                />
                {!directors &&
                    (!pan_data?.name ||
                        businessTypeFromDocument(pan_data?.number) !== 'P') &&
                    !ckyc_person && (
                        <>
                            <FormView
                                updated={makeManualPayload}
                                data={manualData}
                                config={{
                                    items: [
                                        {
                                            type: 'text',
                                            name: 'name',
                                            label: 'Business Name',
                                            is_required: true,
                                        },
                                        {
                                            type: 'select',
                                            name: 'business_type',
                                            options_src: 'constants',
                                            options: 'business_types',
                                            is_required: true,
                                        },
                                        {
                                            type: 'text',
                                            name: 'pan',
                                            label: 'Business PAN',
                                        },
                                        {
                                            type: 'text',
                                            name: 'person_entity_name',
                                            label: 'Authorized Person Name',
                                            is_required: true,
                                        },
                                        {
                                            type: 'text',
                                            name: 'person_entity_pan',
                                            label: 'Authorized Person PAN',
                                            is_required: true,
                                        },
                                        {
                                            type: 'hidden',
                                            name: 'tags',
                                            value: ['current'],
                                        },
                                        {
                                            type: 'subheading',
                                            title: 'Address',
                                        },
                                        { type: 'text', name: 'address_line' },
                                        {
                                            type: 'text',
                                            name: 'pincode',
                                            class: 'col-md-12',
                                            lookup: {
                                                type: 'pincode',
                                                url: urls.lookup_pincode,
                                                fields: {
                                                    city: 'city',
                                                    state: 'state',
                                                },
                                            },
                                        },
                                        { type: 'text', name: 'city' },
                                        { type: 'text', name: 'state' },
                                    ],
                                }}
                            />
                        </>
                    )}
                {ckyc_images && <KycSummaryDocuments docs={ckyc_images} />}
                <p className='color-green'>
                    <i className='ri ri-checkbox-line'></i> By clicking the
                    proceed button you are agreeing to share your information
                    for your credit line/loan application
                </p>
                {ckyc_images?.Signature?.image_data && (
                    <div className='profile mt-20'>
                        <Image
                            src={`data:image/png;base64, ${ckyc_images?.Signature?.image_data}`}
                        />
                    </div>
                )}
            </div>
            <div className='btn-group btn-fixed'>
                <Link
                    to={routes.home}
                    className='btn btn-line'>
                    Change GST
                </Link>
                <button
                    onClick={handleProceed}
                    className='btn'>
                    Proceed
                </button>
            </div>
        </div>
    );
};

export default KycSummary;
