import Resizer from 'react-image-file-resizer';

export const resizeFile = (file) => {
    const format = file.type.toString().indexOf('png') !== -1 ? 'PNG' : 'JPEG';
    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1000, // width
            1500, // height
            format, // format
            100, // quality
            0, // rotation
            (uri) => {
                resolve(uri);
            },
            'file', // output type
        );
    });
};
