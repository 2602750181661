import React from 'react';
import ReactDOM from 'react-dom/client';
import 'remixicon/fonts/remixicon.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './context/UserContext';
import { register } from 'swiper/element/bundle';
import { ThemeProvider } from './context/ThemeContext';

register();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    process.env.REACT_APP_STRICT_MODE &&
        process.env.REACT_APP_STRICT_MODE === 'true' ? (
        <React.StrictMode>
            <ThemeProvider>
                <UserProvider>
                    <App />
                </UserProvider>
            </ThemeProvider>
        </React.StrictMode>
    ) : (
        <ThemeProvider>
            <UserProvider>
                <App />
            </UserProvider>
        </ThemeProvider>
    ),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
