import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoaderAnimated from '../../components/atoms/LoaderAnimated';
import PageHeader from '../../components/molecules/PageHeader';
import FormView from '../../components/organisms/FormView';
import { alertError } from '../../helpers/alert';
import { initLead } from '../../services/_crm.service';
import { routes } from './config';

const KycInit = () => {
    const [type, setType] = useState('gst');
    const [request, setRequest] = useState({});
    const [loading, setLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const navigate = useNavigate();
    const handleUpdated = (response) => {
        setRequest(response);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (type === 'gst' && !request.gstin) {
            alertError('Please enter your GST number');
        } else if (type === 'pan' && !request.pan_no) {
            alertError('Please enter your PAN number');
        } else {
            setLoading(true);
            try {
                const data = await initLead(request);
                data.type = type;
                if (data?.leads && data?.leads?.length > 0) {
                    alertError(
                        `The ${type} number you entered already has an account with us. Please login using ${data?.leads?.[0]?.primary_mobile}`,
                    );
                } else {
                    localStorage.setItem('kyc', JSON.stringify(data));
                    setTimeout(() => {
                        navigate(routes.summary);
                    }, 3000);
                    setFinished(true);
                }
            } catch (error) {}
            setLoading(false);
        }
    };

    const toggleType = () => {
        setType(type === 'gst' ? 'pan' : 'gst');
    };
    return (
        <div
            className='container init'
            style={{ marginTop: -100 }}>
            <PageHeader
                className='p-0 pb-20'
                subtitle={
                    type === 'gst'
                        ? 'Enter your GST number to start your application'
                        : 'Enter your PAN number to start your application'
                }>
                {type === 'gst' ? 'GST Number' : 'PAN Number'}
            </PageHeader>
            {finished && (
                <LoaderAnimated
                    loading={true}
                    name='success'
                    loop={false}
                />
            )}
            {loading && <LoaderAnimated loading={loading} />}
            <form onSubmit={handleSubmit}>
                <FormView
                    config={{
                        items:
                            type === 'gst'
                                ? [{ name: 'gstin' }]
                                : [{ name: 'pan_no' }],
                    }}
                    updated={handleUpdated}
                    data={request}
                />
                <button
                    className='btn'
                    disabled={loading}>
                    Start Application
                </button>
            </form>
            <small
                className='init__reset'
                onClick={toggleType}>
                {type === 'gst'
                    ? "Don't have GST, enter PAN instead"
                    : 'Enter GST instead'}
            </small>
        </div>
    );
};

export default KycInit;
