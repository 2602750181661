import React, { useEffect, useState } from 'react';

const TabbedNav = ({ mode, config, onChange, tab }) => {
    const [selected, setSelected] = useState(tab || config.items[0].value);

    const handleSelected = (value) => {
        setSelected(value);
        if (onChange) {
            onChange(value);
        }
    };

    useEffect(() => {
        if (tab) {
            setSelected(tab);
        }
    }, [config, tab]);

    return (
        <ul className={`tabbed ${mode ? 'tabbed-' + mode : ''}`}>
            {config.items &&
                config.items.length > 0 &&
                config.items?.map((item, index) => {
                    return (
                        <li
                            onClick={() => handleSelected(item.value)}
                            className={`tabbed__item ${
                                item.value === selected ? 'active' : ''
                            }`}
                            key={item.value}>
                            {item.name}
                        </li>
                    );
                })}
        </ul>
    );
};

export default TabbedNav;
