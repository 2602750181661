import { perc } from '../../../helpers/maths';
import { amountWords } from '../../../helpers/str';

const CreditLineV2 = ({ line }) => {
    return (
        <div className='card card-special p-20'>
            <div className='breakup mt-10 row'>
                <div className='breakup__item col'>
                    <span className='breakup__item__number'>
                        <span className='breakup__item__number__currency'>
                            ₹
                        </span>
                        {amountWords(line.available, false)}
                    </span>
                    <div className='breakup__item__label'>Available</div>
                </div>
                <div className='breakup__item col'>
                    <span className='breakup__item__number'>
                        <span className='breakup__item__number__currency'>
                            ₹
                        </span>
                        {amountWords(line.used, false)}
                    </span>
                    <div className='breakup__item__label'>Used</div>
                </div>
                <div className='breakup__item col'>
                    <span className='breakup__item__number'>
                        <span className='breakup__item__number__currency'>
                            ₹
                        </span>
                        {amountWords(line.approved, false)}
                    </span>
                    <div className='breakup__item__label'>Approved</div>
                </div>
            </div>
            <div className='progress mt-20'>
                <div
                    className='progress__status bg-success rounded'
                    role='progressbar'
                    style={{
                        width: `${perc(line.available, line.approved)}%`,
                    }}
                    aria-valuenow={perc(line.available, line.approved)}
                    aria-valuemin='0'
                    aria-valuemax={perc(line.available, line.approved)}></div>
            </div>
            <div className='text-center fs-sm mt-20'>My Credit Line</div>
        </div>
    );
};
export default CreditLineV2;
