import React, { useState, useEffect } from 'react';

const CurrentLocation = () => {
    const [location, setLocation] = useState({ lat: null, lon: null });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        lat: position.coords.latitude,
                        lon: position.coords.longitude,
                    });
                    setLoading(false);
                },
                (error) => {
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            setError(
                                'User denied the request for Geolocation.',
                            );
                            break;
                        case error.POSITION_UNAVAILABLE:
                            setError('Location information is unavailable.');
                            break;
                        case error.TIMEOUT:
                            setError(
                                'The request to get user location timed out.',
                            );
                            break;
                        case error.UNKNOWN_ERROR:
                            setError('An unknown error occurred.');
                            break;
                        default:
                            setError('An unknown error occurred.');
                    }
                    setLoading(false);
                },
            );
        } else {
            setError('Geolocation is not supported by this browser.');
            setLoading(false);
        }
    }, []);

    const LoadingSVG = () => (
        <svg
            width='50'
            height='50'
            viewBox='0 0 100 100'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'>
            <circle
                cx='50'
                cy='50'
                r='35'
                stroke='currentColor'
                strokeWidth='10'
                fill='none'
                strokeDasharray='165'
                strokeDashoffset='0'>
                <animateTransform
                    attributeName='transform'
                    type='rotate'
                    from='0 50 50'
                    to='360 50 50'
                    dur='1s'
                    repeatCount='indefinite'
                />
            </circle>
        </svg>
    );

    return (
        <div className='location bg-red-dark p-20'>
            <h5>Current Location</h5>
            {loading ? (
                <div>
                    <p className='mb-10'>Fetching location...</p>
                    <LoadingSVG />
                </div>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                <p>
                    Lat, Long: {location.lat}, {location.lon}
                </p>
            )}
        </div>
    );
};

export default CurrentLocation;
