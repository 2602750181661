import React, { useEffect } from 'react';
import DetailView from '../../../components/organisms/DetailView';
import KycSummaryApplicants from './KycSummaryApplicants';

const Directors = ({
    businessType,
    directors,
    ckyc_person,
    pan_data,
    handleApplicantChange,
}) => {
    useEffect(() => {}, [directors, ckyc_person, pan_data]);
    return (
        <>
            {directors?.length === 1 && ckyc_person && (
                <>
                    <h5 className='mt-20 mb-10'>Authorized Signatory</h5>
                    <DetailView
                        details={ckyc_person}
                        config={{
                            items: [
                                {
                                    name: 'full_name',
                                    label: 'Name',
                                },
                                {
                                    name: 'father_fullname',
                                    label: "Father's Name",
                                },
                                {
                                    name: 'gender',
                                },
                                {
                                    name: 'dob',
                                },
                            ],
                        }}
                    />
                </>
            )}
            {directors?.length === 1 && !ckyc_person && (
                <>
                    <h5 className='mt-20 mb-10'>Authorized Signatory</h5>
                    <div className='list list-bg'>
                        <div className='list__item'>
                            <div className='list__item__key'>Name</div>
                            <div className='list__item__value'>
                                {directors[0]}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {businessType === 'P' && !directors && pan_data?.name && (
                <>
                    <h5 className='mt-20 mb-10'>Authorized Signatory</h5>
                    <DetailView
                        details={pan_data}
                        config={{
                            items: [
                                {
                                    name: 'name',
                                    label: 'Name',
                                },
                                {
                                    name: 'fatherName',
                                    label: "Father's Name",
                                },
                            ],
                        }}
                    />
                </>
            )}
            {directors?.length > 1 && (
                <KycSummaryApplicants
                    names={directors}
                    onChange={handleApplicantChange}
                />
            )}
        </>
    );
};

export default Directors;
