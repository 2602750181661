import axios from 'axios';
import React, { useEffect, useState } from 'react';
import FormView from '.';
import { confirm, toastSuccess } from '../../../helpers/alert';
import { buildPayload, flattenForm } from '../../../helpers/form';
import { getValue, updateObject } from '../../../helpers/json';
import { beautify } from '../../../helpers/str';
import { transform } from '../../../helpers/transform';
import Loader from '../../atoms/Loader';

const RepeaterForm = (props) => {
    const { config, details, constants, updated, submitted, idPrefix } = props;
    const [request, setRequest] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleUpdated = (index, data) => {
        const tmp = [...request];
        tmp[index] = { ...data };
        setRequest(tmp);
        if (updated) {
            updated(tmp);
        }
    };

    const add = () => {
        const tmp = [...request];
        tmp.push({});
        setActiveIndex(tmp.length - 1);
        setRequest(tmp);
    };

    const duplicate = (index) => {
        const tmp = [...request];
        tmp.push(tmp[index]);
        setActiveIndex(tmp.length - 1);
        setRequest(tmp);
    };

    const remove = (index) => {
        confirm('Delete?', 'Do you really want to delete this?', () => {
            const tmp = [...request];
            tmp.splice(index, 1);
            setRequest(tmp);
            if (updated) {
                updated(tmp);
            }
        });
    };

    const save = async (e) => {
        e.preventDefault();
        setLoading(true);
        const tmp = [...request];
        tmp.forEach((row, index) => {
            tmp[index] = buildPayload(config.items, row);
        });

        if (config.url) {
            await axios
                .request({
                    url: config.url,
                    data: updateObject({}, config.as || config.name, tmp),
                    method: config.method || 'post',
                })
                .finally(() => {
                    setLoading(false);
                });
        } else if (submitted) {
            submitted(updateObject({}, config.as || config.name, tmp));
            setLoading(false);
        }
        toastSuccess('Saved Successfully!');
    };

    useEffect(() => {
        // Init Request
        (() => {
            const tmp = details ? [...details] : config.minLength ? [{}] : [];
            tmp.forEach((row, index) => {
                tmp[index] = flattenForm(config.items, row);
            });
            setRequest(tmp);
        })();
    }, [config, details]);

    return (
        <>
            <form
                action=''
                onSubmit={save}>
                <Loader loading={loading} />
                <div
                    className='accordion  accordion-icon-toggle'
                    id={`accordion_${idPrefix}`}>
                    {request?.map((details, index) => {
                        const cls = transform('class', index % 5);
                        return (
                            <div
                                className={`accordion-item border border-dashed border-${cls} bg-light-${cls}`}
                                key={`form_view_${index}`}>
                                <div
                                    className={`accordion-header py-1 d-flex justify-content-between align-items-center ${
                                        index !== activeIndex ? 'collapsed' : ''
                                    }`}
                                    data-bs-toggle='collapse'
                                    data-bs-target={`#collapse_${idPrefix}_${index}`}>
                                    <div className='d-flex align-items-center'>
                                        <span className='ms-2 accordion-icon'>
                                            &rarr;
                                        </span>
                                        <h3
                                            className={`fs-4 fw-semibold mb-0 text-${cls}`}>
                                            {config.titleKey &&
                                            getValue(details, config.titleKey)
                                                ? beautify(
                                                      getValue(
                                                          details,
                                                          config.titleKey,
                                                      ),
                                                  )
                                                : `${config.title} ${
                                                      index + 1
                                                  }`}
                                        </h3>
                                        <div className='ms-2'>
                                            {config?.highlights?.map(
                                                (highlight, highlightIndex) => {
                                                    return (
                                                        <React.Fragment
                                                            key={`highlight_${highlight}`}>
                                                            {getValue(
                                                                details,
                                                                highlight,
                                                            ) && (
                                                                <strong className='px-1'>
                                                                    {beautify(
                                                                        highlight,
                                                                    )}
                                                                    :{' '}
                                                                    {beautify(
                                                                        getValue(
                                                                            details,
                                                                            highlight,
                                                                        ),
                                                                    )}
                                                                </strong>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                },
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        {index === request.length - 1 && (
                                            <button
                                                type='button'
                                                onClick={add}
                                                className='btn btn-sm btn-icon btn-info me-2'>
                                                <i className='ri ri-add-line'></i>
                                            </button>
                                        )}
                                        <button
                                            type='button'
                                            onClick={() => duplicate(index)}
                                            className='btn btn-sm btn-icon btn-warning me-2'>
                                            <i className='ri ri-file-copy-2-fill'></i>
                                        </button>
                                        <button
                                            type='button'
                                            onClick={() => remove(index)}
                                            className='btn btn-sm btn-icon btn-danger me-2'>
                                            <i className='ri ri-close-line'></i>
                                        </button>
                                    </div>
                                </div>
                                <div
                                    id={`collapse_${idPrefix}_${index}`}
                                    className={`accordion-collapse collapse ${
                                        index === activeIndex ? 'show' : ''
                                    }`}
                                    data-bs-parent={`#accordion_${idPrefix}`}>
                                    <div className='accordion-body pt-0'>
                                        <FormView
                                            constants={constants}
                                            data={details}
                                            config={{
                                                ...config,
                                                disableButton: true,
                                            }}
                                            updated={(data) =>
                                                handleUpdated(index, data)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                {request.length === 0 && (
                    <button
                        type='button'
                        onClick={add}
                        className='btn btn-sm btn-info me-2'>
                        {config.btn || 'Add'}
                    </button>
                )}
                {config.save && request.length > 0 && (
                    <button className='btn btn-sm btn-primary mt-5'>
                        Save
                    </button>
                )}
            </form>
        </>
    );
};

export default RepeaterForm;
