import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { routes as onboardingRoutes } from '../apps/Onboarding/config';
import Loader from '../components/atoms/Loader';
import PageHeader from '../components/molecules/PageHeader';
import { useUser } from '../context/UserContext';
import { getServiceAccounts } from '../services/_crm.service';
import { changeProfile, getProfiles } from '../services/_identity.service';
import { getProgramConfig } from '../services/_datum.service';

const SwitchProgram = () => {
    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const { loginUser } = useUser();
    const navigate = useNavigate();
    const initProfiles = async () => {
        setLoading(true);
        const response = await getProfiles();
        if (response.length === 1) {
            switchProfile(response[0]);
        } else if (response.length === 0) {
            window.location.href = onboardingRoutes.home;
        }
        setProfiles(response);
        setLoading(false);
    };

    useEffect(() => {
        initProfiles();
    }, []);

    const switchProfile = async (profile) => {
        const payload = {
            lead_id: profile.lead_id,
            program_id: profile.program_id,
        };
        const response = await changeProfile(payload);
        if (response?.access_token) {
            localStorage.setItem(
                process.env.REACT_APP_LOCAL_STORAGE_AUTH,
                response.access_token,
            );

            const tmp = {};
            const serviceAccounts = await getServiceAccounts(
                payload.lead_id,
                payload.program_id,
            );
            Object.entries(serviceAccounts).forEach(([key, value]) => {
                if (value?.length) {
                    tmp[key] = value[0];
                }
            });
            payload.service_accounts = tmp;

            payload.program_config = await getProgramConfig(payload.program_id);

            loginUser(payload);
            navigate('/');
        }
    };

    return (
        <>
            {loading && (
                <>
                    <Loader loading={loading} />
                    <PageHeader
                        className='p-0'
                        subtitle={`We're checking accounts associated with your mobile number. Please wait`}>
                        Fetching your Account
                    </PageHeader>
                </>
            )}
            {!loading && profiles.length > 1 && (
                <>
                    <PageHeader
                        className='p-0'
                        subtitle={`${profiles.length} programs found. Select a program to switch & manage your credit line`}>
                        Hi {profiles[0]?.name},
                    </PageHeader>
                    <div className='pt-0'>
                        <ul className='menu'>
                            {profiles.map((row) => {
                                return (
                                    <li
                                        className='menu__item p-10'
                                        key={row.id}>
                                        <Link
                                            onClick={() => switchProfile(row)}>
                                            <div className={`menu__item__info`}>
                                                {/* <Image src='/assets/img/companies/adani_thumb.png' /> */}
                                                {row?.program?.name}
                                            </div>
                                            <i className='ri ri-arrow-right-line'></i>
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </>
            )}
        </>
    );
};

export default SwitchProgram;
