import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CLASSES } from '../../consts/classes';
import { searchArray, getArrayIndex } from '../../helpers/json';
import { parseUrl } from '../../helpers/str';
import Icon from '../atoms/Icon';
import ListItem from '../molecules/ListItem';
import TabbedNav from '../molecules/TabbedNav';
import { useSwipe } from '../../hooks/useSwipe';
import { routes } from '../../apps/BorrowerApp/config';

const ListView = ({ config, filter }) => {
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState(null);
    const [list, setList] = useState([]);

    const onSwipedLeft = () => {
        handleSwipe(1);
    };

    const onSwipedRight = () => {
        handleSwipe(-1);
    };

    const handleSwipe = (increment) => {
        const index = getArrayIndex(config.tabs, 'value', selectedTab.value);
        const newIndex = index + increment;
        if (newIndex >= 0 && newIndex <= config.tabs.length - 1) {
            const tabData = config.tabs[newIndex];
            setSelectedTab(tabData);
            getList(tabData.url);
            changeTab(tabData.value);
        }
    };

    const swipe = useSwipe();

    const getList = async (url) => {
        if (config.data) {
            setList(config.data);
        } else {
            const { data } = await axios.get(url, {
                params: filter,
            });
            if (data.results) {
                setList(data.results);
            } else {
                setList(data);
            }
        }
    };

    useEffect(() => {
        if (config.tabs && config.tab) {
            changeTab(config.tab);
        } else {
            getList(config.api);
        }
    }, [config, filter]);

    const changeTab = (tab) => {
        const tabData = searchArray(config.tabs, 'value', tab);
        setSelectedTab(tabData);
        getList(tabData.url);
    };

    const handleTabClick = (tab) => {
        navigate(routes.topups + '/' + tab);
    };

    const handleNavigate = (data) => {
        if (config.tabs && selectedTab) {
            navigate(parseUrl(selectedTab.path, data));
        } else if (config.path) {
            navigate(parseUrl(config.path, data));
        }
    };

    return (
        <div
            className='list'
            onTouchStart={swipe.onTouchStart}
            onTouchMove={swipe.onTouchMove}
            onTouchEnd={() => swipe.onTouchEnd(onSwipedLeft, onSwipedRight)}>
            {config.tabs && selectedTab && (
                <TabbedNav
                    onChange={(tab) => handleTabClick(tab)}
                    tab={selectedTab.value}
                    config={{
                        items: config.tabs,
                    }}
                />
            )}
            {list?.map((row) => {
                const classNames = {};
                if (config.icon) {
                    ['icon', 'iconBg'].forEach((key) => {
                        const chunks = config.icon.split(',');
                        chunks.forEach((i) => {
                            if (key === 'icon' && row[i] && !classNames.icon) {
                                const item = searchArray(
                                    config.items,
                                    'name',
                                    i,
                                );
                                if (item?.options) {
                                    const options = item.options;
                                    if (options?.[row[i]]?.icon) {
                                        classNames[key] = options[row[i]].icon;
                                    }
                                }
                            } else if (key === 'iconBg') {
                                classNames[key] = CLASSES[row[i]];
                            }
                        });
                    });
                }
                return (
                    <div
                        key={row.id}
                        className={`list__item ${
                            config?.path || selectedTab?.path ? 'clickable' : ''
                        }`}
                        onClick={() => handleNavigate(row)}>
                        {config.icon && (
                            <div
                                className={`list__item__icon bg-${classNames?.iconBg}`}>
                                <Icon name={classNames?.icon} />
                            </div>
                        )}
                        <div className='list__item__content'>
                            {config.tabs &&
                                config.tab &&
                                selectedTab?.items?.map((item) => {
                                    return (
                                        <ListItem
                                            key={item.name}
                                            item={item}
                                            details={row}
                                        />
                                    );
                                })}
                            {!config.tabs &&
                                !config.tab &&
                                config.items.map((item) => {
                                    return (
                                        <ListItem
                                            key={item.name}
                                            item={item}
                                            details={row}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ListView;
