import axios from 'axios';
import { useEffect, useState } from 'react';
import { beautify } from '../../../helpers/str';
import { transform } from '../../../helpers/transform';
import { useConstants } from '../../../context/ConstantsContext';

const InputFieldSelect = (props) => {
    const { config = {}, onChange, defaultValue, className, constants } = props;
    const [value, setValue] = useState(defaultValue || '');
    const [options, setOptions] = useState([]);
    const consts = useConstants();

    const initOptions = async () => {
        let optionsList = config.options;
        if (
            optionsList &&
            optionsList[0] &&
            typeof optionsList[0] !== 'object'
        ) {
            optionsList = transform('options', optionsList);
        }
        if (config.options_src === 'prop') {
            optionsList = transform(
                'options',
                constants[config.options.key],
                config.options.value,
                config.options.label,
            );
        } else if (config.options_src === 'constants') {
            optionsList = consts.constants[config.options];
        } else if (config.options_src === 'api') {
            const { data } = await axios.get(config.options.url);
            optionsList = transform(
                'options',
                data.results || data,
                config.options.value,
                config.options.label,
            );
        }
        setOptions(optionsList);
    };

    const handleChange = (e) => {
        const val = e.target.value;
        setValue(val);
        if (onChange) {
            onChange(val, e.target.name);
        }
    };

    useEffect(() => {
        initOptions();
    }, [config, defaultValue]);

    return (
        <select
            className={`control ${className}`}
            readOnly={config.readOnly}
            onChange={handleChange}
            value={value}>
            <option value=''>{config.placeholder}</option>
            {options &&
                options.map((option) => {
                    return (
                        <option
                            value={option.value}
                            key={option.value}>
                            {option.label
                                ? option.label
                                : beautify(option.value)}
                        </option>
                    );
                })}
        </select>
    );
};

export default InputFieldSelect;
