import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppHeader from '../components/organisms/AppHeader';
import Login from './Login';
import SwitchProgram from './SwitchProgram';
import VerifyLogin from './VerifyLogin';
import CompanyLogin from './CompanyLogin';
import LoginJupiter from './LoginJupiter';

const Auth = () => {
    return (
        <>
            <AppHeader
                logout={false}
                back={false}
            />
            <div className='container login'>
                <div className='login__inner'>
                    <Routes>
                        <Route
                            index
                            element={<Login />}></Route>
                        <Route
                            path='/company'
                            element={<CompanyLogin />}></Route>
                        <Route
                            path='/jupiter'
                            element={<LoginJupiter />}></Route>
                        <Route
                            path='/otp'
                            element={<VerifyLogin />}></Route>
                        <Route
                            path='/programs'
                            element={<SwitchProgram />}></Route>
                    </Routes>
                </div>
            </div>
        </>
    );
};

export default Auth;
