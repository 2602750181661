import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { filterArray, getValue, searchArray } from '../../../helpers/json';
import { beautify, buildUrl, singular } from '../../../helpers/str';
import { transform } from '../../../helpers/transform';
import { useConstants } from '../../../context/ConstantsContext';
import { getConstants } from '../../../services/_constants.service';

const FormControlSelect = (props) => {
    const {
        item,
        onSelectChange,
        value,
        className,
        theme,
        constants,
        request,
    } = props;
    const optionTemplate = {
        value: '',
        label:
            item.label ||
            `${item.creatable ? 'Select or Enter' : 'Select'} ${beautify(
                item.name,
            )}`,
    };
    const [options, setOptions] = useState([]);
    const [valueOption, setValueOption] = useState(
        item.multi ? [] : optionTemplate,
    );
    const consts = useConstants();

    const initOptions = async () => {
        let optionsList = item.options;
        if (item.options_src === 'arr') {
            optionsList = transform('options', optionsList, 'id', 'name');
        } else if (item.options_src === 'prop') {
            optionsList = transform(
                'options',
                constants[item.options.key],
                item.options.value,
                item.options.label,
            );
        } else if (item.options_src === 'constants') {
            if (item.options.indexOf('.') === -1) {
                optionsList = getConstants(consts.constants, item.options);
            } else {
                const chunks = item.options.split('.');
                let prevKey = chunks.shift();
                let dict = filterArray(consts.constants, 'type', prevKey);
                chunks.forEach((chunk, index) => {
                    const val = getValue(request, singular(prevKey));
                    const filtered = searchArray(dict, 'value', val);
                    prevKey = chunk;
                    if (!chunks[index + 1]) {
                        optionsList = getConstants(filtered.children, chunk);
                    } else {
                        dict = filtered.children;
                    }
                });
            }
        } else if (item.options_src === 'api') {
            if (
                (request && Object.keys(request).length !== 0) ||
                item.options.url.replace('https:', '').indexOf(':') === -1
            ) {
                const url = buildUrl(item.options.url, request, {
                    ignoreExtra: true,
                });
                let data = {};
                const response = await axios.get(url);
                data = response.data;

                optionsList = transform(
                    'options',
                    data.results || data,
                    item.options.value || 'id',
                    item.options.label || 'name',
                );
            }
        }
        if (item.hasOther) {
            optionsList.push({ value: 'other', label: 'Other' });
        }
        if (
            item.creatable &&
            value &&
            !searchArray(optionsList, 'value', value)
        ) {
            optionsList.push({ value, label: value });
        }
        initValueOptions(optionsList);
        setOptions(optionsList);
    };

    const initValueOptions = (arr) => {
        let tmpValueOption = null;
        if (typeof value === 'string') {
            const tmp = searchArray(arr, 'value', value);
            tmpValueOption = tmp?.value
                ? tmp
                : item.multi
                ? []
                : optionTemplate;
        } else if (Array.isArray(value)) {
            const values = [];
            value.forEach((valueItem) => {
                const tmp = searchArray(
                    arr,
                    'value',
                    typeof valueItem === 'object'
                        ? getValue(valueItem, item.valueKey)
                        : valueItem,
                );
                if (tmp?.value) {
                    values.push(tmp);
                }
            });
            tmpValueOption = values || [optionTemplate];
        } else if (value?.value) {
            tmpValueOption = value;
        } else {
            tmpValueOption = item.multi ? [] : optionTemplate;
        }
        setValueOption(tmpValueOption);
    };

    const handleChange = (option) => {
        if (option?.__isNew__) {
            const tmp = [...options];
            tmp.push({ label: option.label, value: option.value });
            setOptions(tmp);
        }
        onSelectChange(item.name, option || optionTemplate);
    };

    useEffect(() => {
        initOptions();
    }, [value, request]);

    return (
        <>
            {item.creatable ? (
                <CreatableSelect
                    theme={(t) => ({ ...t, ...theme })}
                    name={item && item.name ? item.name : 'select'}
                    value={valueOption}
                    placeholder={
                        item.label || item.placeholder || 'Select or Enter...'
                    }
                    options={options}
                    isClearable={true}
                    className={`${className || 'me-2 w-100'}`}
                    closeMenuOnSelect={!item.multi}
                    isMulti={!!item.multi}
                    onChange={handleChange}
                    isDisabled={item.disabled}
                />
            ) : (
                <Select
                    theme={(t) => ({ ...t, ...theme })}
                    name={item && item.name ? item.name : 'select'}
                    value={valueOption}
                    placeholder={item.label || item.placeholder || 'Select...'}
                    options={options}
                    isClearable={true}
                    className={`${className || 'me-2 w-100'}`}
                    closeMenuOnSelect={!item.multi}
                    isMulti={!!item.multi}
                    onChange={handleChange}
                    isDisabled={item.disabled}
                />
            )}
        </>
    );
};

export default FormControlSelect;
