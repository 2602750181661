import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { flattenForm } from '../../../helpers/form';
import { getValue, searchArray } from '../../../helpers/json';
import { beautify, buildUrl, slugify } from '../../../helpers/str';
import { transform } from '../../../helpers/transform';
import FormControlChips from '../../molecules/FormControl/FormControlChips';
import FormControlDateRangePicker from '../../molecules/FormControl/FormControlDateRangePicker';
import FormControlDatepicker from '../../molecules/FormControl/FormControlDatepicker';
import FormControlFile from '../../molecules/FormControl/FormControlFile';
import FormControlGroup from '../../molecules/FormControl/FormControlGroup';
import FormControlMultiRange from '../../molecules/FormControl/FormControlMultiRange';
import FormControlRadio from '../../molecules/FormControl/FormControlRadio';
import FormControlRadioList from '../../molecules/FormControl/FormControlRadioList';
import FormControlSelect from '../../molecules/FormControl/FormControlSelect';
import FormControlSwitch from '../../molecules/FormControl/FormControlSwitch';
import FormControlSwitchTable from '../../molecules/FormControl/FormControlSwitchTable';
import FormControlTable from '../../molecules/FormControl/FormControlTable';
import FormControlTimepicker from '../../molecules/FormControl/FormControlTimepicker';
import InputFieldAmount from '../../molecules/InputField/InputFieldAmount';
import InputFieldSelect from '../../molecules/InputField/InputFieldSelect';
import FormViewLabel from './FormViewLabel';
import RepeaterForm from './RepeaterForm';
import CurrentLocation from '../../molecules/CurrentLocation';

const FormView = (props) => {
    const {
        is_editable = true,
        label = true,
        config,
        data,
        updated,
        verified,
        className,
        constants,
        submitted,
        idPrefix,
    } = props;
    const [request, setRequest] = useState({});
    const [hidden, setHidden] = useState([]);

    const handleChange = (e) => {
        updateValue(e.target.name, e.target.value);
    };

    const handleSelectChange = (name, option) => {
        if (Array.isArray(option)) {
            updateValue(
                name,
                option.map((a) => a.value),
            );
        } else {
            updateValue(name, option.value);
        }
    };

    const handleFileSelect = (item, files) => {
        updateValue(item.name, files);
    };

    const handleBlur = async (e, item) => {
        const tmp = { ...request };
        if (item.lookup && e.target.value) {
            try {
                const lookupData = await axios.get(
                    buildUrl(item.lookup.url, { value: e.target.value }),
                );
                if (item.lookup.fields) {
                    Object.entries(item.lookup.fields).forEach(
                        ([source, destination], index) => {
                            if (lookupData.data[source]) {
                                tmp[destination] = lookupData.data[source];
                            }
                        },
                    );
                }
                if (item.lookup.target) {
                    Object.entries(item.lookup.target).forEach(
                        ([destination, source], index) => {
                            tmp[destination] = '';
                            if (Array.isArray(source)) {
                                source.forEach((field) => {
                                    tmp[destination] = tmp[destination]
                                        ? tmp[destination] +
                                          `, ${lookupData.data[field]}`
                                        : lookupData.data[field];
                                });
                            }
                        },
                    );
                }
            } catch (error) {}
        } else if (item.slugTarget) {
            tmp[item.slugTarget] = slugify(request[item.name]);
        }
        setRequest(tmp);
        if (updated) {
            updated(tmp);
        }
    };

    const updateValue = (name, value) => {
        const item = searchArray(config.items, 'name', name);
        const tmp = { ...request };
        tmp[name] = value;
        if (item?.same) {
            const [destination, source] = item.same.split('=');
            tmp[destination] = value ? tmp[source] : '';
        }
        setRequest(tmp);
        updateHidden(name, value);
        if (updated) {
            updated(tmp);
        }
    };

    const updateForm = (value, name) => {
        const tmp = { ...request };
        tmp[name] = value;
        setRequest(tmp);
        updateHidden(name, value);
        if (updated) {
            updated(tmp);
        }
    };

    const initRequest = async () => {
        let prefill = false;
        if (data) {
            delete data.status;
        }
        const tmp = flattenForm(config.items, { ...data });
        for (let index = 0; index < config.items.length; index++) {
            const item = config.items[index];
            if (item.if) {
                Object.entries(item.if).forEach(([key, value]) => {
                    if (data[key] !== value) {
                        delete config.items[index];
                    }
                });
            }
            if (item.prefill) {
                prefill = true;
                const payloadValue = getValue(data, item?.prefill?.key);
                if (payloadValue) {
                    const response = await axios.get(
                        buildUrl(item.prefill.url, { value: payloadValue }),
                    );
                    if (Array.isArray(item.prefill.value)) {
                        item.prefill.value.forEach((field) => {
                            const val = getValue(response.data, field);
                            tmp[item.name] = tmp[item.name]
                                ? tmp[item.name] + `, ${val}`
                                : val;
                        });
                    } else {
                        tmp[item.name] = getValue(
                            response.data,
                            item.prefill.value,
                        );
                    }
                }
            }
        }
        initHidden(tmp);
        setRequest(tmp);
        if (prefill && updated) {
            updated(tmp);
        }
    };

    const initHidden = (requestData) => {
        const tmp = [...hidden];
        config.items.forEach((item) => {
            const id = item.id ? item.id : item.name;
            if (item.when) {
                const [key, value] = item.when.split(':');
                const connected = searchArray(config.items, 'name', key);
                if (
                    (data &&
                        value !== undefined &&
                        data[key] !== undefined &&
                        value.toString() === data[key].toString()) ||
                    (requestData &&
                        value !== undefined &&
                        requestData[key] !== undefined &&
                        value.toString() === requestData[key].toString()) ||
                    (connected &&
                        value !== undefined &&
                        connected.value !== undefined &&
                        item.value !== undefined &&
                        value.toString() === connected.value.toString())
                ) {
                    const index = tmp.indexOf(id);
                    if (index !== -1) {
                        tmp.splice(index, 1);
                    }
                } else {
                    tmp.push(id);
                }
            }
        });
        setHidden([...new Set(tmp)]);
    };

    const updateHidden = (name, resValue) => {
        const tmp = [...hidden];
        config.items.forEach((item) => {
            if (item.when) {
                const [key, value] = item.when.split(':');
                const index = tmp.indexOf(item.name);
                if (name === key && value.indexOf('|') !== -1) {
                    const valueChunks = value.split('|');
                    if (valueChunks.indexOf(resValue) !== -1) {
                        tmp.splice(index, 1);
                    } else {
                        tmp.push(item.name);
                    }
                } else if (
                    name === key &&
                    resValue.toString().indexOf(value.toString()) === -1
                ) {
                    tmp.push(item.name);
                } else if (name === key) {
                    if (index !== -1) {
                        tmp.splice(index, 1);
                    }
                }
            }
            if (item.whenNot) {
                const [key, value] = item.whenNot.split(':');
                const index = tmp.indexOf(item.name);
                if (
                    name === key &&
                    resValue.toString().indexOf(value.toString()) !== -1
                ) {
                    tmp.push(item.name);
                } else if (name === key) {
                    if (index !== -1) {
                        tmp.splice(index, 1);
                    }
                }
            }
        });
        setHidden([...new Set(tmp)]);
    };

    const [enabled, setEnabled] = useState([]);

    const onEnabledToggle = (e) => {
        if (e.target.checked) {
            setEnabled([...enabled, e.target.value]);
        } else if (enabled.includes(e.target.value)) {
            setEnabled(enabled.filter((item) => item !== e.target.value));
        }
    };

    useEffect(() => {
        initRequest();
    }, [config, data]);

    const handleVerification = async (item) => {
        const key = item.verify.key || item.name;
        if (item.verify && item.verify.url) {
            const { data } = await axios.request({
                url: item.verify.url,
                method: item.verify.method || 'post',
                data: {
                    [key]: request[item.name],
                },
            });
            const tmp = { ...request };
            Object.entries(item.verify.mapping).forEach(
                ([destination, source]) => {
                    let value = getValue(data, source.from);
                    if (source.slugify) {
                        value = transform('slug', value);
                    }
                    tmp[destination] = value;
                },
            );
            setRequest(tmp);
            verified(item, data);
        }
    };

    return (
        <>
            {config.repeater && (
                <RepeaterForm
                    idPrefix={idPrefix}
                    constants={constants}
                    details={getValue(request, config.as || config.name)}
                    submitted={submitted}
                    updated={updated}
                    config={{
                        title: config.title,
                        url: config.url,
                        class: config.class,
                        name: config.name,
                        as: config.as,
                        items: config.items,
                        titleKey: config.repeater.titleKey,
                        highlights: config.repeater.highlights,
                        btn: config.repeater.btn,
                        method: config.method,
                        save:
                            config.repeater.save === undefined
                                ? true
                                : config.repeater.save,
                        minLength:
                            config.repeater.minLength === undefined
                                ? 1
                                : config.repeater.minLength,
                    }}
                />
            )}
            {!config.repeater && (
                <div className={className}>
                    <div>
                        {config?.items?.map((item, index) => {
                            if (!is_editable) {
                                item.disabled = true;
                            }
                            return hidden.indexOf(item.id || item.name) !==
                                -1 ||
                                (item.nonEmpty &&
                                    (!request || !request[item.name])) ||
                                (item.update === false &&
                                    ['PATCH', 'PUT'].indexOf(config.method) !==
                                        -1) ? (
                                <React.Fragment
                                    key={`form_view_item_${
                                        item.name || item.title
                                    }_${index}`}></React.Fragment>
                            ) : (
                                <React.Fragment
                                    key={`form_view_item_${
                                        item.name || item.title
                                    }_${index}`}>
                                    {item.type === 'heading' && (
                                        <h3 className='mt-20 text-uppercase fw-bold'>
                                            {item.title}
                                        </h3>
                                    )}
                                    {item.type === 'subheading' && (
                                        <h4 className='mt-20 text-muted text-uppercase fw-bold'>
                                            {item.title}
                                        </h4>
                                    )}
                                    {item.type === 'separator' && (
                                        <div className='separator separator-dashed'></div>
                                    )}
                                    {item.type === 'or' && (
                                        <div className='separator separator-dashed separator-content mt-10'>
                                            <span className='h2'>or</span>
                                        </div>
                                    )}
                                    {item.type === 'hidden' && (
                                        <input
                                            type='hidden'
                                            name={item.name}
                                            onChange={handleChange}
                                            value={request?.[item.name] || ''}
                                        />
                                    )}
                                    {[
                                        'heading',
                                        'support',
                                        'separator',
                                        'hidden',
                                        'subheading',
                                    ].indexOf(item.type) === -1 && (
                                        <div
                                            className={`${
                                                item.class || config.class || ''
                                            } ${
                                                item.optional
                                                    ? 'border border-gray-400 border-dashed rounded p-5'
                                                    : ''
                                            }`}>
                                            <div>
                                                {label &&
                                                    item.showLabel !==
                                                        false && (
                                                        <FormViewLabel
                                                            inlineLabel={
                                                                config.inlineLabel
                                                            }
                                                            item={item}
                                                            onEnabledToggle={
                                                                onEnabledToggle
                                                            }
                                                        />
                                                    )}
                                                {(!item.optional ||
                                                    (item.optional &&
                                                        enabled.includes(
                                                            item.name,
                                                        ))) && (
                                                    <div
                                                        className={[
                                                            'd-flex align-items-center justify-content-between',
                                                        ].join(' ')}>
                                                        {item.type ===
                                                            'readonly' && (
                                                            <div className='fs-6 fw-bold'>
                                                                {item.value}
                                                            </div>
                                                        )}
                                                        {(!item.type ||
                                                            [
                                                                'text',
                                                                'password',
                                                                'email',
                                                                'color',
                                                                'date',
                                                                'month',
                                                                'number',
                                                                'range',
                                                                'tel',
                                                                'time',
                                                                'url',
                                                                'week',
                                                            ].indexOf(
                                                                item.type,
                                                            ) !== -1) && (
                                                            <div className='form__item'>
                                                                <input
                                                                    type={
                                                                        item.type ||
                                                                        'text'
                                                                    }
                                                                    readOnly={
                                                                        item.readOnly
                                                                    }
                                                                    disabled={
                                                                        item.disabled
                                                                    }
                                                                    placeholder={
                                                                        item.placeholder ||
                                                                        item.label ||
                                                                        beautify(
                                                                            item.name,
                                                                        )
                                                                    }
                                                                    className='control'
                                                                    name={
                                                                        item.name
                                                                    }
                                                                    onBlur={(
                                                                        e,
                                                                    ) =>
                                                                        handleBlur(
                                                                            e,
                                                                            item,
                                                                        )
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    value={
                                                                        request?.[
                                                                            item
                                                                                .name
                                                                        ] || ''
                                                                    }
                                                                />
                                                                {item.verify && (
                                                                    <button
                                                                        className='btn btn-outline btn-light-info'
                                                                        type='button'
                                                                        onClick={() =>
                                                                            handleVerification(
                                                                                item,
                                                                            )
                                                                        }>
                                                                        {item
                                                                            .verify
                                                                            .label ||
                                                                            'Verify'}
                                                                    </button>
                                                                )}
                                                            </div>
                                                        )}
                                                        {item.type ===
                                                            'amount' && (
                                                            <InputFieldAmount
                                                                config={item}
                                                                defaultValue={
                                                                    request?.[
                                                                        item
                                                                            .name
                                                                    ] || ''
                                                                }
                                                                onChange={
                                                                    updateForm
                                                                }
                                                            />
                                                        )}
                                                        {item.type ===
                                                            'supportText' && (
                                                            <div
                                                                className={`text-${item.class} mb-1 bg-light-${item.class} p-4 w-100`}>
                                                                <i
                                                                    className={`bi bi-info-circle-fill text-${item.class} cursor-pointer me-2`}></i>
                                                                {item.text}
                                                            </div>
                                                        )}
                                                        {item.type ===
                                                            'alert' && (
                                                            <div
                                                                className={`w-100 alert alert-${
                                                                    item.class ||
                                                                    'info'
                                                                }`}
                                                                role='alert'>
                                                                <h4 className='alert-heading'>
                                                                    {
                                                                        item?.heading
                                                                    }
                                                                </h4>
                                                                <p className='m-0'>
                                                                    {item?.text}
                                                                </p>
                                                            </div>
                                                        )}
                                                        {item.type ===
                                                            'rawText' &&
                                                            request?.[
                                                                item?.name
                                                            ] && (
                                                                <small
                                                                    className={`support-text text-${
                                                                        item.className ||
                                                                        'dark'
                                                                    }`}>
                                                                    {request?.[
                                                                        item
                                                                            .name
                                                                    ] || ''}
                                                                </small>
                                                            )}
                                                        {item.type ===
                                                            'datepicker' && (
                                                            <FormControlDatepicker
                                                                item={item}
                                                                value={
                                                                    request?.[
                                                                        item
                                                                            .name
                                                                    ] || ''
                                                                }
                                                                onChange={
                                                                    updateValue
                                                                }
                                                            />
                                                        )}
                                                        {item.type ===
                                                            'timepicker' && (
                                                            <FormControlTimepicker
                                                                item={item}
                                                                value={
                                                                    request?.[
                                                                        item
                                                                            .name
                                                                    ] || ''
                                                                }
                                                                onChange={
                                                                    updateValue
                                                                }
                                                            />
                                                        )}
                                                        {item.type ===
                                                            'daterange' && (
                                                            <FormControlDateRangePicker
                                                                item={item}
                                                                value={
                                                                    request?.[
                                                                        item
                                                                            .name
                                                                    ] || ''
                                                                }
                                                                onChange={
                                                                    updateValue
                                                                }
                                                            />
                                                        )}
                                                        {item.type ===
                                                            'textarea' && (
                                                            <textarea
                                                                className='control w-100'
                                                                name={item.name}
                                                                rows={
                                                                    item.rows ||
                                                                    5
                                                                }
                                                                value={
                                                                    request?.[
                                                                        item
                                                                            .name
                                                                    ] || ''
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }></textarea>
                                                        )}
                                                        {item.type ===
                                                            'json' && (
                                                            <textarea
                                                                className='control w-100'
                                                                name={item.name}
                                                                rows={
                                                                    item.rows ||
                                                                    5
                                                                }
                                                                value={
                                                                    request?.[
                                                                        item
                                                                            .name
                                                                    ] || '{}'
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }></textarea>
                                                        )}
                                                        {item.type ===
                                                            'legacy_select' && (
                                                            <InputFieldSelect
                                                                config={item}
                                                                value={
                                                                    request?.[
                                                                        item
                                                                            .name
                                                                    ] || ''
                                                                }
                                                                onChange={(
                                                                    val,
                                                                ) =>
                                                                    updateValue(
                                                                        item.name,
                                                                        val,
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                        {item.type ===
                                                            'select' && (
                                                            <FormControlSelect
                                                                constants={
                                                                    constants
                                                                }
                                                                item={item}
                                                                request={
                                                                    request
                                                                }
                                                                value={
                                                                    request?.[
                                                                        item
                                                                            .name
                                                                    ] || ''
                                                                }
                                                                onSelectChange={
                                                                    handleSelectChange
                                                                }
                                                            />
                                                        )}
                                                        {item.type ===
                                                            'chips' && (
                                                            <FormControlChips
                                                                item={item}
                                                                value={
                                                                    request?.[
                                                                        item
                                                                            .name
                                                                    ] || ''
                                                                }
                                                                onChipsChange={
                                                                    handleSelectChange
                                                                }
                                                            />
                                                        )}
                                                        {item.type ===
                                                            'group' && (
                                                            <FormControlGroup
                                                                config={item}
                                                                data={request}
                                                            />
                                                        )}
                                                        {item.type ===
                                                            'location' && (
                                                            <CurrentLocation />
                                                        )}
                                                        {item.type ===
                                                            'switch' && (
                                                            <FormControlSwitch
                                                                item={item}
                                                                value={
                                                                    request?.[
                                                                        item
                                                                            .name
                                                                    ] || false
                                                                }
                                                                onChange={
                                                                    updateValue
                                                                }
                                                            />
                                                        )}
                                                        {item.type ===
                                                            'multirange' && (
                                                            <FormControlMultiRange
                                                                value={
                                                                    request?.[
                                                                        item
                                                                            .name
                                                                    ] || ''
                                                                }
                                                                item={item}
                                                                onChange={
                                                                    updateValue
                                                                }
                                                            />
                                                        )}
                                                        {item.type ===
                                                            'file' && (
                                                            <FormControlFile
                                                                item={item}
                                                                onFileSelect={
                                                                    handleFileSelect
                                                                }
                                                            />
                                                        )}
                                                        {item.type ===
                                                            'table' && (
                                                            <FormControlTable
                                                                constants={
                                                                    constants
                                                                }
                                                                item={item}
                                                                value={
                                                                    request &&
                                                                    request[
                                                                        item
                                                                            .name
                                                                    ] &&
                                                                    Array.isArray(
                                                                        request[
                                                                            item
                                                                                .name
                                                                        ],
                                                                    )
                                                                        ? request[
                                                                              item
                                                                                  .name
                                                                          ]
                                                                        : []
                                                                }
                                                                onChange={
                                                                    updateValue
                                                                }
                                                            />
                                                        )}
                                                        {item.type ===
                                                            'radiolist' && (
                                                            <FormControlRadioList
                                                                item={item}
                                                                value={
                                                                    request?.[
                                                                        item
                                                                            .name
                                                                    ] || []
                                                                }
                                                                onChange={(
                                                                    val,
                                                                ) =>
                                                                    updateValue(
                                                                        item.name,
                                                                        val,
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                        {item.type ===
                                                            'radio' && (
                                                            <FormControlRadio
                                                                item={item}
                                                                value={
                                                                    request?.[
                                                                        item
                                                                            .name
                                                                    ] || ''
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />
                                                        )}
                                                        {item.type ===
                                                            'notifications' && (
                                                            <FormControlSwitchTable
                                                                item={item}
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {item.support && (
                                        <div className='text-info mb-4 bg-light p-4'>
                                            <i className='bi bi-info-circle-fill text-info cursor-pointer me-2'></i>
                                            {item.support}
                                        </div>
                                    )}
                                    {item.sample && (
                                        <div className='text-info'>
                                            <i className='bi bi-info-circle-fill text-info cursor-pointer me-2'></i>
                                            <a
                                                rel='noreferrer'
                                                target='_blank'
                                                href={item.sample}>
                                                Download Sample File
                                            </a>
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

export default FormView;
