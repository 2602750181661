import React from 'react';
import DetailView from '../../../components/organisms/DetailView';

const LoanPaidBreakup = ({ summary, className }) => {
    return (
        <DetailView
            className={className || ''}
            details={summary}
            config={{
                items: [
                    {
                        name: 'principal_paid',
                        transform: 'amount',
                    },
                    {
                        name: 'interest_paid',
                        transform: 'amount',
                    },
                    {
                        name: 'overdue_paid',
                        transform: 'amount',
                    },
                    {
                        name: 'penal_paid',
                        transform: 'amount',
                    },
                ],
            }}
        />
    );
};

export default LoanPaidBreakup;
