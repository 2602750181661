import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from '../../../context/UserContext';
import { useEffect, useState } from 'react';
import PageHeader from '../../../components/molecules/PageHeader';
import FormWrapper from '../../../components/organisms/FormView/FormWrapper';
import { urls } from '../../../services/_crm.service';

const GstAnalyticsValidate = () => {
    const navigate = useNavigate();
    const [request, setRequest] = useState({});
    const [searchParams] = useSearchParams();

    useEffect(() => {}, []);

    const handleSubmitted = (response) => {
        if (response?.data?.status) {
            navigate(
                '/borrower/gst-analytics?request_id=' +
                    searchParams.get('request_id'),
            );
        }
    };

    const handleUpdated = (data) => {
        console.log(data);
        setRequest(data);
    };

    return (
        <div
            className='container init'
            style={{ marginTop: -100 }}>
            <PageHeader
                className='p-0 pb-20'
                subtitle='Enter the OTP to fetch your GST data'></PageHeader>
            <FormWrapper
                submitted={handleSubmitted}
                config={{
                    url: urls.gst_analytics_validate,
                    btn: 'Validate OTP',
                    items: [
                        {
                            type: 'hidden',
                            name: 'request_id',
                            value: searchParams.get('request_id'),
                        },
                        { name: 'otp', showLabel: false },
                    ],
                }}
                updated={handleUpdated}
                data={request}
            />
        </div>
    );
};
export default GstAnalyticsValidate;
