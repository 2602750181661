import React from 'react';
import { getValue } from '../../helpers/json';
import { transform } from '../../helpers/transform';
import { CLASSES } from '../../consts/classes';
import { Link } from 'react-router-dom';

const ListItem = ({ item, details }) => {
    const value = getValue(details, item.name);
    let formattedValue = value;
    if (item.transform) {
        formattedValue = transform(item.transform, formattedValue);
    }
    return (
        <>
            {item.type === 'heading' && (
                <div
                    className={`list__item__content__heading ${item.className}`}>
                    {formattedValue}
                </div>
            )}
            {(!item.type || item.type === 'text') && (
                <div
                    className={`list__item__content__text color-${CLASSES[value]} ${item.className}`}>
                    {value && item.prefix && <>{item.prefix} </>}
                    {formattedValue}
                </div>
            )}
            {item.type === 'link' && (
                <Link
                    target='_blank'
                    to={value}
                    className={`list__item__content__text color-yellow`}>
                    {item.label}
                </Link>
            )}
            {item.type === 'items' && (
                <div className='list__item__row'>
                    {item?.items.map((row, index) => {
                        return (
                            <React.Fragment key={row.name}>
                                <ListItem
                                    details={details}
                                    item={row}
                                />
                                {index < item.items.length - 1 && (
                                    <span className='mx-5'>&#11825;</span>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default ListItem;
