import React, { useEffect, useState } from 'react';
import { beautify } from '../../helpers/str';

const InputText = ({ config, onChange, defaultValue }) => {
    const [value, setValue] = useState(defaultValue || '');

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleChange = (e) => {
        const val = e.target.value;
        setValue(val);
        if (onChange) {
            onChange(val, e.target.name);
        }
    };

    return (
        <input
            type={config.type || 'text'}
            autoComplete={config.autoComplete}
            autoFocus={config.autoFocus}
            readOnly={config.readOnly}
            placeholder={config.placeholder || beautify(config.name)}
            className={`control ${config.className || ''}`}
            name={config.name}
            onChange={handleChange}
            value={value || ''}
            inputMode={config.type === 'number' ? 'decimal' : undefined}
        />
    );
};

export default InputText;
