export const root = '/c';
export const BASE_URL = process.env.REACT_APP_API_ROOT_DEFAULT + root + '/';

export const routes = {
    home: root + '/',

    leads: root + '/leads',
    lead_details: root + '/leads/:id',
};

export const api = {
    leads: BASE_URL + 'leads',
};
