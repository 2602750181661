import React from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from '../../../components/molecules/PageHeader';
import ListView from '../../../components/organisms/ListView';
import { useConstants } from '../../../context/ConstantsContext';
import { buildUrl } from '../../../helpers/str';
import { urls as accountingUrls } from '../../../services/_accounting.service';
import { useUser } from '../../../context/UserContext';

const RecordsList = ({ filterBy }) => {
    const { id } = useParams();
    const { user } = useUser();
    const { constants } = useConstants();

    return (
        <>
            <PageHeader>Repayments</PageHeader>
            <ListView
                config={{
                    api: buildUrl(accountingUrls.records, {
                        lead_id: user.lead_id,
                        program_id: user.program_id,
                    }),
                    maxItems: 2,
                    items: [
                        {
                            type: 'heading',
                            name: 'amount',
                            transform: 'amount',
                        },
                        {
                            type: 'items',
                            name: 'status_settled_at',
                            items: [
                                {
                                    name: 'ref_id',
                                },
                                {
                                    name: 'utr',
                                    prefix: 'UTR: ',
                                },
                                {
                                    name: 'settled_at',
                                    transform: 'dateFromEpoch',
                                },
                                {
                                    name: 'status',
                                    transform: 'beautify',
                                },
                            ],
                        },
                    ],
                }}
                filter={{ [filterBy]: id }}
            />
        </>
    );
};

export default RecordsList;
