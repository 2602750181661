import React, { useEffect } from 'react';
import DetailView from '../../../components/organisms/DetailView';

const LoanOutstandingBreakup = ({ summary, className = '' }) => {
    useEffect(() => {
        console.log(summary);
    }, [summary]);
    return (
        <DetailView
            className={className}
            details={summary}
            config={{
                items: [
                    {
                        name: 'total_outstanding',
                        transform: 'amount',
                    },
                    {
                        name: 'principal_outstanding',
                        transform: 'amount',
                    },
                    {
                        name: 'interest_outstanding',
                        transform: 'amount',
                    },
                    {
                        name: 'overdue_outstanding',
                        transform: 'amount',
                    },
                    {
                        name: 'penal_outstanding',
                        transform: 'amount',
                    },
                ],
            }}
        />
    );
};

export default LoanOutstandingBreakup;
