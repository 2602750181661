import React, { useEffect } from 'react';
import DetailView from '../../../components/organisms/DetailView';
import KycSummaryAddress from './KycSummaryAddress';

const GstKycData = ({ gst_detailed }) => {
    useEffect(() => {}, [gst_detailed]);
    return (
        <>
            {gst_detailed && (
                <>
                    <h5 className='mb-10'>Business Details</h5>
                    <DetailView
                        details={gst_detailed}
                        config={{
                            items: [
                                {
                                    name: 'tradeNameOfBusiness',
                                    label: 'Name',
                                },
                                {
                                    name: 'constitutionOfBusiness',
                                    label: 'Business Type',
                                },
                                {
                                    name: 'registrationDate',
                                    label: 'Date of Incorporation',
                                    transform: 'date',
                                },
                            ],
                        }}
                    />
                </>
            )}
            <div className='my-10'>
                <KycSummaryAddress
                    address={gst_detailed?.principalPlaceAddress}
                />
            </div>
        </>
    );
};

export default GstKycData;
