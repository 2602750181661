import React from 'react';
import PageHeader from '../../components/molecules/PageHeader';
import { useUser } from '../../context/UserContext';
import { firstName } from '../../helpers/str';

const CompanyDashboard = () => {
    const { user } = useUser();
    return (
        <div className='container'>
            <PageHeader
                className='p-0 pb-10'
                subtitle='Manage your borrowers here'>
                Welcome {firstName(user.name)},
            </PageHeader>
            <div className='stats row'>
                <div className='stats__item col'>
                    <div className='bg-blue stats__item__inner'>
                        <div className='flex justify-between'>
                            <span className='stats__item__inner__icon'>
                                <i className='ri ri-group-fill color-blue'></i>
                            </span>
                            <span className='stats__item__inner__number'>
                                323
                            </span>
                        </div>
                        <div className='stats__item__inner__label'>
                            All Leads
                        </div>
                    </div>
                </div>
                <div className='stats__item col'>
                    <div className='bg-red stats__item__inner'>
                        <div className='flex justify-between'>
                            <span className='stats__item__inner__icon'>
                                <i className='ri ri-group-fill color-red'></i>
                            </span>
                            <span className='stats__item__inner__number'>
                                11
                            </span>
                        </div>
                        <div className='stats__item__inner__label'>
                            KYC Pending
                        </div>
                    </div>
                </div>
            </div>
            <div className='stats row'>
                <div className='stats__item col'>
                    <div className='bg-yellow-dark stats__item__inner'>
                        <div className='flex justify-between'>
                            <span className='stats__item__inner__icon'>
                                <i className='ri ri-group-fill color-yellow-dark'></i>
                            </span>
                            <span className='stats__item__inner__number'>
                                11
                            </span>
                        </div>
                        <div className='stats__item__inner__label'>
                            Under Review
                        </div>
                    </div>
                </div>
                <div className='stats__item col'>
                    <div className='bg-red-dark stats__item__inner'>
                        <div className='flex justify-between'>
                            <span className='stats__item__inner__icon'>
                                <i className='ri ri-group-fill color-red'></i>
                            </span>
                            <span className='stats__item__inner__number'>
                                11
                            </span>
                        </div>
                        <div className='stats__item__inner__label'>
                            Activation
                        </div>
                    </div>
                </div>
            </div>
            <div className='stats row'>
                <div className='stats__item col'>
                    <div className='bg-green stats__item__inner'>
                        <div className='flex justify-between'>
                            <span className='stats__item__inner__icon'>
                                <i className='ri ri-group-fill color-green'></i>
                            </span>
                            <span className='stats__item__inner__number'>
                                11
                            </span>
                        </div>
                        <div className='stats__item__inner__label'>
                            Disbursed
                        </div>
                    </div>
                </div>
                <div className='col'></div>
            </div>
        </div>
    );
};

export default CompanyDashboard;
