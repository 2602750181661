import React from 'react';
import { beautify } from '../../helpers/str';

const JsonList = ({ data }) => {
    return (
        <ul>
            {Object.entries(data).map(([key, value], cellIndex) => {
                return (
                    <React.Fragment key={key}>
                        {value !== undefined && (
                            <li>
                                <strong>{beautify(key)}</strong>
                                <span className='me-1'>:</span>
                                {typeof value === 'object' ? (
                                    <JsonList data={value} />
                                ) : (
                                    <>{value}</>
                                )}
                            </li>
                        )}
                    </React.Fragment>
                );
            })}
        </ul>
    );
};

export default JsonList;
