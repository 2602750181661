import React, { useEffect, useState } from 'react';
import { beautify } from '../../helpers/str';
import { selectOptions } from '../../helpers/form';
import { searchArray } from '../../helpers/json';

const InputSelect = ({ config, onChange, defaultValue }) => {
    const [value, setValue] = useState(defaultValue || '');
    const [options, setOptions] = useState([]);

    const initOptions = async () => {
        const tmpOptions = await selectOptions(config.options);
        setOptions(tmpOptions);
        const option = searchArray(tmpOptions, 'value', defaultValue);
        updateValue(option ? option.value : '');
    };

    useEffect(() => {
        setValue(defaultValue);
        initOptions();
    }, [defaultValue]);

    const handleChange = (e) => {
        updateValue(e.target.value);
    };

    const updateValue = (val) => {
        setValue(val);
        if (onChange) {
            onChange(val, config.name);
        }
    };

    return (
        <select
            name={config.name}
            autoFocus={config.autoFocus}
            className={`select ${config.className || ''}`}
            readOnly={config.readOnly}
            onChange={handleChange}
            value={value}>
            <option value=''>{config.placeholder}</option>
            {options?.map((option) => {
                return (
                    <option
                        value={option.value}
                        key={option.value}>
                        {option.name ? option.name : beautify(option.value)}
                    </option>
                );
            })}
        </select>
    );
};

export default InputSelect;
