import axios from 'axios';
import { alertError } from '../helpers/alert';
import { searchArray } from '../helpers/json';
import { beautify, buildUrl } from '../helpers/str';
import { resizeFile } from '../helpers/file';

export const BASE_URL =
    (process.env.REACT_APP_API_ROOT_CRM ||
        process.env.REACT_APP_API_ROOT_DEFAULT) + '/crm/';

export const urls = {
    onboarding_leads: BASE_URL + 'onboarding/leads/',
    onboarding_gst_directors: BASE_URL + 'onboarding/leads/:lead_id/directors',
    onboarding_leads_verify: BASE_URL + 'onboarding/leads/verify/',
    onboarding_ckyc_profile: BASE_URL + 'onboarding/leads/ckyc_profile/',
    onboarding_search_gst: BASE_URL + 'onboarding/leads/fetch_gst/',
    onboarding_ckyc_images_upload:
        BASE_URL + 'onboarding/leads/ckyc_images_upload/',

    leads: BASE_URL + 'leads/',
    lead_details: BASE_URL + 'leads/:id/',
    lead_validate: BASE_URL + 'leads/:id/validate/',
    line_details: BASE_URL + 'line_details/',

    applicants: BASE_URL + 'applicants/',
    applicant_details: BASE_URL + 'applicants/:id/',
    applicant_gst_analytics: BASE_URL + 'applicants/:id/gst-analytics/',
    applicant_itr: BASE_URL + 'applicants/:id/itr/',

    lead_program_accounts:
        BASE_URL + 'leads/:id/programs/:program_id/service_accounts/',

    addresses: BASE_URL + 'addresses/',
    address_details: BASE_URL + 'addresses/:id/',

    documents: BASE_URL + 'documents/',
    document_details: BASE_URL + 'documents/:id/',

    banks: BASE_URL + 'banks/',
    bank_details: BASE_URL + 'banks/:id/',

    lookup_pincode: BASE_URL + 'addresses/pincode/:value',
    lookup_ifsc: BASE_URL + 'banks/ifsc/:value',

    gst_analytics_init: BASE_URL + '/vendors/gst-analytics/initiate',
    gst_analytics_validate: BASE_URL + '/vendors/gst-analytics/validate',
    gst_analytics_data: BASE_URL + '/vendors/gst-analytics/:id',

    itr_analytics_intiate: BASE_URL + '/vendors/itr/initiate',
    itr_analytics_data: BASE_URL + '/vendors/itr/:id',
};

export const initLead = async (payload) => {
    const { data } = await axios.post(urls.onboarding_leads_verify, payload);
    return data;
};

export const getLead = async (id) => {
    const { data } = await axios.get(buildUrl(urls.lead_details, { id }));
    data.person_entity = searchArray(
        data.applicants,
        'is_authorized_signatory',
        true,
    );
    data.business_entity = searchArray(
        data.applicants,
        'applicant_type',
        'business',
    );
    return data;
};

export const searchGst = async (pan_no, state) => {
    const { data } = await axios.post(urls.onboarding_search_gst, {
        pan_no,
        state,
    });
    return data;
};

export const getCkycProfile = async (name, mobile) => {
    const { data } = await axios.post(urls.onboarding_ckyc_profile, {
        name,
        mobile,
    });
    return data;
};

export const getGstAnalyticsData = async (id) => {
    const { data } = await axios.get(
        buildUrl(urls.gst_analytics_data, {
            id,
        }),
    );
    return data;
};

export const getItrAnalyticsData = async (id) => {
    const { data } = await axios.get(
        buildUrl(urls.itr_analytics_data, {
            id,
        }),
    );
    return data;
};

export const uploadCkycImages = async (payload) => {
    const { data } = await axios.post(
        urls.onboarding_ckyc_images_upload,
        payload,
    );
    return data;
};

export const createLead = async (payload) => {
    const { data } = await axios.post(urls.onboarding_leads, payload);
    return data;
};

export const createApplicant = async (payload) => {
    const { data } = await axios.post(urls.applicants, payload);
    return data;
};

export const updateApplicant = async (id, payload) => {
    const { data } = await axios.patch(
        buildUrl(urls.applicant_details, { id }),
        payload,
    );
    return data;
};

export const applicantGstAnalytics = async (id) => {
    const { data } = await axios.get(
        buildUrl(urls.applicant_gst_analytics, { id }),
    );
    return data;
};

export const applicantItr = async (id) => {
    const { data } = await axios.get(buildUrl(urls.applicant_itr, { id }));
    return data;
};

export const getServiceAccounts = async (id, programId) => {
    const { data } = await axios.get(
        buildUrl(urls.lead_program_accounts, { id, program_id: programId }),
    );
    return data;
};

export const listAddresses = async (applicant_id) => {
    const { data } = await axios.get(
        buildUrl(urls.addresses, { applicant_id, 'no-pagination': true }),
    );
    return data;
};

export const listGstDirectors = async (lead_id) => {
    const { data } = await axios.get(
        buildUrl(urls.onboarding_gst_directors, { lead_id }),
    );
    return data;
};

export const createAddress = async (payload) => {
    const { data } = await axios.post(urls.addresses, payload);
    return data;
};

export const listBanks = async (applicant_id) => {
    const { data } = await axios.get(
        buildUrl(urls.banks, { applicant_id, 'no-pagination': true }),
    );
    return data;
};

export const listDocuments = async (
    applicant_id,
    related_model_name = 'applicant',
) => {
    const { data } = await axios.get(
        buildUrl(urls.documents, {
            related_model_id: applicant_id,
            related_model_name,
            'no-pagination': true,
        }),
    );
    return data;
};

export const uploadDocument = async (file, obj) => {
    if (file?.type?.indexOf('image') !== -1 && file?.size >= 200000) {
        file = await resizeFile(file);
    }
    const payload = new FormData();
    payload.append('files', file);
    Object.entries(obj).forEach(([key, value]) => {
        payload.append(key, value);
    });
    const { data } = await axios.post(urls.documents, payload);
    return data;
};

export const updateDocument = async (id, file) => {
    console.log(id, file);
    if (file?.type?.indexOf('image') !== -1 && file?.size >= 200000) {
        file = await resizeFile(file);
    }
    const payload = new FormData();
    payload.append('files', file);
    const { data } = await axios.patch(
        buildUrl(urls.document_details, { id }),
        payload,
    );
    return data;
};

export const deleteDocument = async (id) => {
    const { data } = await axios.delete(
        buildUrl(urls.document_details, { id }),
    );
    return data;
};

export const validateLead = async (lead) => {
    try {
        const { data } = await axios.put(
            buildUrl(urls.lead_validate, { id: lead?.id, silent: true }),
        );
        return data;
    } catch (error) {
        const desc = error?.response?.data?.description;
        if (desc && desc?.[0]) {
            const applicant = searchArray(
                lead.applicants,
                'id',
                desc[0].applicant_id,
            );
            alertError(
                `Please fill ${beautify(desc[0].missing_fields.join(', '))}`,
                `Information Missing for ${applicant.name}`,
            );
        } else {
            alertError(error?.response?.data?.message);
        }
        return Promise.reject(error);
    }
};

export const lineDetails = async (leadId, params) => {
    const { data } = await axios.get(
        buildUrl(urls.line_details, {
            lead_id: leadId,
            ...params,
        }),
    );
    return data;
};
