import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../components/molecules/PageHeader';
import FormWrapper from '../../../components/organisms/FormView/FormWrapper';
import { useUser } from '../../../context/UserContext';
import { getLead, urls } from '../../../services/_crm.service';
import { transform } from '../../../helpers/transform';

const GstAnalyticsInit = () => {
    const { user } = useUser();
    const navigate = useNavigate();
    const [request, setRequest] = useState({});
    const [lead, setLead] = useState({});

    useEffect(() => {
        initLead();
    }, []);

    const initLead = async () => {
        if (user.lead_id) {
            const response = await getLead(user.lead_id);
            setLead(response);
        }
    };

    const handleSubmitted = (response) => {
        if (request.mode === 'otp' && response?.data?.result?.request_id) {
            navigate(
                '/borrower/gst-analytics/validate?request_id=' +
                    response?.data?.result?.request_id,
            );
        } else if (response?.data?.result?.request_id) {
            navigate(
                '/borrower/gst-analytics?request_id=' +
                    response?.data?.result?.request_id,
            );
        }
    };

    const handleUpdated = (data) => {
        setRequest(data);
    };

    return (
        <div
            className='container init'
            style={{ marginTop: -100 }}>
            <PageHeader
                className='p-0 pb-20'
                subtitle='Enter your GST Credentials to fetch your GST data'></PageHeader>
            <FormWrapper
                submitted={handleSubmitted}
                config={{
                    url: urls.gst_analytics_init,
                    btn: 'Fetch GST Data',
                    items: [
                        {
                            name: 'gstin',
                            value: lead?.business_entity?.gstin,
                            disabled: lead?.business_entity?.gstin,
                        },
                        { name: 'username' },
                        {
                            name: 'mode',
                            type: 'radiolist',
                            label: 'Login Using',
                            options: transform('options', ['password', 'otp']),
                        },
                        { name: 'password', when: 'mode:password' },
                    ],
                }}
                updated={handleUpdated}
                data={request}
            />
        </div>
    );
};
export default GstAnalyticsInit;
