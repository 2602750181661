export const root = '/borrower';
export const BASE_URL = process.env.REACT_APP_API_ROOT_DEFAULT + root + '/';

export const routes = {
    home: root + '/',

    kyc: root + '/kyc',
    kyc_validate: root + '/validate',

    topups: root + '/topups',
    topup_details: root + '/topups/:id',

    loan_details: root + '/loans/:id',

    records: root + '/records',
    record_detalis: root + '/records/:id',
    record_add: root + '/records/new',
    record_payment: root + '/records/payment',

    account_programs: root + '/account/programs',
    account_summary: root + '/account/summary',

    agreements: root + '/agreements',
};

export const api = {
    accounts: BASE_URL + 'accounts',
    account_details: BASE_URL + 'accounts/:id',

    banks: BASE_URL + 'banks',

    constants: BASE_URL + 'constants',

    dashboard: BASE_URL + 'dashboard',

    transactions: BASE_URL + 'transactions',
    transaction_details: BASE_URL + 'transactions/:id',
};
