import React, { useEffect, useState } from 'react';
import Loader from '../../components/atoms/Loader';
import DetailView from '../../components/organisms/DetailView';
import FormView from '../../components/organisms/FormView';
import { useUser } from '../../context/UserContext';
import { alertError } from '../../helpers/alert';
import { createAddress, updateApplicant } from '../../services/_crm.service';
import KycFormAddress from '../Kyc/KycForm/KycFormAddress';

const OnboardingPerson = ({ lead, onNext, onRefresh }) => {
    const { user } = useUser();
    const [personConfig, setPersonConfig] = useState({});
    const [loading, setLoading] = useState(false);
    const [person, setPerson] = useState({});
    const [address, setAddress] = useState({});

    const initLead = async () => {
        setPerson(lead.person_entity);
        setPersonConfig(user?.program_config?.borrower?.person);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!person.pan_no && !lead?.person_entity?.pan_no) {
            return alertError('Please enter PAN Number');
        }
        setLoading(true);
        await savePerson();
        if (address?.pincode) {
            await saveAddress();
        }
        setLoading(false);
        onNext();
    };

    const savePerson = async () => {
        await updateApplicant(lead?.person_entity?.id, person);
    };

    const saveAddress = async () => {
        await createAddress(address);
    };

    useEffect(() => {
        if (lead?.person_entity?.id) {
            initLead();
        }
    }, [lead]);

    return (
        <form
            className='container'
            onSubmit={handleSubmit}>
            {loading && <Loader loading={loading} />}
            {lead?.person_entity?.name && lead?.person_entity?.source && (
                <>
                    <h3>{lead?.person_entity?.name}</h3>
                    <p className='mb-10'>
                        Verify your information to continue KYC
                    </p>
                    <DetailView
                        details={lead}
                        config={{
                            items: [
                                {
                                    name: 'person_entity.name',
                                    label: 'Name',
                                },
                                {
                                    name: 'person_entity.father_name',
                                    label: "Father's Name",
                                },
                                {
                                    name: 'person_entity.dob',
                                    transform: 'date',
                                },
                                {
                                    name: 'person_entity.primary_email',
                                },
                                {
                                    name: 'person_entity.pan_no',
                                },
                            ],
                        }}
                    />
                </>
            )}
            {lead?.person_entity?.id && (
                <FormView
                    updated={setPerson}
                    data={person}
                    config={{
                        items: [
                            ...(!lead?.person_entity?.source ||
                            !lead?.person_entity?.name
                                ? [
                                      {
                                          type: 'text',
                                          name: 'name',
                                          is_required: true,
                                          value: lead?.person_entity?.name,
                                      },
                                  ]
                                : []),
                            ...(!lead?.person_entity?.source ||
                            !lead?.person_entity?.pan_no
                                ? [
                                      {
                                          type: 'text',
                                          name: 'pan_no',
                                          is_required: true,
                                      },
                                  ]
                                : []),
                            ...(!lead?.person_entity?.source ||
                            !lead?.person_entity?.dob
                                ? [
                                      {
                                          type: 'datepicker',
                                          name: 'dob',
                                      },
                                  ]
                                : []),
                            {
                                type: 'text',
                                name: 'primary_email',
                                value: lead?.person_entity?.primary_email,
                            },
                            ...(personConfig?.extra_data
                                ? personConfig?.extra_data?.map((extra) => {
                                      extra.name = extra.key;
                                      return extra;
                                  })
                                : []),
                        ],
                    }}
                    submitted={handleSubmit}
                />
            )}
            {lead?.person_entity?.id && (
                <>
                    {/* <div className='mt-20'>
                        <OnboardingDocs
                            onRefresh={onRefresh}
                            required={['pan', 'aadhaar']}
                            onUpdated={onDocsUpdated}
                            doc_type='person_document_names'
                            className='mt-20'
                            applicant={lead?.person_entity}
                        />
                    </div> */}
                    <div className='mt-20'>
                        <h4 className='mt-20'>Address</h4>
                        <KycFormAddress
                            applicant={lead?.person_entity}
                            onUpdate={(response) => setAddress(response)}
                        />
                    </div>
                </>
            )}
            <button className='btn btn-fixed'>Save & Proceed</button>
        </form>
    );
};

export default OnboardingPerson;
