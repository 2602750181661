import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { applicantItr } from '../../../services/_crm.service';

const ItrAnalyticsSummary = ({ applicant_id }) => {
    const [exists, setExists] = useState(true);
    const [running, setRunning] = useState(false);

    const initItrAnalytics = async (id) => {
        const response = await applicantItr(id);
        setExists(response?.vendor_key === 'ITR_FORM');
        setRunning(response?.data?.result?.request_id);
    };

    useEffect(() => {
        if (applicant_id) {
            initItrAnalytics(applicant_id);
        }
    }, [applicant_id]);
    return (
        <>
            {!exists && (
                <div className='card mt-20 bg-green-dark p-20'>
                    <h4>
                        <span className='ml-5 color-green'>ITR Data</span>
                    </h4>
                    <small>Some text as description...</small>
                    {running ? (
                        <Link
                            className='btn bg-green color-green-dark mt-10'
                            to={`/borrower/itr-analytics?request_id=${running}`}>
                            In Progress, Check Status!
                        </Link>
                    ) : (
                        <Link
                            className='btn bg-green color-green-dark mt-10'
                            to='/borrower/itr-analytics/initiate'>
                            Pull ITR
                        </Link>
                    )}
                </div>
            )}
        </>
    );
};
export default ItrAnalyticsSummary;
