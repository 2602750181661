import React, { useState } from 'react';
import FormControlSelect from './FormControlSelect';

const FormControlGroup = (props) => {
    const { config } = props;
    const [request, setRequest] = useState({});
    const handleChange = (e) => {
        updateValue(e.target.name, e.target.value);
    };

    const updateValue = (name, value) => {
        setRequest({ ...request, [name]: value });
    };

    const handleSelectChange = (val) => {};

    return (
        <>
            {
                <div className='form__item'>
                    {config.items &&
                        config.items.map((item, index) => {
                            return (
                                <React.Fragment key={`item_${item.name}`}>
                                    {(!item.type ||
                                        [
                                            'text',
                                            'password',
                                            'email',
                                            'color',
                                            'date',
                                            'month',
                                            'number',
                                            'range',
                                            'tel',
                                            'time',
                                            'url',
                                            'week',
                                        ].indexOf(item.type) !== -1) && (
                                        <input
                                            type={item.type || 'text'}
                                            readOnly={item.readOnly}
                                            placeholder={item.placeholder}
                                            className='control'
                                            name={item.name}
                                            onChange={handleChange}
                                            value={
                                                (request &&
                                                    request[item.name]) ||
                                                ''
                                            }
                                        />
                                    )}
                                    {item.type === 'select' && (
                                        <FormControlSelect
                                            className='w-150px'
                                            theme={{ borderRadius: 0 }}
                                            item={item}
                                            value={
                                                (request &&
                                                    request[item.name]) ||
                                                ''
                                            }
                                            onSelectChange={handleSelectChange}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                </div>
            }
        </>
    );
};

export default FormControlGroup;
