import React from 'react';
import { transform } from '../../../helpers/transform';

const FormViewLabel = (props) => {
    const { item, onEnabledToggle, inlineLabel } = props;
    return (
        <>
            {((item.type !== 'switch' && item.type !== 'hidden') ||
                item.showLabel === true) && (
                <span className={inlineLabel ? 'col-md-4' : 'col-md-12'}>
                    {item.optional && (
                        <div className='form-check form-check-custom form-check-solid'>
                            <input
                                className='cursor-pointer form-check-input'
                                type='checkbox'
                                value={item.name}
                                onChange={onEnabledToggle}
                            />
                            <label
                                className={`form-check-label ${
                                    item.is_required ? 'required' : ''
                                }`}>
                                {item.label || transform('beautify', item.name)}
                            </label>
                        </div>
                    )}
                    {!item.optional && (
                        <label
                            className={`${
                                item.is_required ? 'required' : ''
                            } col-form-label form__label me-2`}>
                            {item.label || transform('beautify', item.name)}
                        </label>
                    )}
                    {item.tooltip && (
                        <i className='bi bi-info-circle-fill cursor-pointer'></i>
                    )}
                </span>
            )}
        </>
    );
};

export default FormViewLabel;
