import React from 'react';
import { Link } from 'react-router-dom';

const Stepper = ({ className, items, active }) => {
    return (
        <div className={`stepper ${className}`}>
            <div className='stepper__line'></div>
            {items.map((item, index) => {
                return (
                    <Link
                        to={index < active ? item.path : '#'}
                        className={`stepper__item ${
                            active === index ? 'stepper__item-active' : ''
                        } ${active > index ? 'stepper__item-done' : ''}`}
                        key={item.name}>
                        <div className='stepper__item__icon'>
                            {active > index ? (
                                <i className='ri-check-line'></i>
                            ) : (
                                <>{index + 1}</>
                            )}
                        </div>
                        <div className='stepper__item__name'>{item.name}</div>
                    </Link>
                );
            })}
        </div>
    );
};

export default Stepper;
