import React, { useEffect } from 'react';

const AccordionItem = ({
    title,
    index,
    showIndex,
    activeIndex,
    children,
    onClick,
}) => {
    const handleClick = (index) => {
        if (onClick) {
            onClick(index);
        }
    };
    useEffect(() => {}, [title, index, showIndex, activeIndex]);
    return (
        <div
            key={index}
            className={`accordion__item ${
                activeIndex === index ? 'active' : ''
            }`}
            onClick={() => handleClick(index)}>
            <div className='accordion__item__header'>
                {showIndex && (
                    <span className='accordion__item__header__index'>
                        {index + 1}
                    </span>
                )}
                {title}
            </div>
            {activeIndex === index && (
                <div className='accordion__item__content'>{children}</div>
            )}
        </div>
    );
};

export default AccordionItem;
