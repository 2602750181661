import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Icon from '../atoms/Icon';
import { useUser } from '../../context/UserContext';

const NavBar = ({ config }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { type, user } = useUser();
    const [isAddMenuShown, setIsAddMenuShown] = useState(false);
    useEffect(() => {
        setIsAddMenuShown(false);
    }, [navigate]);
    return (
        <div className='navbar'>
            {(type === 'company' || user?.service_accounts?.lms?.id) && (
                <>
                    <ul
                        className={`navbar__flymenu ${
                            isAddMenuShown ? 'navbar__flymenu-visible' : ''
                        }`}>
                        {user?.service_accounts?.lms?.id && (
                            <li className='navbar__flymenu__item'>
                                <NavLink
                                    onClick={() => setIsAddMenuShown(false)}
                                    to={`${config.root}/topups/new`}
                                    className='navbar__flymenu__item__link bg-yellow color-black'>
                                    Upload Invoice
                                </NavLink>
                            </li>
                        )}
                        {user?.service_accounts?.lms?.id && (
                            <li className='navbar__flymenu__item'>
                                <NavLink
                                    onClick={() => setIsAddMenuShown(false)}
                                    to={`${config.root}/records/new`}
                                    className='navbar__flymenu__item__link bg-green'>
                                    Make Repayment
                                </NavLink>
                            </li>
                        )}
                        {type === 'company' && (
                            <li className='navbar__flymenu__item'>
                                <NavLink
                                    onClick={() => setIsAddMenuShown(false)}
                                    to={`/onboarding/`}
                                    className='navbar__flymenu__item__link bg-green'>
                                    Add Lead
                                </NavLink>
                            </li>
                        )}
                    </ul>
                    <button
                        className={`navbar__add btn btn-icon-lg btn-circle ${
                            isAddMenuShown ? 'navbar__add-clicked' : ''
                        }`}
                        onClick={() => setIsAddMenuShown(!isAddMenuShown)}>
                        <i className='ri ri-add-line'></i>
                    </button>
                </>
            )}
            <ul className='navbar__menu'>
                {config.items &&
                    config.items.length > 0 &&
                    config.items?.map((item) => {
                        return (
                            <li
                                className='navbar__menu__tab'
                                key={item.name}>
                                <NavLink
                                    to={`${config.root}/${item.link}`}
                                    className='navbar__menu__tab__link'>
                                    <Icon
                                        name={item.icon}
                                        className='navbar__menu__tab__link__icon'
                                    />
                                    <span className='navbar__menu__tab__link__title'>
                                        {item.title}
                                    </span>
                                </NavLink>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};

export default NavBar;
