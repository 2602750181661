import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import { getLead, validateLead } from '../../services/_crm.service';
import LoaderAnimated from '../../components/atoms/LoaderAnimated';
import { routes } from '../BorrowerApp/config';

const KycValidate = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        const lead = await getLead(user.lead_id);
        if (lead?.status === 'kyc_pending') {
            const response = await validateLead(lead);
            if (!response) {
                navigate(-1);
            }
        }
        window.location.href = routes.home;
        setLoading(false);
    };

    useEffect(() => {
        handleSubmit();
    }, []);

    return (
        <>
            {loading && (
                <LoaderAnimated
                    loading={true}
                    name='processing'
                    title='Please wait, Your application is being submitted!'
                />
            )}
        </>
    );
};

export default KycValidate;
