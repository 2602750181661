import React, { useContext, useEffect, useState } from 'react';
import { useUser } from '../../context/UserContext';
import MetaContext from '../../context/MetaContext';
import { useConstants } from '../../context/ConstantsContext';
import { listConstants } from '../../services/_constants.service';
import AppHeader from '../../components/organisms/AppHeader';
import { Route, Routes } from 'react-router-dom';
import CompanyDashboard from './CompanyDashboard';
import NavBar from '../../components/molecules/NavBar';
import { root } from './config';
import Leads from './Leads';

const CompanyApp = () => {
    const { user } = useUser();
    const { setMeta } = useContext(MetaContext);
    const { cacheConstants } = useConstants();
    const [initialized, setInitialized] = useState(false);

    const getConstants = async () => {
        try {
            const response = await listConstants();
            cacheConstants(response);
        } catch (error) {
        } finally {
            setInitialized(true);
        }
    };

    useEffect(() => {
        getConstants();
        setMeta({ title: 'Crego' });
    }, []);

    return (
        <>
            {initialized && (
                <>
                    <AppHeader logout={true} />
                    <div className='content'>
                        <Routes>
                            <Route
                                index
                                element={<CompanyDashboard />}></Route>
                            <Route
                                path='/leads/*'
                                element={<Leads />}></Route>
                        </Routes>
                    </div>
                    <NavBar
                        config={{
                            root,
                            items: [
                                {
                                    name: 'home',
                                    icon: 'home-smile-2-line',
                                    title: 'Home',
                                    link: '',
                                },
                                {
                                    name: 'leads',
                                    icon: 'team-line',
                                    title: 'Leads',
                                    link: 'leads',
                                },
                                {
                                    name: 'account',
                                    icon: 'account-circle-line',
                                    title: 'My Account',
                                    link: 'account',
                                },
                            ],
                        }}
                    />
                </>
            )}
        </>
    );
};

export default CompanyApp;
