import React from 'react';
import MessageView from '../../../components/molecules/MessageView';

const RecordsPayment = () => {
    return (
        <MessageView
            heading='Coming Soon'
            img='/assets/img/svgs/pg.svg'>
            You'll be able to make your repayments using UPI, Debit Card and Net
            Banking soon.
        </MessageView>
    );
};

export default RecordsPayment;
