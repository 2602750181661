import axios from 'axios';
import { getArrayIndex, getValue, updateObject } from './json';
import { beautify } from './str';
import { alertError } from './alert';
import { resizeFile } from './file';

export const validatePayload = (items, payload) => {
    let valid = true;
    const errors = [];
    items.forEach((item) => {
        if (item.is_required && !getValue(payload, item.as || item.name)) {
            valid = false;
            errors[item.name] = `${beautify(item.name)} is required`;
        }
    });
    if (!valid && Object.keys(errors).length > 0) {
        alertError(errors);
    }
    return valid;
};

export const buildPayload = (items, request, lists) => {
    const payload =
        getArrayIndex(items, 'type', 'file') !== -1
            ? formData(items, request)
            : formObject(items, request);
    if (lists) {
        lists.forEach((key) => {
            payload[key] = Object.values(payload[key]);
        });
    }
    return payload;
};

export const formData = (items, request) => {
    const payload = new FormData();
    items.forEach((item) => {
        if (
            request[item.name] &&
            item.type === 'file' &&
            typeof request[item.name] === 'object'
        ) {
            Array.from(request[item.name]).forEach((file) => {
                payload.append(item.name, file);
            });
        } else if (Array.isArray(request[item.name])) {
            request[item.name].forEach((val) => {
                payload.append(item.name, val);
            });
        } else if (item.type === 'json') {
            payload.append(item.name, JSON.parse(request[item.name]));
        } else if (item.type !== 'file' && request[item.name] !== undefined) {
            payload.append(item.name, request[item.name]);
        }
    });
    return payload;
};

export const formObject = (items, request) => {
    let payload = {};
    items.forEach((item) => {
        const value = request?.[item.name];
        if (item.as) {
            payload = updateObject(payload, item.as, value);
        } else {
            if (request && value !== undefined) {
                if (['daterange', 'multirange'].indexOf(item.type) !== -1) {
                    if (value[0]) {
                        payload = {
                            ...payload,
                            [item.name + '_min']: value[0],
                        };
                    }
                    if (value[1]) {
                        payload = {
                            ...payload,
                            [item.name + '_max']: value[1],
                        };
                    }
                } else {
                    payload = { ...payload, [item.name]: value };
                }
            }
        }
    });
    return payload;
};

export const flattenForm = (items, data) => {
    let tmp = {};
    const req = { ...data };
    if (items) {
        items.forEach((item) => {
            if (item.type === 'json') {
                req[item.name] = JSON.stringify(req[item.name]);
            }
            if (item.value !== undefined && item.value !== null) {
                tmp[item.name] =
                    item.type === 'json'
                        ? JSON.stringify(item.value)
                        : item.value;
            }
            if (item.as && data) {
                tmp[item.name] =
                    item.type === 'json'
                        ? JSON.stringify(getValue(data, item.as))
                        : getValue(data, item.as);
            }
        });
    }
    tmp = { ...tmp, ...req };
    return tmp;
};

export const selectOptions = async (config) => {
    let options = [];
    if (config?.api) {
        const { data } = await axios.get(config?.api);
        data.data.forEach((row) => {
            options.push({
                value: config?.value ? getValue(row, config.value) : row.id,
                name: config?.name ? getValue(row, config.name) : row.name,
                icon: config?.icon ? getValue(row, config.icon) : row.icon,
            });
        });
    } else {
        options = config;
        options.forEach((value, index) => {
            if (typeof value !== 'object') {
                options[index] = { name: beautify(value), value };
            }
        });
    }
    return options;
};
