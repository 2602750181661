export const MAPPINGS = {
    signzy: {
        business_types: {
            PARTNERSHIP: 'partnership',
            'PRIVATE LIMITED COMPANY': 'private_limited',
            PROPRIETORSHIP: 'proprietorship',
            'HINDU UNDIVIDED FAMILY': 'huf',
            'PUBLIC SECTOR UNDERTAKING': 'psu',
            'PUBLIC LIMITED COMPANY': 'public_limited',
            'LIMITED LIABILITY PARTNERSHIP': 'limited_liability_partnership',
            'SOCIETY/ CLUB/ TRUST/ AOP': 'aop',
        },
    },

    crego: {
        partners_label: {
            partnership: 'partner',
            limited_liability_partnership: 'partner',
        },
    },
};
