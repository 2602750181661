import React, { useEffect, useState } from 'react';

const FormControlMultiRange = (props) => {
    const { item, onChange, value } = props;
    const [minVal, setMinVal] = useState(item.min || '');
    const [maxVal, setMaxVal] = useState(item.max || '');

    useEffect(() => {
        if (value) {
            const [valMin, valMax] = value;
            setMinVal(valMin);
            setMaxVal(valMax);
        }
    }, [value]);

    useEffect(() => {
        onChange(item.name, [minVal, maxVal]);
    }, [minVal, maxVal]);

    return (
        <div className='mt-2 mb-10'>
            <div className='form__item'>
                <input
                    name='min'
                    type='text'
                    className='control'
                    placeholder='Min'
                    value={minVal}
                    onChange={(e) => setMinVal(e.target.value)}
                />
                <input
                    name='max'
                    type='text'
                    className='control'
                    placeholder='Max'
                    value={maxVal}
                    onChange={(e) => setMaxVal(e.target.value)}
                />
            </div>
        </div>
    );
};

export default FormControlMultiRange;
