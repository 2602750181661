import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Image from '../atoms/Image';

const VerticalNav = ({ config, onClick, tab }) => {
    const [selected, setSelected] = useState(tab || config.items[0].value);

    const handleSelected = (value) => {
        setSelected(value);
        if (onClick) {
            onClick(value);
        }
    };

    useEffect(() => {}, [config, tab]);

    const info = (item) => {
        return (
            <>
                <div className={`menu__item__info ${item.className || ''}`}>
                    {item.img && <Image src={item.img} />}
                    {item.name}
                </div>
                {(item.arrow === undefined || item.arrow) && (
                    <i className='ri ri-arrow-right-line'></i>
                )}
            </>
        );
    };

    return (
        <ul className='menu'>
            {config.items &&
                config.items.length > 0 &&
                config.items?.map((item, index) => {
                    return (
                        <li
                            onClick={() => handleSelected(item.value)}
                            className={[
                                'menu__item',
                                item.value === selected ? 'active' : '',
                                item.img ? 'p-10' : 'p-20',
                            ].join(' ')}
                            key={item.value}>
                            <Link
                                to={item.path}
                                className={item.className || ''}>
                                {info(item)}
                            </Link>
                        </li>
                    );
                })}
        </ul>
    );
};

export default VerticalNav;
