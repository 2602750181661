import React, { useState } from 'react';
import LeadAccount from './LeadAccount';
import TabbedNav from '../../../../components/molecules/TabbedNav';
import {
    Navigate,
    Route,
    Routes,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { routes } from '../../config';
import LeadStatus from './LeadStatus';
import LeadActivity from './LeadActivity';
import LeadProperty from './LeadProperty';
import LeadFi from './LeadFi';
import Onboarding from '../../../Onboarding';

const LeadDetails = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState({
        value: params['*'],
    });

    const lead = {
        id: '08d16dcc-df03-4c95-98e3-952b7190c985',
        primary_applicant: {
            name: 'Kiranovations Technology Private Limited',
            primary_mobile: '9920828104',
            business_type: 'private_limited',
        },
        ref_id: 'CL3LXZ1',
        program: {
            ref_id: 'CL3LWZ2A',
            program: {
                id: 'fd332f90-5d1b-428b-b8f5-37014b5be8d7',
                ref_id: 'CP00XRK',
                name: 'MSFT <> AMEX',
                product_type: 'scf',
            },
        },
    };

    const root = routes.leads + '/' + lead.id;
    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        navigate(root + '/' + tab);
    };

    return (
        <div className='container'>
            {params['*'].indexOf('kyc') === -1 && (
                <div className='card'>
                    <div className='card__body p-15'>
                        <h4>{lead.primary_applicant.name}</h4>
                        <div className='mt-5'>
                            <span className='color-secondary pr-2'>
                                <i className='ri-loader-line mr-2'></i>
                                Status:
                            </span>
                            <span className='color-green'>Pending</span>
                        </div>
                        <div className='mt-5'>
                            <span className='color-secondary pr-2'>
                                <i className='ri-map-pin-line mr-2'></i>
                                Branch:
                            </span>
                            <span>New Delhi</span>
                        </div>
                    </div>
                    <TabbedNav
                        mode='line'
                        onChange={(tab) => handleTabClick(tab)}
                        tab={selectedTab?.value}
                        config={{
                            items: [
                                {
                                    value: '',
                                    url: root,
                                    path: routes.lead_details,
                                    name: 'KYC',
                                },
                                {
                                    value: 'account',
                                    url: root + 'account',
                                    path: routes.lead_details,
                                    name: 'Account',
                                },
                                {
                                    value: 'activity',
                                    url: root + 'activity',
                                    path: routes.lead_details,
                                    name: 'Activity',
                                },
                            ],
                        }}
                    />
                </div>
            )}
            <Routes>
                <Route
                    index
                    element={<LeadStatus root={root} />}></Route>
                <Route
                    path='kyc/*'
                    element={<Onboarding page={false} />}></Route>
                <Route
                    path='account'
                    element={<LeadAccount />}></Route>
                <Route
                    path='activity'
                    element={<LeadActivity />}></Route>
                <Route
                    path='property'
                    element={<LeadProperty lead={lead} />}></Route>
                <Route
                    path='FI'
                    element={<LeadFi lead={lead} />}></Route>
            </Routes>
        </div>
    );
};

export default LeadDetails;
