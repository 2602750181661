import React from 'react';
import { useConstants } from '../../../context/ConstantsContext';

const FormControlRadioList = (props) => {
    const { item, onChange } = props;
    const consts = useConstants();
    const options =
        typeof item.options === 'string' ? consts[item.options] : item.options;
    return (
        <div>
            {options?.map((option) => {
                return (
                    <label
                        className='control-radio'
                        key={`radio_list_item_${option.value}`}>
                        <input
                            type='radio'
                            name={item.name}
                            value={option.value}
                            onChange={() => onChange(option.value)}
                        />
                        <span className='control-radio__label'>
                            {option.label}
                        </span>
                    </label>
                );
            })}
        </div>
    );
};

export default FormControlRadioList;
