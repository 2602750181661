import React, { useEffect, useState } from 'react';
import { dateFromEpoch, days } from '../../../helpers/datetime';
import {
    getLoanSummary,
    listLoans,
} from '../../../services/_accounting.service';
import { amount, buildUrl } from '../../../helpers/str';
import { Link } from 'react-router-dom';
import { routes } from '../config';
import { useUser } from '../../../context/UserContext';

const DueLoans = () => {
    const { user } = useUser();
    const [list, setList] = useState([]);
    const getList = async () => {
        const response = await listLoans({
            lead_id: user.lead_id,
            program_id: user.program_id,
            ordering: 'due_date',
            status: 'running',
        });
        const tmp = [];
        for (const row of response?.results) {
            row.days = days(dateFromEpoch(row.due_date, 'YYYY-MM-DD'));
            if (tmp.length < 3 && row.days > -15) {
                if (row.days > -1) {
                    row.class = 'red';
                } else if (row.days > -15) {
                    row.class = 'yellow';
                }
                row.summary = await getLoanSummary(row.id);
                row.total_outstanding =
                    row.summary.principal_outstanding +
                    row.summary.interest_outstanding +
                    row.summary.overdue_outstanding +
                    row.summary.penal_outstanding;
                tmp.push(row);
            }
            setList(tmp);
        }
    };
    useEffect(() => {
        getList();
    }, []);
    return (
        <>
            {list?.map((row) => {
                return (
                    <div
                        className='card mt-10'
                        key={row.id}>
                        <div
                            className={[
                                'card__header',
                                row.class ? `bg-${row.class}-dark` : '',
                            ].join(' ')}>
                            <h4>
                                Repayment due
                                <span
                                    className={[
                                        'ml-5',
                                        row.class ? `color-${row.class}` : '',
                                    ].join(' ')}>
                                    {row.days < 0 && <>in {-row.days} days</>}
                                    {row.days === 0 && <>today</>}
                                </span>
                            </h4>
                            <small>
                                Pay before due date to avoid penalties & any
                                impact on your credit score.
                            </small>
                        </div>
                        <div className='card__body p-20'>
                            <h2>{amount(row?.total_outstanding)}</h2>
                            {row?.invoice_no}
                            <span className='mx-5'>&#11825;</span>
                            <span
                                className={[
                                    row.class ? `color-${row.class}` : '',
                                ].join(' ')}>
                                Due on {dateFromEpoch(row?.due_date)}
                            </span>
                            <div className='btn-group'>
                                <Link
                                    to={buildUrl(routes.loan_details, {
                                        id: row.id,
                                    })}
                                    className='btn btn-sm btn-line'>
                                    View Details
                                </Link>
                                <Link
                                    to={buildUrl(routes.record_add, {
                                        amount: row.total_outstanding,
                                    })}
                                    className='btn btn-sm'>
                                    Pay Now
                                </Link>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default DueLoans;
