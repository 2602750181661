import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopupsList from './TopupsList';
import TopupDetails from './TopupDetails';
import TopupsForm from './TopupForm';
import { routes } from '../config';

const Topups = () => {
    return (
        <Routes>
            <Route
                index
                element={<Navigate to={`${routes.topups}/running`} />}></Route>
            {['all', 'pending', 'running', 'closed'].map((tab) => {
                return (
                    <Route
                        key={tab}
                        path={`/${tab}`}
                        element={<TopupsList tab={tab} />}
                    />
                );
            })}
            <Route
                path='/new'
                element={<TopupsForm />}></Route>
            <Route
                path='/:id'
                element={<TopupDetails />}></Route>
        </Routes>
    );
};

export default Topups;
