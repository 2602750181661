import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/atoms/Loader';
import PageHeader from '../../../components/molecules/PageHeader';
import FormWrapper from '../../../components/organisms/FormView/FormWrapper';
import { useUser } from '../../../context/UserContext';
import { alertSuccess } from '../../../helpers/alert';
import { urls as disburseUrls } from '../../../services/_disburse.service';
import { routes } from '../config';

const TopupForm = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [account, setAccount] = useState({});
    const handleSubmit = (data) => {
        alertSuccess('The withdrawal request has been raised successfully');
        navigate(routes.topups + '/all');
    };

    const initAccount = async () => {
        const response = user?.service_accounts;
        if (response?.disburse?.id) {
            setAccount(response?.disburse);
        }
    };

    useEffect(() => {
        initAccount();
    }, []);

    return (
        <>
            {!account?.id && <Loader loading={!account?.id} />}
            {account?.id && (
                <>
                    <PageHeader subtitle='Upload a distributor invoice to withdraw money from your credit line'>
                        Request Disbursement
                    </PageHeader>
                    <FormWrapper
                        className='px-30'
                        config={{
                            url: disburseUrls.topups,
                            btn: 'Request Disbursement',
                            items: [
                                { type: 'file', name: 'file' },
                                {
                                    type: 'hidden',
                                    name: 'account',
                                    value: account?.id,
                                },
                                { type: 'text', name: 'invoice_no' },
                                { type: 'amount', name: 'invoice_amount' },
                                { type: 'amount', name: 'disbursal_amount' },
                                { type: 'datepicker', name: 'invoice_date' },
                            ],
                        }}
                        submitted={handleSubmit}
                    />
                </>
            )}
        </>
    );
};

export default TopupForm;
