import axios from 'axios';
import React, { useState } from 'react';
import { buildPayload } from '../../helpers/form';
import { buildUrl } from '../../helpers/str';
import FormFields from './FormFields';

const Form = ({ className, config, data, onSubmit }) => {
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState(data || {});

    const handleChange = (data) => {
        setRequest(data);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const payload = buildPayload(config.items, request);
        const queryParams =
            config.method && config.method.toLowerCase() === 'get'
                ? payload
                : {};

        if (config.action) {
            const url = buildUrl(config.action, data);
            const response = await axios
                .request({
                    url,
                    data: payload,
                    method: config.method || 'post',
                    params: queryParams,
                    responseType: config.responseType || 'json',
                })
                .finally(() => {
                    setLoading(false);
                });
            setRequest({});
            if (onSubmit) {
                onSubmit(response.data);
            }
        } else {
            if (onSubmit) {
                onSubmit(request);
            }
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            className={className || ''}>
            <FormFields
                onChange={handleChange}
                config={config}
                data={data}></FormFields>
            <button className='btn'>{config?.btn?.text || 'Submit'}</button>
        </form>
    );
};

export default Form;
