import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

const FormControlTimepicker = (props) => {
    const { item, onChange, value } = props;
    const [startDate, setStartDate] = useState('');

    const handleChange = (date) => {
        setStartDate(date);
        onChange(item.name, item.epoch ? moment(date).unix() : date);
    };

    useEffect(() => {
        try {
            if (value) {
                const date = new Date(
                    item.epoch ? moment.unix(value) : moment(value),
                );
                setStartDate(date);
            }
        } catch (error) {}
    }, [item, value]);

    return (
        <>
            <DatePicker
                showTimeSelect
                dateFormat='yyyy-MM-dd HH:mm'
                className='control'
                selected={startDate}
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                onChange={handleChange}
            />
        </>
    );
};

export default FormControlTimepicker;
