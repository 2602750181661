import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LeadsList from './LeadsList';
import LeadDetails from './LeadDetails';

const Leads = () => {
    return (
        <Routes>
            <Route
                index
                element={<LeadsList />}></Route>
            <Route
                path='/:id/*'
                element={<LeadDetails />}></Route>
        </Routes>
    );
};

export default Leads;
