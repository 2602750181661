import React, { useState } from 'react';
import LoaderAnimated from '../../components/atoms/LoaderAnimated';
import PageHeader from '../../components/molecules/PageHeader';
import FormView from '../../components/organisms/FormView';
import { useUser } from '../../context/UserContext';
import { searchGst } from '../../services/_crm.service';

const OnboardingSearchGst = () => {
    const { user } = useUser();
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState({});
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const gst = await searchGst(request.pan_no, request.state);
        setLoading(false);
    };
    const handleUpdated = (data) => {
        setRequest(data);
    };
    return (
        <div
            className='container init'
            style={{ marginTop: -100 }}>
            <PageHeader
                className='p-0 pb-20'
                subtitle='Search your GST number below'></PageHeader>
            {loading && <LoaderAnimated loading={loading} />}
            <form onSubmit={handleSubmit}>
                <FormView
                    config={{
                        items: [
                            { name: 'pan_no' },
                            { name: 'state', type: 'select' },
                        ],
                    }}
                    updated={handleUpdated}
                    data={request}
                />
                <button
                    className='btn'
                    disabled={loading}>
                    Start Application
                </button>
            </form>
        </div>
    );
};

export default OnboardingSearchGst;
