import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RecordsList from './RecordsList';
import RecordsForm from './RecordsForm';
import RecordsPayment from './RecordsPayment';
import RecordsStatus from './RecordsStatus';

const Records = () => {
    return (
        <Routes>
            <Route
                index
                element={<RecordsList />}></Route>
            <Route
                path='/new'
                element={<RecordsForm />}></Route>
            <Route
                path='/payment'
                element={<RecordsPayment />}></Route>
            <Route
                path='/finished'
                element={<RecordsStatus />}></Route>
        </Routes>
    );
};

export default Records;
