import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../../components/atoms/Loader';
import DetailView from '../../../components/organisms/DetailView';
import {
    dateFromEpoch,
    daysClass,
    humanizedDue,
} from '../../../helpers/datetime';
import { amount, buildUrl } from '../../../helpers/str';
import { getLoan, getLoanSummary } from '../../../services/_accounting.service';
import { routes } from '../config';
import LoanDpd from './LoanDpd';
import LoanOutstandingBreakup from './LoanOutstandingBreakup';
import LoanPaidBreakup from './LoanPaidBreakup';

const LoanDetails = () => {
    const { id } = useParams();
    const [loan, setLoan] = useState({});
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        setLoading(true);
        const response = await getLoan(id);
        response.summary = await getLoanSummary(id);
        response.summary.total_outstanding =
            response.summary.principal_outstanding +
            response.summary.interest_outstanding +
            response.summary.overdue_outstanding +
            response.summary.penal_outstanding;
        setLoan(response);
        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, [id]);

    return (
        <>
            {loading && <Loader loading={loading} />}
            {!loading && (
                <>
                    <div className='container flex justify-between align-center'>
                        <div>
                            {loan?.summary?.total_outstanding == 0 &&
                                loan.status === 'closed' && (
                                    <h1 className='color-green'>Paid</h1>
                                )}
                            {(loan?.summary?.total_outstanding != 0 ||
                                loan.status !== 'closed') && (
                                <h1>
                                    {amount(loan?.summary?.total_outstanding)}
                                </h1>
                            )}
                            Invoice No: {loan?.invoice_no}
                            {loan?.status !== 'closed' && loan?.due_date && (
                                <>
                                    <span className='mx-5'>&#11825;</span>
                                    <span
                                        className={`color-${daysClass(
                                            dateFromEpoch(
                                                loan.due_date,
                                                'YYYY-MM-DD',
                                            ),
                                        )}`}>
                                        {humanizedDue(loan.due_date)}
                                    </span>
                                </>
                            )}
                        </div>
                        {loan?.topup?.invoice_storage_id && (
                            <a
                                href={loan?.topup?.invoice_storage_id}
                                className='btn btn-icon-lg btn-circle'>
                                <i className='ri ri-bill-line'></i>
                            </a>
                        )}
                    </div>
                    <div className='container pt-0'>
                        {loan?.summary?.dpd !== 0 && <LoanDpd loan={loan} />}
                        {loan?.status !== 'closed' && (
                            <>
                                <h4 className='mt-20'>Outstanding</h4>
                                <LoanOutstandingBreakup
                                    className='mt-10'
                                    summary={loan?.summary}
                                />
                            </>
                        )}
                        <h4 className='mt-20'>Repayments</h4>
                        <LoanPaidBreakup
                            className='mt-10'
                            summary={loan?.summary}
                        />
                        <h4 className='mt-20'>Loan Details</h4>
                        <DetailView
                            className='mt-10'
                            details={loan}
                            config={{
                                items: [
                                    { name: 'ref_id', label: 'Loan ID' },
                                    {
                                        name: 'loan_amount',
                                        transform: 'amount',
                                    },
                                    {
                                        name: 'due_date',
                                        transform: 'dateFromEpoch',
                                    },
                                    {
                                        name: 'loan_start_date',
                                        transform: 'dateFromEpoch',
                                    },
                                    { name: 'utr' },
                                    { name: 'status', transform: 'beautify' },
                                ],
                            }}
                        />
                        {loan.status !== 'closed' && (
                            <Link
                                className='btn'
                                to={buildUrl(routes.record_add, {
                                    amount: loan?.summary?.total_outstanding,
                                })}>
                                Make Repayment
                            </Link>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default LoanDetails;
