import React, { useEffect, useState } from 'react';
import { useUser } from '../../../context/UserContext';
import { getLead } from '../../../services/_crm.service';
import Loader from '../../../components/atoms/Loader';
import DetailView from '../../../components/organisms/DetailView';

const AccountSummary = () => {
    const { user } = useUser();
    const [loading, setLoading] = useState(false);
    const [lead, setLead] = useState({});
    const initProfile = async () => {
        setLoading(true);
        const response = await getLead(user.lead_id);
        setLead(response);
        setLoading(false);
    };
    useEffect(() => {
        initProfile();
    }, []);
    return (
        <>
            {loading && <Loader loading={loading} />}
            {!loading && (
                <>
                    <div className='container flex justify-between align-center'>
                        <div>
                            <h1>
                                {lead?.business_entity?.name ||
                                    lead?.person_entity?.name}
                            </h1>
                            {lead?.business_entity?.primary_mobile ||
                                lead?.person_entity?.primary_mobile}
                        </div>
                    </div>
                    <div className='container pt-0'>
                        <h4 className='mt-30 mb-10'>Business Details</h4>
                        <DetailView
                            details={lead}
                            config={{
                                items: [
                                    {
                                        name: 'business_entity.ref_id',
                                        label: 'Borrower ID',
                                    },
                                    { name: 'business_entity.pan_no' },
                                ],
                            }}
                        />
                        <h4 className='mt-30 mb-10'>Applicant Details</h4>
                        <DetailView
                            details={lead}
                            config={{
                                items: [
                                    {
                                        name: 'person_entity.name',
                                        label: 'Name',
                                    },
                                    {
                                        name: 'person_entity.primary_mobile',
                                        label: 'Mobile No.',
                                    },
                                    {
                                        name: 'person_entity.pan_no',
                                    },
                                    { name: 'person_entity.aadhaar_no' },
                                ],
                            }}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default AccountSummary;
