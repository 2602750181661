import React from 'react';
import Icon from '../atoms/Icon';

const Filter = () => {
    return (
        <>
            <Icon name='filter-3-line' />
        </>
    );
};

export default Filter;
