import React, { useEffect } from 'react';

const Loader = ({ loading }) => {
    useEffect(() => {}, [loading]);
    return (
        <>
            {loading && (
                <div className='loader'>
                    <span className='spinner'></span>
                </div>
            )}
        </>
    );
};

export default Loader;
