import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { routes as authRoutes } from '../../../auth/_auth.config';
import Loader from '../../../components/atoms/Loader';
import VerticalNav from '../../../components/molecules/VerticalNav';
import { useUser } from '../../../context/UserContext';
import { getInitial } from '../../../helpers/str';
import { downloadSoa } from '../../../services/_accounting.service';
import { getLead } from '../../../services/_crm.service';
import { getProfiles } from '../../../services/_identity.service';
import { routes } from '../config';
import { confirm } from '../../../helpers/alert';

const AccountProfile = () => {
    const { user, logoutUser } = useUser();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [nav, setNav] = useState([
        {
            value: 'summary',
            name: 'My KYC',
            path: routes.account_summary,
        },
        {
            value: 'logout',
            name: 'Logout',
            className: 'color-red',
            arrow: false,
        },
    ]);
    const [lead, setLead] = useState({});
    const serviceAccounts = user?.service_accounts;

    const soa = async (id) => {
        const response = await downloadSoa(id);
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
    };

    const handleClick = (value) => {
        if (value === 'logout') {
            confirm(
                'Logout?',
                'Do you really want to logout your current sessions',
                () => {
                    logoutUser();
                    navigate('/');
                },
            );
        } else if (value === 'soa') {
            soa();
        }
    };

    const initLead = async () => {
        setLoading(true);
        const response = await getLead(user.lead_id);
        setLead(response);
        setLoading(false);
    };

    const initProfiles = async () => {
        const response = await getProfiles();
        if (response.length > 1) {
            const tmp = [...nav];
            tmp.unshift({
                value: 'switch',
                name: 'Switch Program',
                path: authRoutes.programs,
            });
            setNav(tmp);
        }
    };

    useEffect(() => {
        initProfiles();
        initLead();
    }, []);

    return (
        <>
            {loading && <Loader loading={loading} />}
            {!loading && (
                <div className='container pt-40'>
                    <div className='profile'>
                        <div className='profile__initial'>
                            {getInitial(lead?.business_entity?.name)}
                        </div>
                        <div className='profile__info'>
                            <h2 className='profile__info__name'>
                                {lead?.business_entity?.name ||
                                    lead?.person_entity?.name}
                            </h2>
                            <div className='profile__info__id'>
                                {lead?.business_entity?.ref_id}
                                <span className='mx-5'>&#11825;</span>
                                {lead?.business_entity?.primary_mobile ||
                                    lead?.person_entity?.primary_mobile}
                            </div>
                        </div>
                    </div>

                    {serviceAccounts?.lms && (
                        <ul className='menu'>
                            <li
                                className='menu__item'
                                key={serviceAccounts?.lms?.id}>
                                <Link
                                    onClick={() =>
                                        soa(serviceAccounts?.lms?.id)
                                    }>
                                    Download SOA {serviceAccounts?.lms?.ref_id}
                                </Link>
                            </li>
                        </ul>
                    )}

                    <VerticalNav
                        onClick={handleClick}
                        config={{
                            items: nav,
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default AccountProfile;
