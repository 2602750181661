import Swal from 'sweetalert2';
import { beautify } from './str';

export const alertWarning = (text, title = 'Warning!') => {
    return showAlert('warning', title, text);
};

export const alertSuccess = (text, title = 'Success!') => {
    return showAlert('success', title, text);
};

export const alertError = (text, title = '') => {
    return showAlert('error', title, text);
};

export const toastWarning = (text) => {
    return showToast('warning', text);
};

export const toastSuccess = (text) => {
    return showToast('success', text);
};

export const toastError = (text) => {
    return showToast('error', text);
};

export const showToast = (type, text) => {
    showAlert(type, '', text, true);
};

export const showAlert = (icon, title, message, toast = false) => {
    let html = '';
    if (typeof message === 'object') {
        html += '<table class="table table-striped text-start"><tbody>';
        Object.entries(message).forEach(([key, value]) => {
            html +=
                '<tr><td style="width: 10rem" class="text-danger p-5">' +
                beautify(key) +
                '</td><td class="p-5">' +
                value +
                '</td></tr>';
        });
        html += '</tbody></table>';
    }
    Swal.fire({
        icon,
        title,
        html: html || message,
        width: html ? '50rem' : '32rem',
        toast,
        position: toast ? 'bottom-end' : 'center',
    });
};

export const confirm = (title, message, callback) => {
    Swal.fire({
        title,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
    }).then((res) => {
        if (res.isConfirmed) {
            callback();
        }
    });
};

export const confirmText = (title, message, callback, opt = {}) => {
    Swal.fire({
        title: 'Are you sure?',
        text: message,
        icon: 'warning',
        input: opt.inputType || 'textarea',
        inputPlaceholder: opt.placeholder || 'Type a message',
        showCancelButton: true,
        confirmButtonText: opt.btnText || 'Yes',
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-brand',
            cancelButton: 'btn btn-secondary',
        },
    }).then((result) => {
        if (result.value) {
            callback(result);
        }
    });
};
