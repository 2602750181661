import axios from 'axios';
import { buildUrl } from '../helpers/str';

export const BASE_URL =
    (process.env.REACT_APP_API_ROOT_IDENTITY ||
        process.env.REACT_APP_API_ROOT_DEFAULT) + '/identity/';

export const urls = {
    auth_token: BASE_URL + 'auth/token/',
    ping: BASE_URL + 'auth/ping/',

    send_otp: BASE_URL + 'auth/borrower/send_otp/',
    borrower_ping: BASE_URL + 'auth/borrower/ping/',
    verify_otp: BASE_URL + 'auth/borrower/verify_otp/',
    profiles: BASE_URL + 'auth/borrower/profiles/',
    choose_profile: BASE_URL + 'auth/borrower/choose_profile/',
};

export const verifyOtp = async (payload) => {
    const { data } = await axios.post(urls.verify_otp, payload);
    return data;
};

export const ping = async (type) => {
    const { data } = await axios.get(
        type === 'company' ? urls.ping : urls.borrower_ping,
        {
            params: { silent: true },
        },
    );
    return data;
};

export const getProfiles = async (id) => {
    const { data } = await axios.get(buildUrl(urls.profiles));
    return data;
};

export const changeProfile = async (payload) => {
    const { data } = await axios.post(buildUrl(urls.choose_profile), payload);
    return data;
};
