import React from 'react';
import { useParams } from 'react-router-dom';
import ListView from '../../../components/organisms/ListView';
import { useUser } from '../../../context/UserContext';
import { buildUrl } from '../../../helpers/str';
import { urls as accountingUrls } from '../../../services/_accounting.service';
import { urls as disburseUrls } from '../../../services/_disburse.service';
import { routes } from '../config';

const TopupsList = ({ filterBy, tab }) => {
    const { user } = useUser();
    const { id } = useParams();
    const topupItems = [
        {
            type: 'heading',
            name: 'disbursal_amount',
            transform: 'amount',
        },
        {
            type: 'items',
            items: [
                { name: 'invoice_no' },
                {
                    name: 'invoice_date',
                    transform: 'date',
                },
            ],
        },
    ];
    const loanItems = [
        {
            type: 'heading',
            name: 'loan_amount',
            transform: 'amount',
        },
        {
            type: 'items',
            name: 'invoice_no_due_date',
            items: [
                { name: 'invoice_no' },
                {
                    name: 'due_date',
                    transform: 'humanizedDue',
                },
            ],
        },
    ];

    return (
        <ListView
            config={{
                route: routes.topups,
                tab: tab || 'running',
                tabs: [
                    {
                        value: 'all',
                        url: buildUrl(disburseUrls.topups, {
                            lead_id: user.lead_id,
                            program_id: user.program_id,
                            expand: 'payouts',
                        }),
                        path: routes.topup_details,
                        name: 'All',
                        items: topupItems,
                    },
                    {
                        value: 'pending',
                        url: buildUrl(disburseUrls.topups, {
                            lead_id: user.lead_id,
                            program_id: user.program_id,
                            status__in:
                                'pending,pending_approval,pending_disbursement',
                        }),
                        path: routes.topup_details,
                        name: 'Pending',
                        items: topupItems,
                    },
                    {
                        value: 'running',
                        url: buildUrl(accountingUrls.loans, {
                            lead_id: user.lead_id,
                            program_id: user.program_id,
                            status: 'running',
                            ordering: 'due_date',
                        }),
                        path: routes.loan_details,
                        name: 'Running',
                        items: loanItems,
                    },
                    {
                        value: 'closed',
                        url: buildUrl(accountingUrls.loans, {
                            status: 'closed',
                        }),
                        path: routes.loan_details,
                        name: 'Closed',
                        items: loanItems,
                    },
                ],
            }}
            filter={{ [filterBy]: id }}
        />
    );
};

export default TopupsList;
