import { alertError } from './alert';

export const validatePincode = (str) => {
    const isValidPincode = /^[0-9]{6}$/.test(str);

    if (str && !isValidPincode) {
        alertError('Please input a valid 6-digit pincode.');
        return false;
    }

    return true;
};
