import React, { useState } from 'react';
import OnboardingDocs from './OnboardingDocs';
import { alertError } from '../../helpers/alert';

const OnboardingDocuments = ({ lead, onNext, onRefresh }) => {
    const [uploadedDocs, setUploadedDocs] = useState([]);

    const onDocsUpdated = (uploaded) => {
        setUploadedDocs(uploaded);
    };

    const handleSubmit = async (e) => {
        if (uploadedDocs.indexOf('pan') === -1) {
            return alertError('Please upload PAN Card');
        }
        if (uploadedDocs.indexOf('aadhaar') === -1) {
            return alertError('Please upload Aadhaar Card');
        }
        e.preventDefault();
        onNext();
    };

    return (
        <div className='container'>
            <h3>Upload KYC Documents</h3>
            <p className='mb-10'>
                Upload PAN & Aadhaar of the Authorized Signatory (
                {lead?.person_entity?.name}) to continue
            </p>
            <div className='mt-20'>
                <OnboardingDocs
                    required={['pan', 'aadhaar']}
                    onUpdated={onDocsUpdated}
                    onRefresh={onRefresh}
                    doc_type='person_document_names'
                    className='mt-20'
                    applicant={lead?.person_entity}
                />
            </div>
            <button
                className='btn btn-fixed'
                onClick={handleSubmit}>
                Save & Proceed
            </button>
        </div>
    );
};

export default OnboardingDocuments;
