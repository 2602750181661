import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export function useUser() {
    return useContext(UserContext);
}

export function UserProvider({ children }) {
    const [user, setUser] = useState(null);
    const [type, setType] = useState(null);

    const loginUser = (userData) => {
        setUser(userData);
    };

    const initType = (userData) => {
        setType(userData);
    };

    const logoutUser = () => {
        const keys = [
            'extra_data',
            'kyc',
            'ckyc',
            'mobile',
            process.env.REACT_APP_LOCAL_STORAGE_AUTH,
        ];
        keys.forEach((key) => {
            localStorage.removeItem(key);
        });
        setUser(null);
    };

    const contextValue = {
        user,
        type,
        loginUser,
        logoutUser,
        initType,
    };

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
}
