import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { applicantGstAnalytics, getLead } from '../../../services/_crm.service';
import { useUser } from '../../../context/UserContext';

const GstAnalyticsSummary = () => {
    const { user } = useUser();
    const [exists, setExists] = useState(true);
    const [running, setRunning] = useState(false);

    const initGstAnalytics = async (id) => {
        const response = await applicantGstAnalytics(id);
        setExists(response?.vendor_key === 'GST_ANALYTICS_REPORT');
        setRunning(response?.data?.result?.request_id);
    };

    const initLead = async () => {
        if (user.lead_id) {
            const response = await getLead(user.lead_id);
            if (response?.business_entity?.gstin) {
                await initGstAnalytics(response?.business_entity?.id);
            }
        }
    };

    useEffect(() => {
        initLead();
    }, []);
    return (
        <>
            {!exists && (
                <div className='card mt-20 bg-yellow-dark p-20'>
                    <h4>
                        <span className='ml-5 color-yellow'>GST Report</span>
                    </h4>
                    <small>Some text as description...</small>
                    {running ? (
                        <Link
                            className='btn bg-yellow color-yellow-dark mt-10'
                            to={`/borrower/gst-analytics?request_id=${running}`}>
                            In Progress, Check Status!
                        </Link>
                    ) : (
                        <Link
                            className='btn bg-yellow color-yellow-dark mt-10'
                            to='/borrower/gst-analytics/initiate'>
                            Pull GST Report
                        </Link>
                    )}
                </div>
            )}
        </>
    );
};
export default GstAnalyticsSummary;
