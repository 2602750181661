import React from 'react';
import FormView from '../../../../components/organisms/FormView';
import OnboardingDocs from '../../../Onboarding/OnboardingDocs';
import { transform } from '../../../../helpers/transform';

const LeadFi = ({ lead }) => {
    return (
        <div className='card p-15'>
            <FormView
                config={{
                    items: [
                        {
                            name: 'contact_person_name',
                        },
                        {
                            name: 'contact_person_mobile',
                        },
                        {
                            type: 'location',
                            name: 'location',
                            showLabel: false,
                        },
                        {
                            type: 'textarea',
                            name: 'address',
                        },
                        {
                            type: 'select',
                            options: transform('options', [
                                'verified',
                                'not_verified',
                            ]),
                        },
                        {
                            type: 'textarea',
                            name: 'remark',
                        },
                    ],
                }}
            />
            <div className='mt-15'>
                <OnboardingDocs
                    title='Documents'
                    className='mt-20'
                    applicant={lead.primary_applicant}
                />
            </div>
        </div>
    );
};

export default LeadFi;
