import React, { useEffect, useState } from 'react';
import FormControlRadioList from '../../components/molecules/FormControl/FormControlRadioList';
import InputText from '../../components/organisms/InputText';
import { listGstDirectors } from '../../services/_crm.service';

const OnboardingSignatory = ({ lead_id, onChange }) => {
    const [names, setNames] = useState([]);
    const [other, setOther] = useState(false);
    const [customName, setCustomName] = useState('');

    const initNames = async () => {
        const response = await listGstDirectors(lead_id);
        setNames(response);
    };

    const handleChange = (value) => {
        if (onChange && value !== 'other') {
            onChange({ name: value, source: 'GST_DETAILS' });
        } else if (value === 'other') {
            setOther(true);
            onChange({ name: '', other: true });
        }
    };

    const handleOtherChange = (value) => {
        setCustomName(value);
        if (onChange) {
            onChange({ name: value, other: true });
        }
    };

    useEffect(() => {
        initNames();
    }, []);

    return (
        <div className='card'>
            <div className='card__header'>Select Authorized Signatory</div>
            <div className='card__body p-15'>
                <div className='mb-10'></div>
                <FormControlRadioList
                    onChange={handleChange}
                    item={{
                        name: 'members',
                        options: [
                            ...names.map((name) => {
                                return { value: name, label: name };
                            }),
                            {
                                value: 'other',
                                label: 'Other Authorized Signatory',
                            },
                        ],
                    }}
                />
                {other && (
                    <div className='mt-20'>
                        <InputText
                            defaultValue={customName}
                            config={{
                                placeholder:
                                    'Enter Name of Authorized Signatory',
                                name: 'other',
                            }}
                            onChange={handleOtherChange}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default OnboardingSignatory;
