import React, { createContext, useContext, useState } from 'react';

const ThemeContext = createContext();

export function useTheme() {
    return useContext(ThemeContext);
}

export function ThemeProvider({ children }) {
    const [theme, setTheme] = useState(null);
    const [mode, setMode] = useState(
        localStorage.getItem(`${process.env.REACT_APP_KEY}-theme-mode`) ||
            'theme__light',
    );

    const cacheTheme = (themeData) => {
        setTheme(themeData);
    };

    const toggleMode = () => {
        const str = `${process.env.REACT_APP_KEY}-theme-mode`;
        const oldMode = localStorage.getItem(str);
        const newMode =
            oldMode === 'theme__dark' ? 'theme__light' : 'theme__dark';
        localStorage.setItem(str, newMode);
        setMode(newMode);
    };

    const contextValue = {
        theme,
        cacheTheme,
        mode,
        toggleMode,
    };

    return (
        <ThemeContext.Provider value={contextValue}>
            {children}
        </ThemeContext.Provider>
    );
}
