import { useEffect, useState } from 'react';
import { amount, beautify, unAmount } from '../../../helpers/str';

const InputFieldAmount = (props) => {
    const {
        config = {},
        onChange,
        className,
        defaultValue,
        currency = true,
    } = props;
    const [value, setValue] = useState(defaultValue || '');

    const handleChange = (e) => {
        let num = unAmount(e.target.value);
        console.log('num', num);
        if (isNaN(num)) {
            num = '';
        }
        const val = amount(e.target.value, currency);
        setValue(val);
        if (onChange) {
            onChange(unAmount(val), e.target.name);
        }
    };

    useEffect(() => {
        setValue(amount(defaultValue, currency));
    }, [defaultValue, currency]);

    return (
        <div className='form__item'>
            <input
                type='text'
                readOnly={config.readOnly}
                placeholder={
                    config.placeholder || config.label || beautify(config.name)
                }
                className={`control ${className}`}
                name={config.name || 'field'}
                inputMode='decimal'
                onChange={handleChange}
                value={value || ''}
            />
        </div>
    );
};

export default InputFieldAmount;
