import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoaderAnimated from '../../components/atoms/LoaderAnimated';
import DetailView from '../../components/organisms/DetailView';
import FormWrapper from '../../components/organisms/FormView/FormWrapper';
import { useUser } from '../../context/UserContext';
import { getLead, listBanks, urls } from '../../services/_crm.service';
import { routes } from './config';

const KycBank = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [loading, setLoading] = useState(false);
    const [lead, setLead] = useState({});
    const [edit, setEdit] = useState({});
    const [banks, setBanks] = useState([]);

    const initLead = async () => {
        const response = await getLead(user.lead_id);
        setLead(response);
        initBanks(response?.business_entity?.id);
    };

    const handleSubmit = async () => {
        navigate(routes.validate);
    };

    const initBanks = async (applicant_id) => {
        const response = await listBanks(applicant_id);
        setBanks(response);
    };

    useEffect(() => {
        initLead();
    }, []);

    const formItems = [
        {
            type: 'hidden',
            name: 'applicant_id',
            value: lead?.business_entity?.id,
        },
        {
            type: 'text',
            name: 'ifsc',
            writeOnly: true,
            lookup: {
                type: 'ifsc',
                url: urls.lookup_ifsc,
                target: {
                    bank_details: ['bank', 'branch'],
                },
            },
        },
        {
            type: 'rawText',
            name: 'bank_details',
            showLabel: false,
            className: 'info',
            prefill: {
                type: 'ifsc',
                url: urls.lookup_ifsc,
                key: 'ifsc',
                value: ['bank', 'branch'],
            },
        },
        {
            type: 'select',
            name: 'account_type',
            options_src: 'constants',
            options: 'bank_account_types',
        },
        { type: 'text', name: 'account_no' },
        { type: 'text', name: 'account_holder_name' },
        { type: 'hidden', name: 'is_for_nach', value: true },
    ];

    return (
        <div className='container'>
            <h1>Bank Details</h1>
            <p className='mb-10'>
                Please fill the following details to complete your KYC.
            </p>
            {loading && (
                <LoaderAnimated
                    loading={true}
                    name='processing'
                    title='Please wait, Your application is being submitted!'
                />
            )}
            {!edit?.id &&
                banks?.length > 0 &&
                banks?.map((bank) => {
                    return (
                        <div key={bank.id}>
                            <div>
                                <DetailView
                                    noParent={true}
                                    className='p-0'
                                    details={bank}
                                    config={{
                                        items: [
                                            { name: 'bank_name' },
                                            { name: 'ifsc' },
                                            { name: 'account_holder_name' },
                                            { name: 'account_no' },
                                            {
                                                name: 'account_type',
                                                transform: 'beautify',
                                            },
                                        ],
                                    }}
                                />
                            </div>
                            <button
                                className='btn-link m-10'
                                onClick={() => setEdit(bank)}>
                                Edit Bank
                            </button>
                        </div>
                    );
                })}
            {banks.length === 0 && (
                <FormWrapper
                    data={urls.banks}
                    config={{
                        url: urls.banks,
                        items: formItems,
                    }}
                    submitted={handleSubmit}
                />
            )}
            {edit?.id && (
                <FormWrapper
                    data={edit}
                    config={{
                        btn: 'Save & Finish',
                        method: 'PATCH',
                        url: urls.bank_details,
                        items: formItems,
                    }}
                    submitted={handleSubmit}
                />
            )}
            {!edit?.id && banks.length !== 0 && (
                <button
                    onClick={handleSubmit}
                    className='btn'>
                    Finish KYC
                </button>
            )}
        </div>
    );
};

export default KycBank;
