import {
    date,
    dateFromEpoch,
    dateTimeFromEpoch,
    humanizedDue,
} from './datetime';
import { getValue } from './json';
import { amount, beautify } from './str';

const context = {
    amount: (args) => {
        return amount(args[0], args[1]);
    },
    beautify: (args) => {
        return beautify(args[0], args[1]);
    },
    humanizedDue: (args) => {
        return humanizedDue(args[0], args[1]);
    },
    dateTimeFromEpoch: (args) => {
        return dateTimeFromEpoch(args[0], args[1]);
    },
    dateFromEpoch: (args) => {
        return dateFromEpoch(args[0], args[1]);
    },
    date: (args) => {
        return date(args[0], args[1], args[2]);
    },
    filesize: (args) => {
        const size = args[0];
        const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (
            (size / Math.pow(1024, i)).toFixed(2) * 1 +
            ' ' +
            ['Bytes', 'KB', 'MB', 'GB', 'TB'][i]
        );
    },
    options: (args) => {
        const options = [];
        if (args[0] && typeof args[0][0] === 'object') {
            args[0].forEach((option) => {
                const value = getValue(option, args[1] || 'value');
                let label = '';

                if (typeof args[2] === 'object') {
                    const labelChunks = [];
                    args[2].forEach((key) => {
                        if (getValue(option, key)) {
                            labelChunks.push(
                                key.indexOf('amount') !== -1
                                    ? amount(getValue(option, key))
                                    : getValue(option, key),
                            );
                        }
                    });
                    label = labelChunks.join(' - ');
                } else {
                    label = getValue(option, args[2] || 'name');
                }

                if (!label) {
                    label = value;
                }
                options.push({
                    value,
                    label,
                });
            });
        } else if (args[0]) {
            args[0].forEach((option) => {
                options.push({
                    [args[1] || 'value']: option,
                    [args[2] || 'label']: transform('beautify', option),
                });
            });
        }
        return options;
    },
};

export const transform = function (name) {
    const args = Array.prototype.slice.call(arguments, 1);
    return context[name](args);
};
