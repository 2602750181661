import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Loader from '../../../components/atoms/Loader';
import LoaderAnimated from '../../../components/atoms/LoaderAnimated';
import PageHeader from '../../../components/molecules/PageHeader';
import DetailView from '../../../components/organisms/DetailView';
import ListView from '../../../components/organisms/ListView';
import { searchArray } from '../../../helpers/json';
import { getGstAnalyticsData } from '../../../services/_crm.service';

const GstAnalyticsStatus = () => {
    const [report, setReport] = useState({});
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();

    const initData = async () => {
        setLoading(true);
        const response = await getGstAnalyticsData(
            searchParams.get('request_id'),
        );
        setReport(response);
        setLoading(false);
    };

    useEffect(() => {
        if (searchParams.has('request_id')) {
            initData();
        }
    }, [searchParams]);

    return (
        <div>
            <div className='container'>
                {loading && <Loader loading={loading} />}
                {!loading && report?.data?.result?.queued && (
                    <>
                        <LoaderAnimated
                            loading={true}
                            name='searching'
                            title='Please wait, Your GST Report is being fetched.'>
                            <button
                                onClick={initData}
                                className='btn'>
                                Check Again!
                            </button>
                        </LoaderAnimated>
                    </>
                )}
                {!report?.data?.result?.queued && (
                    <>
                        <PageHeader
                            className='p-0 pb-20'
                            subtitle={`${report?.data?.result?.report_details?.entity} (${report?.data?.result?.report_details?.from} -
                    ${report?.data?.result?.report_details?.to})`}>
                            {report?.data?.result?.report_details?.gstin}
                        </PageHeader>
                        <DetailView
                            details={searchArray(
                                report?.data?.result?.overview,
                                'month',
                                'Total',
                            )}
                            config={{
                                items: [
                                    {
                                        name: 'sales',
                                        transform: 'amount',
                                    },
                                    {
                                        name: 'purchase',
                                        transform: 'amount',
                                    },
                                    {
                                        name: 'gross_profit',
                                        transform: 'amount',
                                    },
                                ],
                            }}
                        />
                        <h4 className='mt-20'>Your Top 3 Suppliers</h4>
                        <ListView
                            config={{
                                data: report?.data?.result?.invoices?.suppliers.slice(
                                    0,
                                    3,
                                ),
                                items: [
                                    {
                                        type: 'heading',
                                        name: 'sum',
                                        transform: 'amount',
                                    },
                                    {
                                        type: 'items',
                                        name: 'name',
                                        items: [
                                            {
                                                name: 'name',
                                            },
                                        ],
                                    },
                                ],
                            }}
                        />
                        <Link
                            className='btn'
                            target='_blank'
                            to={report?.data?.result?.files?.pdf}>
                            View PDF Report
                        </Link>
                    </>
                )}
            </div>
        </div>
    );
};
export default GstAnalyticsStatus;
