import * as am5 from '@amcharts/amcharts5';
import * as am5radar from '@amcharts/amcharts5/radar';
import am5themes_Dark from '@amcharts/amcharts5/themes/Dark';
import * as am5xy from '@amcharts/amcharts5/xy';
import React, { useEffect } from 'react';
import { amount } from '../../../../helpers/str';
import { perc } from '../../../../helpers/maths';
import DetailView from '../../../../components/organisms/DetailView';

const LeadAccount = () => {
    useEffect(() => {
        const root = am5.Root.new('radarChart');
        root.setThemes([am5themes_Dark.new(root)]);
        root._logo.dispose();
        const chart = root.container.children.push(
            am5radar.RadarChart.new(root, {
                panX: false,
                panY: false,
                wheelX: false,
                wheelY: false,
                innerRadius: am5.percent(20),
                startAngle: -90,
                endAngle: 180,
            }),
        );
        const data = [
            {
                category: `Paid - ${amount(25000)}`,
                value: perc(25000, 125600),
                full: 100,
                columnSettings: {
                    fill: '#0CB559',
                },
            },
            {
                category: `Outstanding - ${amount(100600)}`,
                value: perc(100600, 125600),
                full: 100,
                columnSettings: {
                    fill: '#F1416C',
                },
            },
        ];

        const cursor = chart.set(
            'cursor',
            am5radar.RadarCursor.new(root, {
                behavior: 'zoomX',
            }),
        );

        cursor.lineY.set('visible', false);
        const xRenderer = am5radar.AxisRendererCircular.new(root, {
            // minGridDistance: 50
        });

        xRenderer.labels.template.setAll({
            radius: 10,
        });

        xRenderer.grid.template.setAll({
            forceHidden: true,
        });

        const xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: xRenderer,
                min: 0,
                max: 100,
                strictMinMax: true,
                numberFormat: "#'%'",
                tooltip: am5.Tooltip.new(root, {}),
            }),
        );

        const yRenderer = am5radar.AxisRendererRadial.new(root, {
            minGridDistance: 20,
        });

        yRenderer.labels.template.setAll({
            centerX: am5.p100,
            fontWeight: '500',
            fontSize: 14,
            templateField: 'columnSettings',
        });

        yRenderer.grid.template.setAll({
            forceHidden: true,
        });

        const yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: 'category',
                renderer: yRenderer,
            }),
        );

        yAxis.data.setAll(data);

        const series1 = chart.series.push(
            am5radar.RadarColumnSeries.new(root, {
                xAxis,
                yAxis,
                clustered: false,
                valueXField: 'full',
                categoryYField: 'category',
                fill: root.interfaceColors.get('alternativeBackground'),
            }),
        );

        series1.columns.template.setAll({
            width: am5.p100,
            fillOpacity: 0.08,
            strokeOpacity: 0,
            cornerRadius: 20,
        });

        series1.data.setAll(data);

        const series2 = chart.series.push(
            am5radar.RadarColumnSeries.new(root, {
                xAxis,
                yAxis,
                clustered: false,
                valueXField: 'value',
                categoryYField: 'category',
            }),
        );

        series2.columns.template.setAll({
            width: am5.p100,
            strokeOpacity: 0,
            tooltipText: '{category}: {valueX}%',
            cornerRadius: 20,
            templateField: 'columnSettings',
        });

        series2.data.setAll(data);
        series1.appear(1000);
        series2.appear(1000);
        chart.appear(1000, 100);
        return () => {
            if (root) {
                root.dispose();
            }
        };
    }, []);
    return (
        <div className='card'>
            <div className='card__body p-15'>
                <div id='radarChart'></div>
                <h4>Account Summary</h4>
                <DetailView
                    className='mt-10'
                    details={{
                        approved: 5000000,
                        used: 100600,
                        disbursed: 206699,
                        paid: 25000,
                    }}
                    config={{
                        items: [
                            {
                                name: 'approved',
                                label: 'Approved Credit Line',
                                transform: 'amount',
                            },
                            {
                                name: 'used',
                                label: 'Used Limit',
                                transform: 'amount',
                            },
                            {
                                name: 'disbursed',
                                label: 'Total Disbursed',
                                transform: 'amount',
                            },
                            {
                                name: 'paid',
                                label: 'Total Paid',
                                transform: 'amount',
                            },
                        ],
                    }}
                />
            </div>
        </div>
    );
};

export default LeadAccount;
