import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoanDetails from './LoanDetails';

const Loans = () => {
    return (
        <Routes>
            <Route
                path='/:id'
                element={<LoanDetails />}></Route>
        </Routes>
    );
};

export default Loans;
