import React from 'react';
import Image from '../../../components/atoms/Image';
import { beautify } from '../../../helpers/str';

const KycSummaryDocuments = ({ docs }) => {
    return (
        <>
            <h5>Documents</h5>
            <div className='uploader'>
                {Object.entries(docs).map(([key, doc]) => {
                    return ['Photograph', 'Signature'].indexOf(key) === -1 ? (
                        <>
                            {['jpg', 'png', 'jpeg'].indexOf(
                                doc?.image_type?.toLowerCase(),
                            ) === -1 && (
                                <label
                                    key={key}
                                    className={`uploader__field ${key.toLowerCase()}`}>
                                    {beautify(key)}
                                    &nbsp;
                                    <small>
                                        <a
                                            className='color-link'
                                            target='_blank'
                                            rel='noreferrer'
                                            href={`data:application/pdf;base64, ${doc?.image_data}`}>
                                            View File
                                        </a>
                                    </small>
                                </label>
                            )}
                            {['jpg', 'png', 'jpeg'].indexOf(
                                doc?.image_type?.toLowerCase(),
                            ) !== -1 && (
                                <label
                                    key={key}
                                    className={`uploader__field uploader__field-image ${key.toLowerCase()}`}>
                                    <Image
                                        className={`uploader__image`}
                                        src={`data:image/${doc?.image_type?.toLowerCase()};base64, ${
                                            doc?.image_data
                                        }`}
                                    />
                                    {beautify(key)}
                                </label>
                            )}
                        </>
                    ) : (
                        <></>
                    );
                })}
            </div>
        </>
    );
};

export default KycSummaryDocuments;
