import React from 'react';
import Image from '../atoms/Image';
import PageHeader from './PageHeader';

const MessageView = ({ img, heading, children }) => {
    return (
        <div className='message'>
            {img && <Image src={img} />}
            <h2>{heading}</h2>
            <p>{children}</p>
        </div>
    );
};

export default MessageView;
