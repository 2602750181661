import { Route, Routes } from 'react-router-dom';
import ItrAnalyticsInit from './ItrAnalyticsInit';
import ItrAnalyticsStatus from './ItrAnalyticsStatus';

const ItrAnalytics = () => {
    return (
        <Routes>
            <Route
                index
                element={<ItrAnalyticsStatus />}></Route>
            <Route
                path='initiate'
                element={<ItrAnalyticsInit />}></Route>
        </Routes>
    );
};
export default ItrAnalytics;
