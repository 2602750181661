import axios from 'axios';
import { buildUrl } from '../helpers/str';

export const BASE_URL =
    (process.env.REACT_APP_API_ROOT_STORAGE ||
        process.env.REACT_APP_API_ROOT_DEFAULT) + '/plugins/storage/';

export const urls = {
    storage_documents_details: BASE_URL + 'documents/:id/',
    storage_file: BASE_URL + 'files/',
};

export const docDetails = async (id) => {
    const { data } = await axios.get(
        buildUrl(urls.storage_documents_details, {
            id,
        }),
    );
    return data?.response_data;
};

export const docUrl = async (file_path) => {
    const { data } = await axios.get(
        buildUrl(urls.storage_file, {
            file_path,
        }),
    );
    return data?.url;
};
