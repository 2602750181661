import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../../context/UserContext';
import { searchArray } from '../../../helpers/json';
import {
    generateAgreement,
    listAgreements,
} from '../../../services/_los.service';
import { routes } from '../config';
import Loader from '../../../components/atoms/Loader';
import { confirm } from '../../../helpers/alert';

const AgreementsSummary = () => {
    const { user } = useUser();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [agreements, setAgreements] = useState([]);
    const [agreement, setAgreement] = useState({});
    const [esign, setEsign] = useState({});
    const UNDERTAKING_FORM = 'Undertaking Form';
    const SANCTION_LETTER = 'Sanction Letter';
    const LOAN_AGREEMENT = 'Loan Agreement';
    const STAMP = 'Stamped Agreement';
    const SIGNED_AGREEMENT = 'Signed Agreement';
    const agreementOrder = [
        UNDERTAKING_FORM,
        SANCTION_LETTER,
        LOAN_AGREEMENT,
        STAMP,
        SIGNED_AGREEMENT,
    ];

    const initAgreements = async () => {
        if (user?.service_accounts?.los?.id) {
            const response = await listAgreements(
                user?.service_accounts?.los?.id,
            );
            setAgreements(response);
            setEsign(searchArray(response, 'type', 'signed_agreement'));
            agreementOrder.forEach((item, index) => {
                const doc = searchArray(response, 'name', item);
                if (doc) {
                    setStep(index + 1);
                    if (!agreement.id && item === STAMP) {
                        doc.name = 'Credit Line Agreement';
                        setAgreement(doc);
                    }
                    if (!agreement.id && item === SIGNED_AGREEMENT) {
                        setAgreement(doc);
                    }
                }
            });
        }
    };

    const handleGenerate = async () => {
        if (agreements.length === 0) {
            confirm(
                'Accept Credit Line',
                'Do you really want to accept credit line and generate agreement for signing?',
                async () => {
                    setLoading(true);
                    await generateAgreement(user?.service_accounts?.los?.id);
                    navigate(routes.agreements);
                    setLoading(false);
                },
            );
        } else {
            navigate(routes.agreements);
        }
    };

    useEffect(() => {
        initAgreements();
    }, []);
    return (
        <div className='mt-10'>
            <Loader loading={loading} />
            {esign?.sign_status !== 'success' && (
                <div className='card mt-20 bg-red-dark p-20'>
                    <h4>
                        <span className='ml-5 color-red'>eSign Pending</span>
                    </h4>
                    <small>
                        Your credit line is ready for disbursal, Please eSign
                        your credit line agreement to withdraw money to your
                        account.
                    </small>
                    <button
                        className='btn bg-red mt-10'
                        onClick={handleGenerate}>
                        Start Agreement eSigning
                    </button>
                </div>
            )}
        </div>
    );
};

export default AgreementsSummary;
