import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/atoms/Loader';
import FormView from '../../components/organisms/FormView';
import { useUser } from '../../context/UserContext';
import {
    createAddress,
    getLead,
    updateApplicant,
} from '../../services/_crm.service';
import KycFormAddress from './KycForm/KycFormAddress';
import { routes } from './config';

const KycBusiness = () => {
    const { user } = useUser();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [lead, setLead] = useState({});
    const [address, setAddress] = useState({});

    const initLead = async () => {
        const response = await getLead(user.lead_id);
        setLead(response);
    };

    const handleAddressUpdated = (response) => {
        setAddress(response);
    };

    const handleUpdated = (response) => {
        const tmp = lead;
        tmp.business_entity = response;
        setLead(tmp);
    };

    const handleSubmit = async () => {
        setLoading(true);
        if (address?.address_line) {
            await createAddress(address);
        }
        await updateApplicant(lead.business_entity.id, lead.business_entity);
        setLoading(false);
        navigate(routes.person);
    };

    useEffect(() => {
        if (user.lead_id) {
            initLead();
        }
    }, []);
    return (
        <div className='container'>
            <h1>Business Information</h1>
            <p className='mb-10'>
                Please fill the following details to complete your KYC.
            </p>
            {loading && <Loader loading={loading} />}
            {lead?.business_entity?.id && (
                <>
                    <FormView
                        updated={handleUpdated}
                        data={lead.business_entity}
                        config={{
                            items: [
                                { type: 'text', name: 'name' },
                                {
                                    type: 'select',
                                    name: 'business_type',
                                    options_src: 'constants',
                                    options: 'business_types',
                                },
                                { type: 'text', name: 'gstin', readonly: true },
                                {
                                    type: 'datepicker',
                                    name: 'date_of_incorporation',
                                    readonly: true,
                                },
                            ],
                        }}
                    />
                    <h4 className='mt-30 mb-5'>Current Business Address</h4>
                    <KycFormAddress
                        onUpdate={handleAddressUpdated}
                        applicant={lead?.business_entity}
                    />
                    <button
                        onClick={handleSubmit}
                        className='btn'
                        disabled={loading}>
                        Proceed
                    </button>
                </>
            )}
        </div>
    );
};

export default KycBusiness;
