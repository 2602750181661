import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { transform } from '../../helpers/transform';
import Tags from '../atoms/Tags';
import SmartLabel from './SmartLabel';
import { addSuffix, amount, buildUrl, parseUrl } from '../../helpers/str';
import { getValue } from '../../helpers/json';
import { perc } from '../../helpers/maths';
const DetailView = ({
    config,
    details,
    alwaysShowAll = false,
    className,
    noParent = false,
}) => {
    const [showAll, setShowAll] = useState(false);
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        if (!copied) {
            return;
        }
        setTimeout(() => {
            setCopied(false);
        }, 1500);
    }, [copied]);
    useEffect(() => {}, [config, details]);
    return (
        <div className={`${noParent ? '' : 'list list-bg'} ${className || ''}`}>
            {config?.items?.map((item, index) => {
                const template = [];
                let value = getValue(details, item.as ? item.as : item.name);
                if (item.when) {
                    const when = getValue(details, item.when);
                    if (when === false) {
                        return <React.Fragment key={index}></React.Fragment>;
                    }
                }
                if (
                    Array.isArray(value) &&
                    typeof value[0] !== 'object' &&
                    item.template !== 'tags' &&
                    item.template !== 'sublist'
                ) {
                    value = value.join(', ');
                }
                if (item.transform) {
                    value = transform(
                        item.transform,
                        details && value !== undefined ? value : item.value,
                    );
                } else {
                    value = details && value ? value : item.value;
                }
                if (!item.transform && item.type === 'amount') {
                    value = amount(value);
                }
                if (item.suffixCol) {
                    value = addSuffix(details, value, item.suffixCol);
                }
                const label = item.label || transform('beautify', item.name);
                if (Array.isArray(item.name)) {
                    template.push(
                        <div
                            key={`list__item__${item.name.join('_')}`}
                            className='list__item'>
                            <div className='list__item__key'>{label}</div>
                            <div className='list__item__value'>
                                {item.name.map((col) => {
                                    return (
                                        <span
                                            className='me-1'
                                            key={`list__item__${item.name.join(
                                                '_',
                                            )}_${col}`}>
                                            {item.transform
                                                ? transform(
                                                      item.transform,
                                                      getValue(details, col),
                                                  )
                                                : getValue(details, col)}
                                        </span>
                                    );
                                })}
                            </div>
                        </div>,
                    );
                } else if (item.template === 'icon') {
                    template.push(
                        <div
                            key={`list__item__${item.name}`}
                            className='list__item'>
                            <div className='symbol symbol-50px me-3'>
                                <span className='symbol-label bg-light-danger'>
                                    <i className={`ri ri-${item.icon}`}></i>
                                </span>
                            </div>
                            <div className=''>
                                <div className='list__item__key'>{label}</div>
                                <div className='list__item__value'>{value}</div>
                            </div>
                        </div>,
                    );
                } else if (item.template === 'smart_label') {
                    template.push(
                        <div
                            key={`list__item__${item.name}`}
                            className='d-flex align-items-center py-3 px-5 border-bottom'>
                            <div className='symbol symbol-50px me-3'>
                                <span className='symbol-label bg-light-danger'>
                                    <i className={`ri ri-${item.icon}`}></i>
                                </span>
                            </div>
                            <div className='list__item'>
                                <div className='list__item__key'>{label}</div>
                                <div className='list__item__value'>
                                    <SmartLabel
                                        url={buildUrl(item.url, {
                                            id: details[item.id],
                                        })}
                                        config={{
                                            heading: item.heading,
                                            desc: item.desc,
                                            tag: item.tag,
                                        }}
                                        className='text-gray-500 d-flex fs-8'
                                    />
                                </div>
                            </div>
                        </div>,
                    );
                } else if (item.template === 'sublist') {
                    value.forEach((val, index) => {
                        template.push(
                            <div
                                key={`list__item__${item.name}_${index}`}
                                className='list__item'>
                                <div className='list__item__key'>{label}</div>
                                <div className='list__item__value'>
                                    {val.name}
                                </div>
                            </div>,
                        );
                    });
                } else if (item.template === 'progress') {
                    template.push(
                        <div
                            key={`list__item__${item.name}`}
                            className='d-flex flex-column py-3 px-5 border-bottom'>
                            <div className='d-flex justify-content-between w-100 fs-4 fw-bold mb-3'>
                                <span>{label}</span>
                                <span>
                                    {value}
                                    &nbsp; of &nbsp;
                                    {item.transform
                                        ? transform(
                                              item.transform,
                                              getValue(details, item.max),
                                          )
                                        : getValue(details, item.max)}
                                </span>
                            </div>
                            <div className='h-8px bg-light rounded mb-3'>
                                <div
                                    className='bg-success rounded h-8px'
                                    role='progressbar'
                                    style={{
                                        width: perc(
                                            getValue(details, item.name),
                                            getValue(details, item.max),
                                        ),
                                    }}
                                    aria-valuenow={perc(
                                        getValue(details, item.name),
                                        getValue(details, item.max),
                                    )}
                                    aria-valuemin='0'
                                    aria-valuemax='100'></div>
                            </div>
                            <div className='fw-semibold text-gray-600'>
                                {item.descriptionPrefix}
                                {transform(
                                    item.transform,
                                    getValue(details, item.description),
                                )}
                                {item.descriptionSuffix}
                            </div>
                        </div>,
                    );
                } else if (item.template === 'tags') {
                    template.push(
                        <div
                            key={`list__item__${item.name}`}
                            className='list__item__value'>
                            <Tags list={value} />
                        </div>,
                    );
                } else if (item.template === 'paragraph') {
                    template.push(
                        <div
                            key={`list__item__${item.name}`}
                            className='py-3 px-5 border-bottom'>
                            <Tags list={item.tags} />
                            <div className='mt-2'>
                                <span className='fs-4 fw-bold mb-1 text-gray-900 text-hover-primary'>
                                    {label}
                                </span>
                                <div className='fw-semibold text-gray-600 mb-5'>
                                    {value}
                                </div>
                            </div>
                        </div>,
                    );
                } else {
                    template.push(
                        <div
                            key={`list__item__${item.name}`}
                            className='list__item'>
                            <div className='list__item__key'>{label}</div>
                            <div className='list__item__value'>
                                {item.copyValue && (
                                    <CopyToClipboard
                                        text={value}
                                        onCopy={() => setCopied(true)}>
                                        <span role='button'>
                                            <i
                                                className={`ri ri-file-copy-2-fill ${
                                                    copied
                                                        ? 'success'
                                                        : 'warning'
                                                }`}></i>
                                            {copied && (
                                                <small className='text-success me-2'>
                                                    Copied!
                                                </small>
                                            )}
                                        </span>
                                    </CopyToClipboard>
                                )}
                                {item.path && (
                                    <a
                                        rel='noreferrer'
                                        target={item.target}
                                        href={parseUrl(item.path, details)}
                                        className={`fw-bold text-hover-primary fs-6 text-primary`}>
                                        {value}
                                    </a>
                                )}
                                {!item.path && <>{value}</>}
                            </div>
                        </div>,
                    );
                }
                return (alwaysShowAll ||
                    showAll ||
                    item.is_required ||
                    value) &&
                    !item.writeOnly ? (
                    <React.Fragment key={`list__${item.name}`}>
                        {template}
                    </React.Fragment>
                ) : (
                    ''
                );
            })}
        </div>
    );
};
export default DetailView;
