import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import { searchArray } from '../helpers/json';
import { getServiceAccounts } from '../services/_crm.service';
import { getProgramConfig } from '../services/_datum.service';
import {
    changeProfile,
    getProfiles,
    verifyOtp,
} from '../services/_identity.service';

const LoginJupiter = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { loginUser } = useUser();

    const initLogin = async () => {
        const mobile = searchParams.get('mobile');
        const otp = mobile.slice(4, 10);
        const program_id = searchParams.get('program_id');
        const response = await verifyOtp({
            mobile,
            otp,
            bypass: true,
            program_id,
        });
        if (response.access_token) {
            localStorage.setItem(
                process.env.REACT_APP_LOCAL_STORAGE_AUTH,
                response.access_token,
            );
            initProfiles(program_id);
        }
    };

    const initProfiles = async (program_id) => {
        const response = await getProfiles();
        const profile = searchArray(response, 'program_id', program_id);
        switchProfile(profile);
    };

    const switchProfile = async (profile) => {
        const payload = {
            lead_id: profile.lead_id,
            program_id: profile.program_id,
        };
        const response = await changeProfile(payload);
        if (response?.access_token) {
            localStorage.setItem(
                process.env.REACT_APP_LOCAL_STORAGE_AUTH,
                response.access_token,
            );

            const tmp = {};
            const serviceAccounts = await getServiceAccounts(
                payload.lead_id,
                payload.program_id,
            );
            Object.entries(serviceAccounts).forEach(([key, value]) => {
                if (value?.length) {
                    tmp[key] = value[0];
                }
            });
            payload.service_accounts = tmp;

            payload.program_config = await getProgramConfig(payload.program_id);

            loginUser(payload);
            navigate('/');
        }
    };

    useEffect(() => {
        if (searchParams.get('mobile')) {
            initLogin();
        }
    }, []);
};

export default LoginJupiter;
