import React from 'react';
import { dateTimeFromEpoch } from '../../../helpers/datetime';
import { beautify } from '../../../helpers/str';
import { Link } from 'react-router-dom';

const SignedAgreement = ({ agreement, onRefresh }) => {
    return (
        <>
            <button
                className='uploader__field__refresh btn btn-sm btn-icon btn-circle bg-green'
                onClick={onRefresh}>
                <i className='ri-refresh-line'></i>
            </button>
            <div>
                <small>
                    Initiated At: {dateTimeFromEpoch(agreement.initiated_at)}
                </small>
            </div>
            {/* <div>
                <small
                    className={
                        agreement.sign_status === 'success'
                            ? 'color-green'
                            : 'color-red'
                    }>
                    Status: {beautify(agreement.sign_status || 'pending')}
                </small>
            </div> */}
            {agreement?.esign?.response_data?.invitees
                ?.splice(0, 1)
                .map((invitee) => {
                    return (
                        <React.Fragment key={invitee.signUrl}>
                            {['success', 'signed'].indexOf(
                                invitee.signer_status,
                            ) !== -1 ? (
                                <div className='color-green my-10'>
                                    Document Signed
                                </div>
                            ) : (
                                <Link
                                    to={invitee.signUrl}
                                    target='_blank'
                                    className='mt-10 btn'>
                                    Start eSign for {invitee.name}
                                </Link>
                            )}
                        </React.Fragment>
                    );
                })}
        </>
    );
};

export default SignedAgreement;
