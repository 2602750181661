import React from 'react';
import { useNavigate } from 'react-router-dom';

const LeadStatus = ({ root }) => {
    const navigate = useNavigate();
    const stages = [
        {
            name: '1. Business Details',
            icon: 'ri-checkbox-circle-fill',
            iconClass: 'green',
            path: 'kyc/business',
        },
        {
            name: '2. Authorized Signatory Details',
            icon: 'ri-progress-7-fill',
            iconClass: 'yellow',
        },
        {
            name: '3. KYC Documents',
            icon: 'ri-progress-3-fill',
            iconClass: 'yellow',
        },
        {
            name: '4. Bank Details',
            icon: 'ri-error-warning-fill',
            iconClass: 'red',
            desc: 'Penny Drop Verification Failed',
        },
        {
            name: '5. Property Details',
            icon: 'ri-progress-1-fill',
            path: 'property',
        },
        {
            name: '6. FI - Not Started',
            icon: 'ri-flag-fill',
            path: 'fi',
        },
        { name: '7. Activation', icon: 'ri-flag-fill', class: 'opacity-50' },
    ];

    const handleClick = (request) => {
        console.log(root, request.path);
        navigate(root + '/' + request.path);
    };

    return (
        <>
            {stages.map((stage) => {
                return (
                    <div
                        className={`card cursor-pointer clickable p-15 mb-10 w-100 ${stage.class}`}
                        key={stage.name}
                        onClick={() => handleClick(stage)}>
                        <div className='flex justify-between align-center'>
                            <div>
                                <div className='flex align-center'>
                                    <i
                                        className={`${stage.icon} color-${stage.iconClass} mr-5 h3`}></i>
                                    {stage.name}
                                </div>
                                {stage.desc && (
                                    <small className='pl-30 color-yellow'>
                                        {stage.desc}
                                    </small>
                                )}
                            </div>
                            <i className='ri-arrow-right-line color-secondary h4'></i>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default LeadStatus;

// TODO: FI -> Details: Address, Contact Details | Form: Lat/Lng, Remark, Document, Status: Verified, Not Verified
// TODO: Valuation -> Details: Ref. Document, Remark, Amount
