import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccordionItem from '../../../components/organisms/AccordionItem';
import { useUser } from '../../../context/UserContext';
import { alertError } from '../../../helpers/alert';
import { searchArray } from '../../../helpers/json';
import { beautify } from '../../../helpers/str';
import { getLead, validateLead } from '../../../services/_crm.service';
import { routes } from '../../BorrowerApp/config';
import KycFormAddress from './KycFormAddress';
import KycFormBank from './KycFormBank';
import KycFormDocuments from './KycFormDocuments';
import KycFormBasic from './KycFormBasic';

const KycForm = () => {
    const { user } = useUser();
    const navigate = useNavigate();
    const [lead, setLead] = useState({});
    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (index) => {
        setActiveIndex(index);
    };

    const goToNext = () => {
        setActiveIndex(activeIndex + 1);
    };

    const initLead = async () => {
        const response = await getLead(user.lead_id);
        setLead(response);
    };

    const handleValidate = async () => {
        try {
            const response = await validateLead(user.lead_id);
            if (response.status === 'kyc_completed') {
                navigate(routes.home);
            }
        } catch (error) {
            const desc = error?.response?.data?.description;
            if (desc && desc?.[0]) {
                const applicant = searchArray(
                    lead.applicants,
                    'id',
                    desc[0].applicant_id,
                );
                alertError(
                    `Please fill ${beautify(
                        desc[0].missing_fields.join(', '),
                    )}`,
                    `Information Missing for ${applicant.name}`,
                );
            } else {
                alertError(error?.response?.data?.message);
            }
        }
    };

    useEffect(() => {
        if (user.lead_id) {
            initLead();
        }
    }, []);

    const items = [
        { key: 'basic', title: 'Basic Details' },
        { key: 'documents', title: 'Documents' },
        { key: 'address', title: 'Address' },
        { key: 'bank', title: 'Bank' },
    ];

    return (
        <div className='container'>
            {user?.lead_id && (
                <>
                    <div className='accordion'>
                        {items.map((item, index) => {
                            return (
                                <AccordionItem
                                    onClick={handleClick}
                                    title={item.title}
                                    index={index}
                                    key={index}
                                    activeIndex={activeIndex}
                                    showIndex={true}>
                                    {item.key === 'basic' && (
                                        <KycFormBasic lead={lead} />
                                    )}
                                    {item.key === 'documents' && (
                                        <KycFormDocuments
                                            applicant_id={
                                                lead?.person_entity?.id
                                            }
                                        />
                                    )}
                                    {item.key === 'address' &&
                                        lead?.business_entity?.id && (
                                            <KycFormAddress
                                                applicant={
                                                    lead?.business_entity
                                                }
                                            />
                                        )}
                                    {item.key === 'bank' && (
                                        <KycFormBank
                                            applicant_id={
                                                lead?.business_entity?.id
                                            }
                                        />
                                    )}
                                </AccordionItem>
                            );
                        })}
                    </div>
                    <button
                        onClick={handleValidate}
                        className='btn bg-green mt-15'>
                        Submit KYC
                    </button>
                </>
            )}
        </div>
    );
};

export default KycForm;
