import axios from 'axios';
import { buildUrl } from '../helpers/str';

const BASE_URL =
    (process.env.REACT_APP_API_ROOT_PAYMENTS ||
        process.env.REACT_APP_API_ROOT_DEFAULT) + '/plugins/payment/';

export const urls = {
    payment_details: BASE_URL + ':id/',
};

export const getPayment = async (id, is_refresh = false) => {
    const { data } = await axios.get(buildUrl(urls.payment_details, { id }), {
        params: { is_refresh },
    });
    return data;
};
