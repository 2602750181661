import React, { useEffect, useState } from 'react';
import AccordionItem from '../../../components/organisms/AccordionItem';
import FormWrapper from '../../../components/organisms/FormView/FormWrapper';
import { useUser } from '../../../context/UserContext';
import {
    createAddress,
    getCkycProfile,
    getLead,
    updateApplicant,
    uploadCkycImages,
    urls,
} from '../../../services/_crm.service';
import { useNavigate } from 'react-router-dom';
import { date } from '../../../helpers/datetime';
import Loader from '../../../components/atoms/Loader';
import { routes } from '../config';

const KycPersonalDetails = () => {
    const { user } = useUser();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [lead, setLead] = useState({});
    const [applicants, setApplicants] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (index) => {
        setActiveIndex(index);
    };
    const initLead = async () => {
        const response = await getLead(user.lead_id);
        const tmp = [];
        response?.applicants?.forEach((applicant) => {
            if (applicant.applicant_type === 'person') {
                tmp.push(applicant);
            }
        });
        setApplicants(tmp);
        setLead(response);
    };
    useEffect(() => {
        if (user.lead_id) {
            initLead();
        }
    }, []);

    const handleSubmit = async (applicant) => {
        if (applicant) {
            setLoading(true);
            const response = await getCkycProfile(
                applicant.name,
                applicant.primary_mobile,
            );
            localStorage.setItem('ckyc', JSON.stringify(response));
            const ckyc =
                response?.ckyc_prefill?.CKYC_SEARCH_AND_DOWNLOAD
                    ?.personal_details;
            if (ckyc) {
                if (ckyc?.father_fname) {
                    applicant.source = 'DECENTRO_CKYC_PREFILL';
                    applicant.father_name = ckyc?.father_fname;
                }
                if (ckyc?.father_lname) {
                    applicant.source = 'DECENTRO_CKYC_PREFILL';
                    applicant.father_name += ' ' + ckyc?.father_lname;
                }

                if (ckyc?.dob) {
                    applicant.dob = date(ckyc?.dob, 'YYYY-MM-DD', 'DD-MM-YYYY');
                    applicant.source = 'DECENTRO_CKYC_PREFILL';
                }

                Object.entries({
                    gender: 'gender',
                    pan: 'pan_no',
                }).forEach(([key, value]) => {
                    if (ckyc?.[key]) {
                        applicant.source = 'DECENTRO_CKYC_PREFILL';
                        applicant[value] = ckyc?.[key];
                    }
                });
                await updateApplicant(applicant.id, applicant);
                await saveAddresses(applicant.id, ckyc);
                await uploadCkycImages({
                    name: applicant?.name,
                    primary_mobile:
                        applicant?.primary_mobile ||
                        lead?.business_entity?.primary_mobile,
                    applicant_id: applicant.id,
                    lead_id: lead?.id,
                });
            }
            setLoading(false);
            if (
                activeIndex === applicants.length - 1 ||
                applicants.length === 0
            ) {
                navigate(routes.person);
            } else {
                setActiveIndex(activeIndex + 1);
            }
        }
    };

    const saveAddresses = async (id, ckyc) => {
        const addresses = [];
        const comm_address = await makeAddress(id, ckyc, 'corres', true);
        if (comm_address.address_line) {
            addresses.push(comm_address);
        }
        const permanent_address = await makeAddress(
            id,
            ckyc,
            'perm',
            !comm_address?.address_line,
        );
        if (
            comm_address.address_line &&
            comm_address?.address_line !== permanent_address.address_line
        ) {
            addresses.push(permanent_address);
        }
        return addresses;
    };

    const makeAddress = async (id, data, type, is_current = false) => {
        const address = {
            source: 'DECENTRO_CKYC_PREFILL',
            applicant_id: id,
            tags: is_current ? ['current'] : [],
            address_line:
                data?.[`${type}_line1`] + ' ' + data?.[`${type}_dist`],
            city: data?.[`${type}_city`],
            state: data?.[`${type}_state`],
            pincode: data?.[`${type}_pin`],
        };
        await createAddress(address);
        return address;
    };

    return (
        <div className='container'>
            <h1>Person Details</h1>
            <p>Provide following information to start KYC</p>
            <Loader loading={loading} />
            <div className='accordion mt-20'>
                {applicants?.length === 0 && (
                    <FormWrapper
                        config={{
                            url: urls.applicants,
                            items: [
                                {
                                    type: 'hidden',
                                    name: 'lead_id',
                                    value: lead?.id,
                                },
                                {
                                    type: 'hidden',
                                    name: 'is_authorized_signatory',
                                    value: true,
                                },
                                { name: 'name' },
                                { name: 'pan_no' },
                                {
                                    type: 'number',
                                    name: 'primary_mobile',
                                },
                            ],
                        }}
                        submitted={handleSubmit}
                    />
                )}
                {applicants?.map((applicant, index) => {
                    return (
                        <AccordionItem
                            title={applicant.name}
                            index={index}
                            key={index}
                            activeIndex={activeIndex}>
                            <FormWrapper
                                data={applicant}
                                config={{
                                    url: urls.applicant_details,
                                    method: 'PATCH',
                                    items: [
                                        { name: 'pan_no' },
                                        {
                                            type: 'number',
                                            name: 'primary_mobile',
                                            value: applicant.is_authorized_signatory
                                                ? applicant?.primary_mobile
                                                : '',
                                        },
                                    ],
                                }}
                                submitted={handleSubmit}
                            />
                        </AccordionItem>
                    );
                })}
            </div>
        </div>
    );
};

export default KycPersonalDetails;
