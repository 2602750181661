import Lottie from 'lottie-react';
import React, { Suspense, useEffect, useState } from 'react';

const LoaderAnimated = ({
    className = '',
    loading = true,
    name = 'searching',
    loop = true,
    title = '',
    children,
}) => {
    const [Animation, setAnimation] = useState(null);

    useEffect(() => {
        const importAnimation = async () => {
            const module = await import(`../../assets/animations/${name}.json`);
            setAnimation(() => module.default);
        };

        importAnimation();
    }, [name]);

    return (
        <>
            {loading && (
                <div className={`loader ${className}`}>
                    {Animation ? (
                        <>
                            <div className='loader__animation'>
                                <Lottie
                                    animationData={Animation}
                                    loop={loop}
                                />
                            </div>
                            <h5 className='loader__text'>{title}</h5>
                            <div className='w-100 mt-10'>{children}</div>
                        </>
                    ) : (
                        <Suspense fallback={<div>Loading...</div>}>
                            {/* Show a loading state while the animation is being loaded */}
                            <div>Loading animation...</div>
                        </Suspense>
                    )}
                </div>
            )}
        </>
    );
};

export default LoaderAnimated;
