export const root = '/kyc';
export const BASE_URL = process.env.REACT_APP_API_ROOT_DEFAULT + root + '/';

export const routes = {
    home: root + '/',
    summary: root + '/summary/',
    business: root + '/business/',
    person: root + '/person/',
    bank: root + '/bank/',
    validate: root + '/validate/',
    personal_details: root + '/personal-details/',
    form: root + '/form/',
};
