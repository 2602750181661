import { Route, Routes } from 'react-router-dom';
import GstAnalyticsInit from './GstAnalyticsInit';
import GstAnalyticsValidate from './GstAnalyticsValidate';
import GstAnalyticsStatus from './GstAnalyticsStatus';

const GstAnalytics = () => {
    return (
        <Routes>
            <Route
                index
                element={<GstAnalyticsStatus />}></Route>
            <Route
                path='initiate'
                element={<GstAnalyticsInit />}></Route>
            <Route
                path='validate'
                element={<GstAnalyticsValidate />}></Route>
        </Routes>
    );
};
export default GstAnalytics;
