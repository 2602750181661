import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPayment } from '../../../services/_payment.service';
import LoaderAnimated from '../../../components/atoms/LoaderAnimated';
import { amount } from '../../../helpers/str';
import { alertSuccess, toastSuccess } from '../../../helpers/alert';

const RecordsStatus = () => {
    const [searchParams] = useSearchParams();
    const [payment, setPayment] = useState({});

    const initPayment = async (force) => {
        const payment_id = searchParams.get('payment_id');
        if (payment_id) {
            const response = await getPayment(payment_id, force);
            setPayment(response);

            if (force) {
                alertSuccess('Payment status has been refreshed successfully');
            }
        }
    };

    useEffect(() => {
        initPayment();
    }, [searchParams]);

    return (
        <div>
            {payment?.response_data?.txn_msg && (
                <>
                    {payment?.status === 'pending' && (
                        <LoaderAnimated
                            className='loader-static'
                            loading={true}
                            name={'rupee-coin'}
                            title='Your payment is being processed. Please check back later!'
                        />
                    )}
                    {payment?.status === 'success' && (
                        <LoaderAnimated
                            className='loader-static'
                            loading={true}
                            name={'success'}
                            loop={false}
                            title='Repayment completed successfully'
                        />
                    )}
                    <div className='fs-hero-lg text-center'>
                        {amount(payment?.response_data?.txn_amt)}
                    </div>
                    <div className='text-center'>
                        <span>Transaction ID: </span>{' '}
                        {payment?.response_data?.tpsl_txn_id}
                    </div>
                    {payment.status === 'pending' && (
                        <button
                            type='button'
                            className='btn mt-5 btn-fixed'
                            onClick={() => initPayment(true)}>
                            Refresh Payment Status
                        </button>
                    )}
                </>
            )}
        </div>
    );
};

export default RecordsStatus;
