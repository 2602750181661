import React, { useEffect, useRef } from 'react';
import Icon from '../atoms/Icon';

const PopSheet = ({ title, children, onClose, closeOnClick = true }) => {
    const bottomSheetRef = useRef(null);
    const handleClickOutside = (event) => {
        if (
            bottomSheetRef.current &&
            !bottomSheetRef.current.contains(event.target) &&
            closeOnClick
        ) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    }, []);
    return (
        <div
            className='popsheet'
            ref={bottomSheetRef}>
            <div className='popsheet__header'>
                <h4 className='popsheet__header__title'>{title}</h4>
                <div className='popsheet__header__toolbar'>
                    <span onClick={onClose}>
                        <Icon name='close-line' />
                    </span>
                </div>
            </div>
            {children}
        </div>
    );
};

export default PopSheet;
