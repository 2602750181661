import { generatePath } from 'react-router-dom';
import { DICT } from '../consts/dict';
import { flattenExpanded, getValue } from './json';
import Pluralize from 'pluralize';
import { STATES } from '../consts/states';

export const beautify = (str, flag = true) => {
    if (!flag) {
        return str;
    }
    if (str && typeof str === 'string' && str.indexOf('.') !== -1) {
        const chunks = str.split('.');
        str = chunks[chunks.length - 1];
    }
    if (typeof str === 'boolean') {
        return str ? 'Yes' : 'No';
    } else if (str && typeof str === 'string') {
        // Get word from dictionary
        const word = DICT.en[str];
        if (word) {
            return word;
        }

        // If is_, remove and add suffix ?
        if (str.indexOf('is_') === 0) {
            return beautify(str.replace('is_', '') + ' ?');
        }

        // If nothing found
        const arr = str.replace(/_/g, ' ').toLowerCase().split(' ');
        for (let i = 0; i < arr.length; i++) {
            const word = DICT.en[arr[i]];
            if (word) {
                arr[i] = word;
                continue;
            }
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].substring(1);
        }
        return arr.join(' ');
    }
    return '';
};

export const amount = (value, symbol = true) => {
    value = unAmount(value);
    if (value === undefined || value === null || value === '' || isNaN(value)) {
        value = '';
    }
    const formatter = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    };
    if (symbol) {
        formatter.style = 'currency';
        formatter.currency = 'INR';
    }
    value = parseFloat(value);
    const formattedValue = (isNaN(value) ? '' : value).toLocaleString(
        'en-IN',
        formatter,
    );
    return formattedValue.replace(/\.00$/, '');
};

export const amountWords = (num, symbol = true) => {
    let val = 0;
    let mutliplier = 1;
    if (num < 0) {
        mutliplier = -1;
        num *= -1;
    }
    if (num > 999 && num < 100000) {
        val = mutliplier * parseFloat((num / 1000).toFixed(2)) + 'K';
    } else if (num >= 10000000) {
        val = mutliplier * parseFloat((num / 10000000).toFixed(2)) + ' Cr';
    } else if (num >= 100000) {
        val = mutliplier * parseFloat((num / 100000).toFixed(2)) + ' L';
    } else if (num <= 999) {
        val = mutliplier * num;
    }
    return symbol ? '₹' + val : val;
};

export const unAmount = (value) => {
    const symbol = '₹';
    if (
        value === undefined ||
        value === null ||
        value === '' ||
        value === symbol
    ) {
        return '';
    }
    value = value.toString().replaceAll(symbol, '').replaceAll(',', '');
    return parseFloat(value);
};

export const buildUrl = (rootUrl, args = {}, options = {}) => {
    let tmp = { ...args };
    if (!rootUrl) return '';
    let { url, queryParams } = getQueryParams(rootUrl);
    let origin = '';
    try {
        origin = new URL(url).origin;
    } catch (error) {}

    if (origin && url.indexOf(origin) !== -1) {
        url = url.replace(origin, '');
    }

    tmp = flattenExpanded(tmp);
    if (tmp) {
        if (!options.ignoreExtra) {
            Object.keys(tmp).forEach((key) => {
                if (url.indexOf(':' + key) === -1 && tmp[key]) {
                    queryParams[key] = tmp[key];
                }
            });
        }
        url = parseUrl(url, tmp);
        if (url[url.length - 1] !== '/') {
            url += '/';
        }
        if (Object.keys(queryParams).length) {
            url += '?' + new URLSearchParams(queryParams).toString();
        }
    } else if (url[url.length - 1] !== '/') {
        url += '/';
    }

    return origin + url;
};

export const getQueryParams = (url) => {
    const queryParams = {};
    const queryString = url.split('?')[1];
    let baseUrl = url;

    if (queryString) {
        const paramPairs = queryString.split('&');
        paramPairs.forEach((pair) => {
            const [key, value] = pair.split('=');
            queryParams[key] = decodeURIComponent(value);
        });
        baseUrl = url.split('?')[0];
    }

    return { url: baseUrl, queryParams };
};

export const parseUrl = (url, params) => {
    let prefix = '';
    if (url.indexOf(process.env.REACT_APP_API_ROOT_DEFAULT) !== -1) {
        prefix = process.env.REACT_APP_API_ROOT_DEFAULT;
        url = url.replace(process.env.REACT_APP_API_ROOT_DEFAULT, '');
    }

    if (url) {
        const tmp = [];
        url.split('/').forEach((x) => {
            if (x.indexOf(':') !== -1 && x.indexOf('.') !== -1) {
                const k = x.replaceAll('.', '_').replaceAll(':', '');
                params[k] = getValue(params, x.replaceAll(':', ''));
                x = x.replaceAll('.', '_');
            }
            tmp.push(x);
        });
        url = tmp.join('/');
    }
    try {
        if (!url || !params) {
            return '';
        } else if (url.indexOf('~') !== -1) {
            return url.replaceAll('~', params);
        }
        return prefix + generatePath(url, params);
    } catch (error) {
        return url;
    }
};

export const charToNumber = (str, max) => {
    const charCodeSum = str
        .split('')
        .reduce((sum, char) => sum + char.charCodeAt(0), 0);
    return (charCodeSum % max) + 1;
};

export const slugify = (str, delimiter = '_') => {
    if (!str) return '';
    return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, delimiter)
        .replace(/^-+|-+$/g, '');
};

export const addSuffix = (obj, value, suffix) => {
    const suffixMap = {
        percentage: '%',
        fixed: '',
    };
    let suffixValue = getValue(obj, suffixMap[suffix] || suffix);
    suffixValue = suffixMap[suffixValue] || suffixValue;
    if (suffixValue) {
        value += ' ' + suffixValue;
    }
    return value;
};

export const getInitial = (str) => {
    return str?.[0] ? str[0].toUpperCase() : '~';
};

export const plural = (word, count) => {
    return Pluralize(word, count);
};

export const singular = (word) => {
    return Pluralize.singular(word);
};

export const parseAddress = (address) => {
    if (!address) {
        return {};
    }
    address = address.replace(/[,\s]+/g, ' ').trim();

    const pincodeRegex = /\b(\d{6})\b/;
    const pincodeMatch = address.match(pincodeRegex);
    const pincode = pincodeMatch ? pincodeMatch[1] : null;
    const state = STATES.find((state) =>
        address.toLowerCase().includes(state.toLowerCase()),
    );

    const address_line = pincode
        ? address.replace(pincodeRegex, '').trim()
        : null;

    return {
        address_line,
        pincode,
        state,
    };
};

export const filename = (url) => {
    return url.split('/').pop().split('#')[0].split('?')[0];
};

export const businessTypeFromDocument = (number) => {
    if (number?.length === 15) {
        return number[5];
    }
    if (number?.length === 10 && number?.[3] !== 'P') {
        return number[3];
    }
};

export const getPersonTag = (businessType) => {
    if (businessType === 'P') {
        return 'owner';
    } else if (businessType === 'F') {
        return 'partner';
    }
    return 'director';
};

export const firstName = (fullName) => {
    if (!fullName) {
        return '';
    }
    const nameParts = fullName.trim().split(/\s+/);
    return nameParts.length > 0 ? nameParts[0] : '';
};
