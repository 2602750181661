import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { transform } from '../../../helpers/transform';

const FormControlFile = (props) => {
    const types = {
        all: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'application/pdf': ['.pdf'],
            'application/vnd.ms-excel': ['.xls', '.xlsx'],
        },
        image: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
        },
        pdf: {
            'application/pdf': ['.pdf'],
        },
    };
    const { item, onFileSelect } = props;
    const [files, setFiles] = useState([]);
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
        useDropzone({
            accept: types[item.filetype || 'all'],
            onDrop: (acceptedFiles) => {
                const response = [...files, ...acceptedFiles];
                setFiles(response);
                onFileSelect(item, response);
            },
        });

    const removeFile = (index) => {
        const tmp = [...files];
        tmp.splice(index, 1);
        setFiles(tmp);
        onFileSelect(item, tmp);
    };

    return (
        <div className='form__item form__item-dropzone'>
            <div
                {...getRootProps()}
                className='dropzone'>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <span>Drop the files here ...</span>
                ) : (
                    <span>Tap to select & upload files from your Device</span>
                )}
            </div>
            {acceptedFiles && (
                <div className='dropzone dropzone-queue'>
                    {files.map((file, fileIndex) => {
                        return (
                            <div
                                className='dropzone-items wm-200px'
                                key={file.path}>
                                <div
                                    className='dropzone-item p-5 dz-image-preview dz-success dz-complete'
                                    id=''>
                                    <div className='dropzone-file'>
                                        <div
                                            className='dropzone-filename'
                                            title='some_image_file_name.jpg'>
                                            <img
                                                className='w-100'
                                                src={URL.createObjectURL(file)}
                                                alt=''
                                            />
                                            <div>
                                                <span>{file.path}</span>
                                                <strong className='ms-2'>
                                                    (
                                                    <span>
                                                        <strong>
                                                            {transform(
                                                                'filesize',
                                                                file.size,
                                                            )}
                                                        </strong>
                                                    </span>
                                                    )
                                                </strong>
                                            </div>
                                        </div>
                                        <div
                                            className='dropzone-error mt-0'
                                            data-dz-errormessage=''></div>
                                    </div>
                                    {/* <div className="dropzone-progress">
                                        <div className="progress bg-warning">
                                            <div className="progress-bar bg-success" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                                                aria-valuenow="0" data-dz-uploadprogress="" style={{ width: '70%' }}> </div>
                                        </div>
                                    </div> */}
                                    <div className='dropzone-toolbar'>
                                        {/* <span className="dropzone-start">
                                            <i className="bi bi-play-fill fs-3"></i>
                                        </span> */}
                                        {/* <span className="dropzone-cancel">
                                            <i className="bi bi-x fs-3"></i>
                                        </span> */}
                                        <span
                                            className='cursor-pointer dropzone-delete'
                                            onClick={() =>
                                                removeFile(fileIndex)
                                            }>
                                            <i className='bi bi-x fs-1'></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default FormControlFile;
