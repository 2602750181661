import React from 'react';
import Image from '../atoms/Image';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import { confirm } from '../../helpers/alert';
import { useTheme } from '../../context/ThemeContext';

const AppHeader = ({ logout = false, back = true }) => {
    const { type, logoutUser } = useUser();
    const { theme, toggleMode } = useTheme();
    const navigate = useNavigate();
    const handleLogout = (e) => {
        e.preventDefault();
        confirm(
            'Logout?',
            'Do you really want to logout your current sessions',
            () => {
                logoutUser();
                navigate(type === 'company' ? '/auth/company' : '/auth');
            },
        );
    };

    const handleThemeToggle = () => {
        toggleMode();
    };

    return (
        <div className='app-header'>
            {back ? (
                <div>
                    <Link onClick={() => navigate(-1)}>
                        <i className='app-header__icon ri ri-arrow-left-line'></i>
                    </Link>
                    {/* <span className='app-header__icon'></span> */}
                </div>
            ) : (
                <div>
                    <span className='app-header__icon'></span>
                </div>
            )}
            <Image
                className='app-header__logo'
                src={
                    theme?.logos?.[0]?.file_url ||
                    '/assets/img/logos/crego-logo-light.svg'
                }
            />
            <div>
                <Link onClick={handleThemeToggle}>
                    <i className='app-header__icon ri ri-contrast-2-fill'></i>
                </Link>
                {logout && (
                    <Link onClick={handleLogout}>
                        <i className='app-header__icon ri ri-logout-circle-r-line'></i>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default AppHeader;
