import { useState } from 'react';

export const useSwipe = () => {
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);

    const minSwipeDistance = 50;

    const onTouchStart = (e) => {
        setTouchEnd(0);
        setTouchStart(e.touches[0].clientX);
    };

    const onTouchMove = (e) => setTouchEnd(e.touches[0].clientX);

    const onTouchEnd = (onSwipedLeft, onSwipedRight) => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        if (Math.abs(distance) > minSwipeDistance) {
            if (distance > 0) {
                onSwipedLeft();
            } else {
                onSwipedRight();
            }
        }
    };

    return {
        onTouchStart,
        onTouchMove,
        onTouchEnd,
    };
};
