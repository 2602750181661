import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormWrapper from '../../components/organisms/FormView/FormWrapper';
import { useUser } from '../../context/UserContext';
import { beautify } from '../../helpers/str';
import { getLead, listBanks, urls } from '../../services/_crm.service';
import { routes } from './config';

const OnboardingBank = ({ onNext }) => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [loading, setLoading] = useState(false);
    const [lead, setLead] = useState({});
    const [parentId, setParentId] = useState('');
    const [edit, setEdit] = useState({});
    const [add, setAdd] = useState(false);
    const [banks, setBanks] = useState([]);

    const initLead = async () => {
        const response = await getLead(user.lead_id);
        setLead(response);
        const parent =
            user?.program_config?.borrower?.borrower_type === 'salaried'
                ? response?.person_entity?.id
                : response?.business_entity?.id;
        initBanks(parent);
        setParentId(parent);
    };

    const handleAdd = () => {
        setAdd(true);
    };

    const handleSubmit = async () => {
        initBanks(parentId);
        setEdit(false);
        setAdd(false);
    };

    const handleFinish = () => {
        navigate(routes.validate);
    };

    const initBanks = async (applicant_id) => {
        const response = await listBanks(applicant_id);
        setBanks(response);
    };

    useEffect(() => {
        initLead();
    }, []);

    const formItems = [
        {
            type: 'hidden',
            name: 'applicant_id',
            value: parentId,
        },
        {
            type: 'text',
            name: 'ifsc',
            writeOnly: true,
            lookup: {
                type: 'ifsc',
                url: urls.lookup_ifsc,
                target: {
                    bank_details: ['bank', 'branch'],
                },
            },
        },
        {
            type: 'rawText',
            name: 'bank_details',
            showLabel: false,
            className: 'info',
            prefill: {
                type: 'ifsc',
                url: urls.lookup_ifsc,
                key: 'ifsc',
                value: ['bank', 'branch'],
            },
        },
        {
            type: 'select',
            name: 'account_type',
            options_src: 'constants',
            options: 'bank_account_types',
        },
        { type: 'text', name: 'account_no' },
        { type: 'text', name: 'account_holder_name' },
        {
            type: 'select',
            label: 'Purpose',
            name: 'tags',
            options_src: 'constants',
            options: 'bank_tags',
            multi: true,
        },
        // { type: 'switch', name: 'is_for_disbursement' },
        // { type: 'switch', name: 'is_for_nach' },
    ];

    return (
        <div className='container'>
            {!edit?.id &&
                banks?.length > 0 &&
                banks?.map((bank) => {
                    return (
                        <div
                            className='card mt-10'
                            key={bank.id}>
                            <div className='card__content'>
                                <div>
                                    <h4>
                                        {bank.account_no} (
                                        {bank.account_holder_name})
                                    </h4>
                                    <p>
                                        {bank.bank_name ? (
                                            <>{bank.bank_name}, </>
                                        ) : (
                                            <></>
                                        )}
                                        {bank.ifsc}
                                    </p>
                                    <small>
                                        {beautify(bank.account_type)} A/C
                                    </small>
                                    {bank.is_for_nach && (
                                        <>
                                            <span className='mx-5'>
                                                &#11825;
                                            </span>
                                            <small>NACH Bank</small>
                                        </>
                                    )}
                                    {bank.is_for_disbursement && (
                                        <>
                                            <span className='mx-5'>
                                                &#11825;
                                            </span>
                                            <small>Disbursement Bank</small>
                                        </>
                                    )}
                                </div>
                                <button
                                    className='btn-link mt-10'
                                    onClick={() => setEdit(bank)}>
                                    Edit Bank
                                </button>
                            </div>
                        </div>
                    );
                })}
            {(add || banks.length === 0) && (
                <FormWrapper
                    data={urls.banks}
                    config={{
                        url: urls.banks,
                        items: formItems,
                    }}
                    submitted={handleSubmit}
                />
            )}
            {edit?.id && (
                <FormWrapper
                    data={edit}
                    config={{
                        btn: 'Save',
                        method: 'PATCH',
                        url: urls.bank_details,
                        items: formItems,
                    }}
                    submitted={handleSubmit}
                />
            )}
            {!add && !edit?.id && banks.length !== 0 && (
                <div className='btn-group'>
                    <button
                        type='button'
                        onClick={handleAdd}
                        className='btn btn-line'>
                        Add Another Bank
                    </button>
                </div>
            )}
            <button
                type='button'
                className='btn btn-fixed'
                onClick={onNext}>
                Proceed
            </button>
        </div>
    );
};

export default OnboardingBank;
