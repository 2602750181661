import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Loader from '../../../components/atoms/Loader';
import LoaderAnimated from '../../../components/atoms/LoaderAnimated';
import PageHeader from '../../../components/molecules/PageHeader';
import { getItrAnalyticsData } from '../../../services/_crm.service';
import ListView from '../../../components/organisms/ListView';

const ItrAnalyticsStatus = () => {
    const [report, setReport] = useState({});
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();

    const initData = async () => {
        setLoading(true);
        const response = await getItrAnalyticsData(
            searchParams.get('request_id'),
        );
        setReport(response);
        setLoading(false);
    };

    useEffect(() => {
        if (searchParams.has('request_id')) {
            initData();
        }
    }, [searchParams]);

    return (
        <div>
            <div className='container'>
                {loading && <Loader loading={loading} />}
                {!loading && report?.data?.result?.queued && (
                    <>
                        <LoaderAnimated
                            loading={true}
                            name='searching'
                            title='Please wait, Your ITR is being fetched.'>
                            <button
                                onClick={initData}
                                className='btn'>
                                Check Again!
                            </button>
                        </LoaderAnimated>
                    </>
                )}
                {!report?.data?.result?.queued && (
                    <>
                        <PageHeader
                            className='p-0 pb-20'
                            subtitle={`${report?.data?.result?.message || ''}`}>
                            {report?.data?.result?.pan}
                        </PageHeader>
                        <h4 className='my-10'>Filing History</h4>
                        <div className='card'>
                            <ListView
                                config={{
                                    data: report?.data?.result?.filing,
                                    items: [
                                        {
                                            type: 'heading',
                                            name: 'year',
                                        },
                                        {
                                            type: 'items',
                                            items: [
                                                {
                                                    type: 'text',
                                                    name: 'itr_type',
                                                    prefix: 'ITR',
                                                },
                                                {
                                                    type: 'text',
                                                    name: 'filing_date',
                                                    transform: 'date',
                                                    prefix: 'Filed On:',
                                                },
                                                {
                                                    type: 'link',
                                                    name: 'files.form',
                                                    label: 'View Form',
                                                },
                                            ],
                                        },
                                    ],
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default ItrAnalyticsStatus;
