import axios from 'axios';
import { buildUrl } from '../helpers/str';

export const BASE_URL =
    (process.env.REACT_APP_API_ROOT_DATUM ||
        process.env.REACT_APP_API_ROOT_DEFAULT) + '/datum/';

export const urls = {
    configs: BASE_URL + 'configs/',
    config_details: BASE_URL + 'configs/:id/',

    program_config: BASE_URL + 'configs/by_program/:id/',
};

export const getProgramConfig = async (id) => {
    const { data } = await axios.get(buildUrl(urls.program_config, { id }));
    return data;
};
