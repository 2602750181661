import axios from 'axios';
import { buildUrl } from '../helpers/str';

export const BASE_URL =
    (process.env.REACT_APP_API_ROOT_UNR ||
        process.env.REACT_APP_API_ROOT_DEFAULT) + '/unr/';

export const urls = {
    program_details: BASE_URL + 'programs/:id/',
    program_config: BASE_URL + 'programs/:program_id/active_config/',
    program_pwa_config: BASE_URL + 'programs/pwa_config/no_auth/',
    program_companies: BASE_URL + 'programs/:id/companies/',
};

export const getProgram = async (id, params = {}) => {
    const { data } = await axios.get(
        buildUrl(urls.program_details, { id, ...params }),
    );
    return data;
};

export const getPwaConfig = async (value, key = 'id') => {
    const { data } = await axios.get(urls.program_pwa_config, {
        params: {
            [key]: value,
            logos: 'logo',
        },
    });
    return data;
};

export const listProgramCompanies = async (id, params) => {
    const { data } = await axios.get(
        buildUrl(urls.program_companies, {
            id,
            ...params,
        }),
    );
    return data;
};
